var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"dashboardWorkOrdersCard"},[_c('div',{staticClass:"header"},[_c('span',{staticClass:"section-header"},[_vm._v("Work Orders")]),_c('v-spacer'),_c('v-text-field',{staticClass:"search-bar",attrs:{"outlined":"","dense":"","hide-details":"","placeholder":"Search","clearable":"","append-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('div',{staticClass:"titleButtons"},[_c('v-btn',{staticClass:"outline-btn",attrs:{"outlined":""},on:{"click":function($event){return _vm.clearTable()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-filter-remove-outline")]),_vm._v(" Clear ")],1),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"button",attrs:{"outlined":"","color":"#0c6599"},on:{"click":_vm.handleOnProjectPageClick}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-map-outline")])],1)]}}])},[_c('span',[_vm._v("View in Map")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"button",attrs:{"outlined":"","color":"#0c6599"},on:{"click":_vm.handleOnWorkOrderProjectPageClick}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-clipboard-text-clock-outline")])],1)]}}])},[_c('span',[_vm._v("View in Work Orders Page")])])],1)],1),_c('IntegrityTable',{ref:"customerDataWorkOrderTable",attrs:{"data":_vm.filteredCustomerDashboardWorkOrders,"headers":_vm.headers,"height":'100%',"matchFilters":_vm.matchFilters,"filterValues":_vm.filterValues,"itemsPerPage":50,"search":_vm.search,"footer-props":{
      'disable-items-per-page': false,
      'items-per-page-options': [25, 50, 100, -1],
    },"loading":_vm.customerDashboardWorkOrdersLoading},on:{"update:data":function($event){_vm.filteredCustomerDashboardWorkOrders=$event},"update:headers":function($event){_vm.headers=$event},"update:matchFilters":function($event){_vm.matchFilters=$event},"update:match-filters":function($event){_vm.matchFilters=$event},"update:filterValues":function($event){_vm.filterValues=$event},"update:filter-values":function($event){_vm.filterValues=$event}},scopedSlots:_vm._u([{key:"workOrderGuid",fn:function(ref){
    var item = ref.item;
return [(item.dataStandardExists)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.getTableInfoLink(item)}}},[_c('v-icon',[_vm._v("mdi-information-outline")])],1):_c('div')]}},{key:"daysOpen",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDaysOpenString(item))+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }