












































































































































import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { AssetData, AssetRef } from '@/types';
import { ProjectData } from '@/store/project/types';
import { PROJECT_MANAGER, SUPER_USER, SYS_ADMIN_AUTH0 } from '@/auth/roles';
import { AssetActions } from '../../store/asset/actions';
import { OptionsObject } from './types';

const assetModule = namespace('asset');
const projectModule = namespace('project');

@Component({})
export default class ExportDataPopout extends Vue {
  @Prop({ default: () => [] }) readonly assets: AssetData[];

  @Prop() readonly tableData: AssetData[];

  @assetModule.State('exportState') exportState: string;

  @assetModule.Action(AssetActions.EXPORT_ASSETS) exportAssets;

  @projectModule.State('data') project: ProjectData | undefined;

  @Watch('menu')
  onMenuChange(): void {
    if (this.menu === true) {
      this.exportTypeCheckboxes = [
        // Only enable if assets are selected
        { name: 'PDF', disabled: this.selectedAssets, value: false },
        { name: 'DB', disabled: this.selectedAssets, value: false },
      ];
      this.mediaTypeCheckboxes = [
        { name: 'Videos', disabled: this.selectedAssets, value: false },
        { name: 'Photos', disabled: this.selectedAssets, value: false },
      ];
      if (this.isUserPM || this.isUserSuper) this.exportTypeCheckboxes.push({ name: 'CSV', disabled: false, value: false });
      this.videoFileNameScheme = this.selectedAssets
        ? this.videoFileNameOptions[2] : this.videoFileNameOptions[1];
    }
  }

  get selectedAssets(): boolean {
    return this.assets.length === 0;
  }

  get isUserPM(): boolean {
    return this.userRoles.includes(PROJECT_MANAGER);
  }

  get isUserSuper(): boolean {
    return this.userRoles.includes(SUPER_USER) || this.userRoles.includes(SYS_ADMIN_AUTH0);
  }

  get isDbExport(): boolean {
    return this.exportTypeCheckboxes.find((cb) => cb.name === 'DB')?.value === true;
  }

  menu = false;

  userRoles = [];

  videoFileNameOptions = [
    'GUID',
    'Assetname_date_time',
    'JobNumber_date',
  ];

  exportTypeCheckboxes = [];

  mediaTypeCheckboxes = [];

  exportOptions = [
    'Ignore validation errors',
    'Do not recieve validation email',
  ];

  exportOptionsCheckboxes = Array(this.exportOptions.length).fill(false);

  videoFileNameScheme = '';

  address = '';

  async mounted(): Promise<void> {
    if (this.$auth.user) {
      this.address = this.$auth.user.email;
      if (this.userRoles.length === 0) this.userRoles = await this.getRoles();
    }
  }

  async getRoles(): Promise<string[]> {
    return this.$auth.getRoles(`auth0|${this.$auth.user.id}`);
  }

  async exportData(): Promise<void> {
    this.menu = false;
    if (this.project == null || this.project.assets == null) return;
    const optionsObject = {
      projectExportObjectList: [],
      jobOptions: {},
    } as OptionsObject;

    optionsObject.jobOptions.address = this.address;
    optionsObject.jobOptions.videoFileNameScheme = this.videoFileNameScheme;

    // eslint-disable-next-line prefer-destructuring
    optionsObject.jobOptions.pdf = this.exportTypeCheckboxes.find((cb) => cb.name === 'PDF').value;
    // eslint-disable-next-line prefer-destructuring
    optionsObject.jobOptions.db = this.exportTypeCheckboxes.find((cb) => cb.name === 'DB').value;
    // eslint-disable-next-line prefer-destructuring
    const csvOptions = this.exportTypeCheckboxes.find((cb) => cb.name === 'CSV');
    optionsObject.jobOptions.csv = csvOptions === undefined ? false : csvOptions.value;

    [optionsObject.jobOptions.ignoreValidation,
      optionsObject.jobOptions.ignoreValidationMessage] = this.exportOptionsCheckboxes;

    optionsObject.jobOptions.videos = this.mediaTypeCheckboxes.find((cb) => cb.name === 'Videos').value;
    optionsObject.jobOptions.photos = this.mediaTypeCheckboxes.find((cb) => cb.name === 'Photos').value;

    const projectExportObject = new Map();
    const assetsToExport = this.assets.length > 0 ? this.assets : this.tableData;

    assetsToExport.forEach((a) => {
      // find assetref in project
      const assetRef = this.project.assets.find((asset) => asset.guid === a.guid) as AssetRef;
      if (assetRef == null) return;

      if (!projectExportObject.has(assetRef.projectGuid)) {
        projectExportObject
          .set(assetRef.projectGuid,
            {
              assetGuids: [],
              inspectionGuids: [],
            });
      }

      const currentProjectExportObject = projectExportObject.get(assetRef.projectGuid);
      assetRef.inspections.forEach((inspection) => {
        if (currentProjectExportObject.inspectionGuids.indexOf(inspection.inspectionGuid) === -1) {
          currentProjectExportObject.inspectionGuids.push(inspection.inspectionGuid);
        }
      });
      currentProjectExportObject.assetGuids.push(a.guid);
      projectExportObject.set(assetRef.projectGuid, currentProjectExportObject);
    });

    Array.from(projectExportObject.entries()).forEach((x) => {
      optionsObject.projectExportObjectList.push({
        projectGuid: x[0],
        assetGuids: x[1].assetGuids,
        inspectionGuids: x[1].inspectionGuids,
      });
    });

    if (optionsObject.projectExportObjectList.length === 0) {
      optionsObject.projectExportObjectList.push({
        projectGuid: this.project.guids[0],
        assetGuids: [],
        inspectionGuids: [],
      });
    }

    this.$emit('exportData', optionsObject);
    this.exportAssets({ exportOptions: optionsObject })
      .then(() => {
        // eslint-disable-next-line no-alert
        alert('Your export request has been received. The download links will be sent shortly to the email provided.');
      });
  }
}
