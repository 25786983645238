







































































































































































































































































































































































/* eslint-disable @typescript-eslint/no-explicit-any */
import { AssetRef, Assignment, Inspection } from '@/types';
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import convert from 'convert-units';
import { ProjectActions } from '@/store/project/actions';
import { ProjectHeaders } from '@/store/project/types';
import { format } from 'date-fns';
import util from '../Maps/utils';

const userPrefsModule = namespace('userPrefs');
const projectModule = namespace('project');

@Component
export default class AssetPopout extends Vue {
  selectedTab = 0;

  tabOptions = [
    { name: 'attributes', value: 'Attributes', isPM: false },
    { name: 'inspections', value: 'Inspections', isPM: false },
    { name: 'collections', value: 'Collections', isPM: true },
  ];

  @Prop() readonly selectedAssetGuid: string;

  @Prop() readonly assetGuidList: string[];

  @Prop() readonly closeState: boolean;

  @Prop() readonly isPM: boolean;

   defaultSelectedAsset: AssetRef = {
     name: '',
     guid: '',
     type: '',
     inspections: [],
     hasCustomerDeliverables: false,
   };

  @userPrefsModule.State('displayImperial') displayImperial: boolean;

  @projectModule.State('selectedAsset') selectedAsset: AssetRef | undefined;

  @projectModule.Action(ProjectActions.FETCH_SELECTED_ASSET) loadAsset;

  @projectModule.State('headers') projectHeaders: ProjectHeaders | undefined;

  @Watch('selectedAssetGuid')
  async onAssetGuidChanged(): Promise<void> {
    if (this.selectedAssetGuid !== undefined) {
      await this.loadAsset(this.selectedAssetGuid);
      this.defaultSelectedAsset = this.selectedAsset;
    }
  }

  get currentTabOptions(): any[] {
    return this.tabOptions.filter(
      (taboption) => !taboption.isPM || taboption.isPM === this.isPM,
    );
  }

  get isManhole(): boolean {
    return this.defaultSelectedAsset.type === 'Manhole';
  }

  get selectedAttribute(): any {
    let retVal = '';

    if (this.defaultSelectedAsset && this.defaultSelectedAsset.attributes) {
      if (typeof this.defaultSelectedAsset.attributes !== 'string') {
        retVal = this.defaultSelectedAsset.attributes;
      } else {
        retVal = JSON.parse(this.defaultSelectedAsset.attributes);
      }
    }
    return retVal;
  }

  get parsedDate(): string {
    if (
      this.selectedAttribute === null
      || this.selectedAttribute.DateInstalled === null
    ) {
      return '';
    }
    const date = new Date(
      this.selectedAttribute.DateInstalled,
    ).toLocaleString();
    return date !== 'Invalid Date' ? date : '';
  }

  get distanceFromUpstream(): number {
    let distance: number;
    if (
      this.defaultSelectedAsset
      && this.defaultSelectedAsset.type === 'Lateral'
    ) {
      // if we got coding data use that
      if (
        this.defaultSelectedAsset.laterals[0]
        && this.defaultSelectedAsset.laterals[0].coding
      ) {
        distance = this.defaultSelectedAsset.laterals[0].coding.distance;
        // else check if we calculated the distance with esri
      } else if (this.selectedAttribute.DistanceFromUS) {
        distance = Math.round((convert(20).from('m').to('ft') + Number.EPSILON) * 10)
          / 10;
      }
    }

    return this.displayImperial
      ? distance
      : Math.round(
        (convert(distance).from('ft').to('m') + Number.EPSILON) * 10,
      ) / 10;
  }

  get clockPosition(): number {
    let retVal: number;
    if (
      this.defaultSelectedAsset.type === 'Lateral'
      && this.defaultSelectedAsset.laterals[0]
      && this.defaultSelectedAsset.laterals[0].coding
    ) {
      retVal = this.defaultSelectedAsset.laterals[0].coding
        .clockAtFrom as number;
    }
    return retVal;
  }

  get tapSize(): number {
    let retVal: number;
    if (
      this.defaultSelectedAsset.type === 'Lateral'
      && this.defaultSelectedAsset.laterals[0]
      && this.defaultSelectedAsset.laterals[0].coding
    ) {
      retVal = this.defaultSelectedAsset.laterals[0].coding
        .value1stDimension as number;
    }
    return retVal;
  }

  get tapCode(): string {
    let retVal: string;
    if (
      this.defaultSelectedAsset.type === 'Lateral'
      && this.defaultSelectedAsset.laterals[0]
      && this.defaultSelectedAsset.laterals[0].coding
    ) {
      retVal = (this.defaultSelectedAsset.laterals[0].coding as any)
        .conditionName;
    }
    return retVal;
  }

  get tapDescription(): string {
    let retVal: string;
    if (
      this.defaultSelectedAsset.type === 'Lateral'
      && this.defaultSelectedAsset.laterals[0]
      && this.defaultSelectedAsset.laterals[0].coding
    ) {
      retVal = (this.defaultSelectedAsset.laterals[0].coding as any).code;
    }
    return retVal;
  }

  get inspections(): Inspection[] {
    if (this.defaultSelectedAsset.inspections
      && Array.isArray(this.defaultSelectedAsset.inspections)) {
      return [...this.defaultSelectedAsset.inspections].sort((a, b) => {
        const va = a.defectScore === null ? -1 : a.defectScore;
        const vb = b.defectScore === null ? -1 : b.defectScore;
        return -(va - vb);
      });
    }
    return [this.defaultSelectedAsset.inspections[0]];
  }

  get assignments(): Assignment[] {
    return this.defaultSelectedAsset.assignments
      ? this.defaultSelectedAsset.assignments
      : [];
  }

  get street(): string {
    if (this.selectedAttribute) {
      if (this.selectedAttribute.Location) return this.selectedAttribute.Location;
      if (this.selectedAttribute.StreetAddress) return this.selectedAttribute.StreetAddress;
      if (this.selectedAttribute.Street) return this.selectedAttribute.Street;
    }

    return '';
  }

  get city(): string {
    if (this.selectedAttribute) {
      if (this.projectHeaders) {
        return this.projectHeaders.cities[
          this.projectHeaders.guids.indexOf(this.selectedAsset.projectGuid)
        ];
      }
      if (this.selectedAttribute.City) return this.selectedAttribute.City;
    }

    return '';
  }

  get drainageArea(): string {
    if (this.selectedAttribute) {
      if (this.selectedAttribute.Drainage) return this.selectedAttribute.Drainage;
      if (this.selectedAttribute.Basin) return this.selectedAttribute.Basin;
    }

    return '';
  }

  get sewerUse(): string {
    if (this.selectedAttribute) {
      if (this.selectedAttribute.Pipe_Use) return this.selectedAttribute.Pipe_Use;
      if (this.defaultSelectedAsset.sewerUse) return this.defaultSelectedAsset.sewerUse;
    }

    return 'Sanitary';
  }

  get pipeSize(): string {
    if (this.selectedAttribute) {
      if (this.selectedAttribute.Pipe_Height) return this.selectedAttribute.Pipe_Height;
      if (this.selectedAttribute.PipeSize) return this.selectedAttribute.PipeSize;
      if (this.selectedAttribute.PipeSize_GIS) return this.selectedAttribute.PipeSize_GIS;
    }

    return '';
  }

  get pipeMaterial(): string {
    if (this.selectedAttribute) {
      if (this.selectedAttribute.Material) return this.selectedAttribute.Material;
      if (this.selectedAttribute.PipeMaterial) return this.selectedAttribute.PipeMaterial;
      if (this.selectedAttribute.PipeMaterial_GIS) return this.selectedAttribute.PipeMaterial_GIS;
    }

    return '';
  }

  get distance(): string {
    try {
      let retVal = '';
      if (this.selectedAttribute) {
        if (this.selectedAttribute.Length) retVal = this.selectedAttribute.Length;
        if (this.selectedAttribute.LengthGIS) retVal = this.selectedAttribute.LengthGIS;
      }

      return this.getDisplayDistanceFtM(parseFloat(retVal));
    } catch {
      return '';
    }
  }

  getDefectColor(score: number): string {
    return util.getScoreColor(score);
  }

  closeButton(): void {
    this.defaultSelectedAsset = {
      name: '',
      guid: '',
      type: '',
      inspections: [],
      hasCustomerDeliverables: false,
    };
    this.$emit('emitClose');
  }

  isButtonDisabled(): boolean {
    return this.defaultSelectedAsset.hasCustomerDeliverables !== true;
  }

  getDisplayDistanceInMM(value: number): string {
    return value || value === 0
      ? util.getDisplayDistanceInMM(this.displayImperial, value)
          + util.getDistanceUnitsInMM(this.displayImperial)
      : '';
  }

  getDisplayDistanceFtMisImperial(value: number, isImperial: boolean): string {
    return value || value === 0
      ? util.getDisplayDistanceFtM(isImperial, value)
          + util.getDistanceUnitsFtM(isImperial)
      : '';
  }

  getDisplayDistanceFtM(value: number): string {
    return this.getDisplayDistanceFtMisImperial(value, this.displayImperial);
  }

  parseDate(dateString: string): string {
    if (dateString === null) {
      return '';
    }

    const dateVal = Date.parse(dateString.split(' ')[0]);

    if (Number.isNaN(dateVal)) {
      return '';
    }

    return format(dateVal, 'MM/dd/yyyy');
  }
}
