/* eslint-disable import/no-mutable-exports */
let json: any;

// Store Role tokens here
export const NONE = 'none';
export const CREW_LEAD = '5a05bb80-a54e-11ec-bcd3-efa2f92373e4';
export const CREW_MEMBER_GUID = '5a05bb80-a54e-11ec-bcd3-efa2f92373e5';
export const CUSTOMER_GUID = '45696b00-e107-11ec-a869-2b45f636ca36';
export const CUSTOMER_SUPPORT_GUID = '5a05bb80-a54e-11ec-bcd3-efa2f92373f0';
export const DEMO_USER_READ_ONLY_GUID = '6cfdb680-446f-11ef-9b21-eb227d655b8f';
export const DEMO_USER_WRITE_GUID = '6d00c3c0-446f-11ef-9b21-c38418a9102d';
export const OPERATIONS_MANAGER_GUID = '5a05bb80-a54e-11ec-bcd3-efa2f92373e9';
export const PROJECT_MANAGER_GUID = 'd5e61d00-d33e-11ea-a42f-6f4431b74d9a';
export const REPORTING_MANAGER_GUID = '5a05bb80-a54e-11ec-bcd3-efa2f92373e8';
export const REPORTING_TECHNICIAN_GUID = 'd5e6441a-d33e-11ea-a432-173994d6bee3';
export const SUPER_USER_GUID = 'f3b58a80-321f-11ee-b17b-5f4718720564';
export const SUPERVISOR_GUID = '5a05bb80-a54e-11ec-bcd3-efa2f92373e6';
export const SYS_ADMIN = '082480e0-e26f-11ec-a869-af7bd91f0f4f';
export const SYSTEM_OWNER_GUID = '5a05bb80-a54e-11ec-bcd3-efa2f92373e7';

export let CREW_LEAD_AUTH0 = 'rol_yZlxAhVuLkrE5TBc';
export let CREW_MEMBER = 'rol_Hp6D8vQpRzJ61Yjv';
export let CUSTOMER = 'rol_gD1VrxrkkGWqGGnT';
export let CUSTOMER_SUPPORT = 'rol_YoIkaKscODOpa2zO';
export let DEMO_USER_READ_ONLY = 'rol_HBiw1w79UEiqGr2A';
export let DEMO_USER_WRITE = 'rol_bQ9rJc5vwIa4A8f9';
export let OPERATIONS_MANAGER = 'rol_Sad2g2GdCtTPJdjR';
export let PROJECT_MANAGER = 'rol_aJJNtsVC1y0REaO2';
export let REPORTING_CONTRACTOR = 'rol_JVOSZWUyF4wiNaID';
export let REPORTING_MANAGER = 'rol_vHp6f8IvFNbDaBWw';
export let REPORTING_TECH_AUTH0 = 'rol_TjC4gTFBkfIlmwp3';
export let SUPER_USER = 'rol_2HMErZICvocrvTXC';
export let SUPERVISOR = 'rol_PZtkHAOOr24rug9p';
export let SYS_ADMIN_AUTH0 = 'rol_EzCS6F2bQJDt6Ke3';
export let SYSTEM_OWNER = 'rol_Bv0RnboqQhu1Q3P7';

export const FIELD_CREW = ['311d14dd-0fab-48b2-b50b-766dc019de3c', 'd5e5f5f0-d33e-11ea-a42e-230598c92e28'];

export const AuthUserRoles = [
  {
    id: CREW_LEAD_AUTH0,
    name: 'Crew Lead',
    guid: CREW_LEAD,
  },
  {
    id: CREW_MEMBER,
    name: 'Crew Member',
    guid: CREW_MEMBER_GUID,
  },
  {
    id: CUSTOMER,
    name: 'Customer',
    guid: CUSTOMER_GUID,
  },
  {
    id: CUSTOMER_SUPPORT,
    name: 'Customer Support',
    guid: CUSTOMER_SUPPORT_GUID,
  },
  {
    id: DEMO_USER_READ_ONLY,
    name: 'Demo User Read Only',
    guid: DEMO_USER_READ_ONLY_GUID,
  },
  {
    id: DEMO_USER_WRITE,
    name: 'Demo User Write',
    guid: DEMO_USER_WRITE_GUID,
  },
  {
    id: OPERATIONS_MANAGER,
    name: 'Operations Manager',
    guid: OPERATIONS_MANAGER_GUID,
  },
  {
    id: PROJECT_MANAGER,
    name: 'Project Manager',
    guid: PROJECT_MANAGER_GUID,
  },
  {
    id: REPORTING_CONTRACTOR,
    name: 'Reporting Contractor',
    guid: REPORTING_MANAGER_GUID,
  },
  {
    id: REPORTING_MANAGER,
    name: 'Reporting Manager',
    guid: REPORTING_MANAGER_GUID,
  },
  {
    id: REPORTING_TECH_AUTH0,
    name: 'Reporting Technician',
    guid: REPORTING_TECHNICIAN_GUID,
  },
  {
    id: SUPER_USER,
    name: 'Super User',
    guid: SUPER_USER_GUID,
  },
  {
    id: SUPERVISOR,
    name: 'Supervisor',
    guid: SUPERVISOR_GUID,
  },
  {
    id: SYS_ADMIN_AUTH0,
    name: 'SysAdmin',
    guid: SYS_ADMIN,
  },
  {
    id: SYSTEM_OWNER,
    name: 'System Owner',
    guid: SYSTEM_OWNER_GUID,
  },
];

export function getRoleString(role: string): string {
  const roleObj = AuthUserRoles.find((r) => r.id === role);
  return roleObj?.name ?? 'ROLE NOT FOUND';
}

export function getRolesArray(roles: string[]): string[] {
  return roles && roles.length > 0
    ? roles.map((role) => getRoleString(role))
    : [];
}

export function getRolesString(roles: string[]): string {
  return getRolesArray(roles).join(', ');
}

export function getRoleId(role: string): string {
  const roleObj = AuthUserRoles.find((r) => r.name === role);
  return roleObj?.id ?? 'ROLE NOT FOUND';
}

export function getRoleGuid(role: string): string {
  const roleObj = AuthUserRoles.find((r) => r.name === role);
  return roleObj?.guid ?? 'ROLE NOT FOUND';
}

export function getRoleGuidString(role: string): string {
  const roleObj = AuthUserRoles.find((r) => r.guid === role);
  return roleObj?.name ?? 'ROLE NOT FOUND';
}

export async function getRoles(): Promise<void> {
  if (typeof fetch === 'function' && json === undefined) {
    try {
      const test = await fetch('/config.json');
      const text = await test.text();
      json = JSON.parse(text);
      CREW_LEAD_AUTH0 = json['CREW_LEAD_AUTH0'];
      CREW_MEMBER = json['CREW_MEMBER'];
      CUSTOMER = json['CUSTOMER'];
      CUSTOMER_SUPPORT = json['CUSTOMER_SUPPORT'];
      DEMO_USER_READ_ONLY = json['DEMO_USER_READ_ONLY'];
      DEMO_USER_WRITE = json['DEMO_USER_WRITE'];
      OPERATIONS_MANAGER = json['OPERATIONS_MANAGER'];
      PROJECT_MANAGER = json['PROJECT_MANAGER'];
      REPORTING_CONTRACTOR = json['REPORTING_CONTRACTOR'];
      REPORTING_MANAGER = json['REPORTING_MANAGER'];
      REPORTING_TECH_AUTH0 = json['REPORTING_TECH_AUTH0'];
      SUPER_USER = json['SUPER_USER'];
      SUPERVISOR = json['SUPERVISOR'];
      SYS_ADMIN_AUTH0 = json['SYS_ADMIN_AUTH0'];
      SYSTEM_OWNER = json['SYSTEM_OWNER'];
    } catch (e) {
      console.warn(e);
    }
  }
}
