



































































































































import { InspectionResponse } from '@/components/CodingForm/types';
import { AssetActions } from '@/store/asset/actions';
import { AssignmentShort } from '@/store/planning/types';
import { AssetData } from '@/types';
import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import ReportFooter from '../ReportFooter.vue';
import { SmokeTestFields } from './types';

const assetModule = namespace('asset');

@Component({ components: { ReportFooter } })
export default class SmokeTestReport extends Vue {
  @assetModule.State('asset') asset!: AssetData;

  @assetModule.Action(AssetActions.FETCH_ASSET_DATA) fetchAssetData;

  @Prop({ default: true }) readonly displayImperial!: boolean;

  @Prop() codingDetail: InspectionResponse;

  @Prop() assetType: string;

  @Prop({ default: '' }) workOrderReport: string;

  @Prop({ default: null }) item: AssignmentShort;

  @Prop() readonly assetName: string;

  @Watch('codingDetail')
  onCodingDetailChange(): void {
    this.populateTableData();
  }

  workOrderJson: any = {};

  mounted(): void {
    this.workOrderJson = JSON.parse(this.codingDetail.inspections[0].jsonData);
    this.populateTableData();
  }

  get smokeTestReportFields(): SmokeTestFields {
    return {
      name: this.item && this.item.nodes.name
        ? this.item.nodes.name
        : '',
      assignedTo: this.item && this.item.crewLeadName
        ? this.item.crewLeadName
        : '',
      status: this.item && this.item.status
        ? this.item.status
        : '',
      testNo: this.item && this.item.deploymentId
        ? this.item.deploymentId
        : '',
      workOrderNumber: this.item && this.item.workOrderNumber
        ? this.item.workOrderNumber
        : '',
      dateIssued: this.workOrderJson && this.workOrderJson.DateIssued
        ? this.formatDate(this.workOrderJson.DateIssued)
        : '',
      completeBy: this.item && this.workOrderJson.PlanDueDate
        ? this.formatDate(this.workOrderJson.PlanDueDate)
        : '',
      workDescription: this.workOrderJson && this.workOrderJson.WorkDescription
        ? this.workOrderJson.WorkDescription
        : '',
      specialInstructions: this.workOrderJson && this.workOrderJson.SpecialInstructions
        ? this.workOrderJson.SpecialInstructions
        : '',
      testDate: this.workOrderJson && this.workOrderJson.TestDate
        ? this.formatDate(this.workOrderJson.TestDate)
        : '',
      startTime: this.workOrderJson && this.workOrderJson.StartTime
        ? this.formatTime(this.workOrderJson.StartTime)
        : '',
      endTime: this.workOrderJson && this.workOrderJson.EndTime
        ? this.formatTime(this.workOrderJson.EndTime)
        : '',
      setupManhole: this.workOrderJson && this.workOrderJson.Manhole
        ? this.workOrderJson.Manhole
        : '',
      noOfReturns: this.workOrderJson && this.workOrderJson.Returns
        ? this.workOrderJson.Returns
        : '',
      inflowReturns: this.workOrderJson && this.workOrderJson.InflowReturns
        ? this.workOrderJson.InflowReturns
        : '',
      infilReturns: this.workOrderJson && this.workOrderJson.InfiltrationReturns
        ? this.workOrderJson.InfiltrationReturns
        : '',
      comments: this.workOrderJson && this.workOrderJson.Comments
        ? this.workOrderJson.Comments
        : '',
    };
  }

  formatDate(dateString: string): string {
    if (!dateString) {
      return '';
    }
    let date = new Date(dateString.substring(0, 10));

    if (date.toString() === 'Invalid Date') {
      return '';
    }

    const userTimezoneOffset = date.getTimezoneOffset() * 60000;
    date = new Date(date.getTime() + userTimezoneOffset);

    const year = date.getFullYear();
    const month = (1 + date.getMonth());
    const day = date.getDate();

    return `${month}/${day}/${year}`;
  }

  formatTime(timeString: string): string {
    if (!timeString) {
      return '';
    }

    const date = new Date(timeString.substring(0, 10));

    if (date.toString() === 'Invalid Date') {
      return '';
    }
    const formattedTimeString = timeString
      .substring(11, timeString.length - 3)
      .split(':');

    const hours = Number(
      formattedTimeString.length > 1 ? formattedTimeString[0] : '',
    );
    const minutes = Number(
      formattedTimeString.length > 1 ? formattedTimeString[1] : '',
    );

    // eslint-disable-next-line no-restricted-globals
    if (isNaN(hours) || isNaN(minutes)) {
      return '';
    }

    date.setHours(hours);
    date.setMinutes(minutes);
    return date.toLocaleTimeString([], { timeStyle: 'short' });
  }

  populateTableData(): void {
    if (this.codingDetail) {
      const mains = [];
      const structures = [];
      const inspection = this.codingDetail.inspections[0];
      const table = document.getElementById('smoke-test-table') as HTMLTableElement;

      if (inspection.subInspectionJsonData) {
        inspection.subInspectionJsonData.forEach((data) => {
          const jsonData = JSON.parse(data.jsonData);
          if (jsonData.AssetType === 'Main') mains.push(jsonData.AssetID);
          else if (jsonData.AssetType === 'Structure') structures.push(jsonData.AssetID);
        });
      }

      const rowCount = mains.length > structures.length ? mains.length : structures.length;

      for (let i = 0; i < rowCount; i += 1) {
        const row = table.insertRow(-1);
        const structCell = row.insertCell(0);
        const mainCell = row.insertCell(1);

        mainCell.innerText = mains[i] ?? '';
        structCell.innerText = structures[i] ?? '';
      }
    }
  }
}
