












































































































































/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Prop, Vue } from 'vue-property-decorator';
import { InspectionResponse } from '@/components/CodingForm/types';
import { namespace } from 'vuex-class';
import { AssetData } from '@/store/asset/types';
import { AssetActions } from '@/store/asset/actions';
import ReportObservation from '../ReportObservation.vue';
import PipeConditionReport from '../../PipeConditionReport/PipeConditionReport.vue';
import ReportFooter from '../ReportFooter.vue';
import util from '../util';
import { GeneralMaintenanceReportFields } from './types';

const assetModule = namespace('asset');

@Component({
  components: {
    ReportFooter,
    ReportObservation,
    PipeConditionReport,
  },
})
export default class GeneralMaintenanceReport extends Vue {
  @assetModule.State('asset') asset!: AssetData;

  @assetModule.Action(AssetActions.FETCH_ASSET_DATA) fetchAssetData;

  @Prop({ default: true }) readonly displayImperial!: boolean;

  @Prop() codingDetail: InspectionResponse;

  @Prop() assetType: string;

  @Prop({ default: '' }) workOrderReport: string;

  @Prop({ default: null }) item: any;

  generalMaintenanceReportFields: GeneralMaintenanceReportFields | any = {};

  workOrderJson: any;

  // eslint-disable-next-line class-methods-use-this
  formatText(text: string): string {
    if (!text) return '';
    let newText = text;

    newText = newText.replace(/&gt;/g, '>');
    newText = newText.replace(/&lt;/g, '<');
    newText = newText.replace(/&quot;/g, '"');

    return newText;
  }

  getDisplayDistanceInMM(value: number): string {
    return value || value === 0
      ? util.getDisplayDistanceInMM(this.displayImperial, value)
          + util.getDistanceUnitsInMM(this.displayImperial)
      : '';
  }

  getDisplayDistanceFtM(value: number): string {
    return value || value === 0
      ? util.getDisplayDistanceFtM(this.displayImperial, value)
          + util.getDistanceUnitsFtM(this.displayImperial)
      : '';
  }

  mounted(): void {
    this.workOrderJson = JSON.parse(this.codingDetail.inspections[0].jsonData);
    // fill fields
    this.fillGeneralMaintenanceReportFields();
    // this.fetchAsset();
    this.$forceUpdate();
  }

  fillGeneralMaintenanceReportFields(): void {
    this.generalMaintenanceReportFields = {
      name: this.item && this.item.nodes.name ? this.item.nodes.name : '',
      assignedTo: this.workOrderJson ? this.workOrderJson.Users : '',
      status: this.item && this.item.status ? this.item.status : '',
      workOrderNumber:
        this.item && this.item.workOrderNumber ? this.item.workOrderNumber : '',
      dateIssued: this.workOrderJson ? this.formatDate(this.workOrderJson.DateIssued) : '',
      workDescription: this.workOrderJson ? this.workOrderJson.Description : '',
      specialInstructions: this.workOrderJson ? this.workOrderJson.Instructions : '',
      assetNumber: this.workOrderJson ? this.workOrderJson.Wastewater : '',
      priority: this.workOrderJson ? this.workOrderJson.Priority : '',
      planDueDate: this.workOrderJson ? this.formatDate(this.workOrderJson.PlanDueDate) : '',
      activity: '', // ?
      streetAddress: this.workOrderJson ? this.workOrderJson.Address : '',
      street: this.workOrderJson ? this.workOrderJson.Street : '',
      dateCompleted: this.workOrderJson ? this.formatDate(this.workOrderJson.InspDate) : '',
      notCompletedWhy: this.workOrderJson ? this.workOrderJson.ResonNotComplete : '',
      specialConditions: this.workOrderJson ? this.workOrderJson.SpecialCondition : '',
      clean: this.workOrderJson ? this.workOrderJson.FUClean : false,
      repair: this.workOrderJson ? this.workOrderJson.FURepair : false,
      inspect: this.workOrderJson ? this.workOrderJson.FUInspect : false,
    };
  }

  fetchAsset(): void {
    this.fetchAssetData({
      assetId: this.item.nodes.guid,
      inspectionId: this.item.guid,
    }).then(() => {
      this.$forceUpdate();
    });
  }

  get workOrderType(): string {
    if (!this.generalMaintenanceReportFields?.workOrderNumber) {
      return '';
    }
    return this.generalMaintenanceReportFields.workOrderNumber.split('-')[0];
  }

  formatDate(dateString: string): string {
    if (!dateString) {
      return '';
    }

    let date = new Date(dateString.substring(0, 10));

    if (date.toString() === 'Invalid Date') {
      return '';
    }

    const userTimezoneOffset = date.getTimezoneOffset() * 60000;
    date = new Date(date.getTime() + userTimezoneOffset);

    const year = date.getFullYear();
    const month = (1 + date.getMonth());
    const day = date.getDate();

    return `${month}/${day}/${year}`;
  }
}
