import { ActionTree } from 'vuex';
import { RootState } from '../types';
import { UserState } from './types';
import { UserMutations } from './mutations';
import storeUtility from '../utils';

// eslint-disable-next-line no-shadow
export enum UserActions {
    FETCH_ALL_USER_DATA = 'FETCH_ALL_USER_DATA',
    FETCH_ALL_DETAILED_USER_DATA = 'FETCH_ALL_DETAILED_USER_DATA',
    FETCH_INACTIVE_DETAILED_USER_DATA = 'FETCH_INACTIVE_DETAILED_USER_DATA',
}

export const actions: ActionTree<UserState, RootState> = {

  async [UserActions.FETCH_ALL_USER_DATA]({ commit }): Promise<void> {
    commit(UserMutations.SET_LOADING_STATE, true);
    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.getAllUsers();

      commit(UserMutations.SET_ALL_USER_DATA, response.data);
    } catch (e) {
      commit(UserMutations.SET_LOAD_ERROR, (e as Error).message);
      throw Error('API Error occurred.');
    } finally {
      commit(UserMutations.SET_LOADING_STATE, false);
    }
  },

  async [UserActions.FETCH_ALL_DETAILED_USER_DATA]({ commit }): Promise<void> {
    commit(UserMutations.SET_LOADING_STATE, true);
    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.getAllDetailedUsers();

      commit(UserMutations.SET_ALL_DETAILED_USER_DATA, response.data);
    } catch (e) {
      commit(UserMutations.SET_LOAD_ERROR, (e as Error).message);
      throw Error('API Error occurred.');
    } finally {
      commit(UserMutations.SET_LOADING_STATE, false);
    }
  },

  async [UserActions.FETCH_INACTIVE_DETAILED_USER_DATA]({ commit }): Promise<void> {
    commit(UserMutations.SET_LOADING_STATE, true);
    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.getInactiveDetailedUsers();

      commit(UserMutations.SET_INACTIVE_DETAILED_USER_DATA, response.data);
    } catch (e) {
      commit(UserMutations.SET_LOAD_ERROR, (e as Error).message);
      throw Error('API Error occurred.');
    } finally {
      commit(UserMutations.SET_LOADING_STATE, false);
    }
  },
};
