
























































































































































































































































































































































































/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-param-reassign */
import {
  Component, Emit, Prop, PropSync, Vue, Watch,
} from 'vue-property-decorator';
import Ajv2019 from 'ajv/dist/2019';
import addFormats from 'ajv-formats';
import { namespace } from 'vuex-class';
import {
  DeploymentFormDoNotInspectDTO, InspectionData, LineSegmentAccessPoint, ManholeAccessPoint,
} from '@/store/asset/types';
import VueHtml2pdf from 'vue-html2pdf';
import { AssignmentShort } from '@/store/planning/types';
import { DeploymentsActions } from '@/store/deployments/actions';
import { UserData } from '@/store/users/types';
import { CREW_LEAD, FIELD_CREW } from '@/auth/roles';
import { iMPS } from '@/views/MasterProjectSummary/iMPS';
import { DataRequirement } from '@/views/MasterProjectSummary/types';
import { AssetActions } from '@/store/asset/actions';
import draggable from 'vuedraggable';
import { NODEITEM_LINESEGMENT_GUID, TASK_RESULT_DETAILS } from '@/common/Constants';

import { SnackBarActions } from '@/store/integritySnackBar/actions';
import { BigPipeDeploymentData, BigPipeSegment } from '@/store/deployments/types';
import { uuid } from 'vue-uuid';
import {
  CodingFormData,
  ConditionalRequirement,
  ConditionalType,
  FormPageData,
} from '../CodingForm/types';
import DeploymentFormPage from './DeploymentFormPage.vue';
import { DataStandardViewReturn } from '../DataStandards/types';
import { DeploymentFormSegmentIssueEntry } from './types';

const planningModule = namespace('planning');
const deploymentModule = namespace('deployments');
const userModule = namespace('users');
const mpsModule = namespace('mps');
const assetModule = namespace('asset');
const integritySnackBar = namespace('integritySnackBar');

@Component({
  components: {
    DeploymentFormPage,
    VueHtml2pdf,
    draggable,
  },
})
export default class DeploymentCodingForm extends Vue {
  openForm = true;

  @Prop({ default: null }) assetId: string;

  @Prop() popOutOpen: boolean;

  @Prop({ default: false }) isWorkOrderForm: boolean;

  @Prop({ default: '' }) workOrderReport: string;

  @Prop({ default: null }) item: any;

  @Prop() inspection!: InspectionData;

  @Prop() readonly projectWorkOrders: AssignmentShort[];

  @Prop() readonly assetType: string;

  @Prop({ default: false }) readonly isEditing: boolean;

  @Prop() readonly editDeploymentGuid: string;

  @PropSync('selectedItems') synchedSelectedItems: AssignmentShort[];

  fullCodingForm: CodingFormData[] | undefined = [];

  allSubDataSchemaData = [];

  subFullCodingForm: CodingFormData[] | undefined = []

  emptySubFullCodingForm: CodingFormData[] | undefined = []

  forceUpdateSubmitButton: string = uuid.v4();

  @deploymentModule.State('deploymentStandards') deploymentStandards: DataStandardViewReturn | undefined;

  @deploymentModule.Action(DeploymentsActions.FETCH_DEPLOYMENT_DATA_STANDARDS) fetchDataStandards;

  @deploymentModule.Action(DeploymentsActions.POST_DEPLOYMENT_DATA) postDeploymentData;

  @deploymentModule.Action(DeploymentsActions.PATCH_BIG_PIPE_DEPLOYMENT) patchBigPipeDeployment;

  @deploymentModule.State('bigPipeDeploymentData') bigPipeDeploymentData: BigPipeDeploymentData | undefined;

  @deploymentModule.State('bigPipeDeploymentDataLoading') bigPipeDeploymentDataLoading: boolean;

  @deploymentModule.State('deploymentNumberData') deploymentNumberData: string | undefined;

  @planningModule.State('patchWorkOrderLoading') patchWorkOrderLoading: boolean;

  @userModule.State('allUserData') allUsers: UserData[] | undefined;

  @mpsModule.State('mpsData') mpsData: iMPS;

  @assetModule.State('lineSegmentAccessPoints') accessPoints: LineSegmentAccessPoint[] | undefined;

  @assetModule.State('availableManholes') availableManholes: ManholeAccessPoint[] | undefined;

  @assetModule.Action(AssetActions.POST_CREATE_NEW_LINE_SEGMENT_ACCESS_POINT)
  postCreateNewLineSegmentAccessPoint;

  @assetModule.State('newLineSegmentAccessPoint') newLineSegmentAccessPoint: AssignmentShort | undefined;

  @assetModule.State('newLineSegmentAccessPointLoading') newLineSegmentAccessPointLoading: boolean;

  @assetModule.State('newLineSegmentAccessPointError') newLineSegmentAccessPointError: string | undefined;

  @assetModule.Action(AssetActions.FETCH_LINE_SEGMENT_ACCESSPOINTS) fetchLineSegmentAccessPoints;

  @assetModule.State('deploymentFormCouldNotInspectError') deploymentFormCouldNotInspectError: string | undefined;

  @assetModule.Action(AssetActions.PATCH_DEPLOYMENT_FORM_WORK_ORDER) patchDeploymentFormWorkOrders;

  @integritySnackBar.Action(SnackBarActions.SHOW) showSnackBar;

  ALWAYSREQUIRED = 'alwaysRequired';

  pages = [];

  subPages = [];

  connectionsCount = 0;

  dataStandardJson = null;

  dataStandardFormat;

  dataStandardSchema = {};

  subDataStandardFormat;

  subDataStandardSchema;

  subDataFormFlag = false;

  subCodeIndex = -1;

  errors = [];

  subErrors = [];

  selectedType = 'Form';

  selectedTypeOld = '';

  currentFormPageData = [];

  oldFormData = {};

  oldSubFormData = [];

  checkBoxHeaderNumbers = [];

  subCheckBoxHeaderNumbers = [];

  ajv = new Ajv2019({
    strict: false,
    strictSchema: false,
    strictTypes: false,
    strictRequired: false,
    strictNumbers: false,
    validateFormats: false,
    useDefaults: 'empty',
    allErrors: true,
    multipleOfPrecision: 6,
    unicodeRegExp: false,
  });

  validate = null;

  subValidate = null;

  couldNotInspectValidate = null;

  showValidationResults = true;

  isPatched = false;

  requiredFieldObjects: ConditionalRequirement;

  hasDownloaded = true;

  reportRefreshKey = 0;

  processingForm = false;

  assetAttributes = null;

  warningDialogOpen = false;

  submitWithWarningConfirmDialogOpen = false;

  // Keeping track of GUIDs created for each pipe connection/sub coding forms
  deploymentStandardsInspections = [];

  // Field names for required fields
  requiredFields = new Map<string, boolean>();

  errorExists = false;

  error = '';

  usManhole: ManholeAccessPoint = null;

  dsManhole: ManholeAccessPoint = null;

  allowCreateNewSegment = false;

  hasSorted = false;

  headersToAddAssetAsEnums = [
    'DeploymentMH',
    'DestinationMH',
  ];

  subSchemaNumberHeaders = [
    'PipeNumber',
    'AssetNumber',
    'AssetID',
    'DeploymentNumber',
  ]

  couldNotInspectDataStandardSchema = {
    properties: {
      IsInspected: {
        description: 'Inspected/CouldNotinspect',
        type: 'boolean',
      },
      Result: {
        description: 'Result',
        type: 'string',
        minLength: 1,
      },
      Comments: {
        description: 'Comments',
        type: 'string',
        minLength: 1,
      },
    },
    required: [
      'IsInspected',
      'Result',
      'Comments',
    ],
  }

  couldNotInspectFullCodingForm = [
    {
      header: 'Result',
      headerNumber: 100,
      isChanged: false,
      value: null,
    },
    {
      header: 'Comments',
      headerNumber: 101,
      isChanged: false,
      value: null,
    },
  ]

  couldNotInspectData = [
    {
      type: 'boolean',
      Order: 1,
      Width: 258,
      units: '',
      Height: 40,
      InputType: 'Toggle',
      exportable: 'N',
      exportname: 'IsInspected',
      headername: 'IsInspected',
      description: 'Inspected/Could Not inspect',
      headernumber: 1,
      relatedtable: '',
      prefilled: false,
      required: true,
    },
    {
      type: 'string',
      Order: 100,
      Width: 258,
      units: '',
      Height: 40,
      InputType: 'Dropdown',
      exportable: 'N',
      exportname: 'Result',
      headername: 'Result',
      description: 'Result',
      headernumber: 100,
      relatedtable: '',
      prefilled: false,
      required: true,
      enum: [
        'Could Not Access',
        'Could Not Locate',
        'Unable To Open Access Point',
        'Does Not Exist',
        'Traffic Control Required',
        'Reversal Required',
      ],
    },
    {
      type: 'string',
      Order: 101,
      Width: 258,
      units: '',
      Height: 40,
      InputType: 'Textbox',
      exportable: 'N',
      exportname: 'Comments',
      headername: 'Comments',
      description: 'Comments',
      headernumber: 101,
      relatedtable: '',
      prefilled: false,
      required: true,
    },
  ]

  showEditScope = false;

  editItems = [];

  drag = false;

  get segments(): string[] {
    return this.synchedSelectedItems.map((sel, i) => `Segment ${i + 1}: ${sel.nodes.name}`);
  }

  get isInspected(): boolean {
    const inspected = this.fullCodingForm.find((value) => value.header === 'IsInspected');
    if (inspected?.value == null || inspected.value === true) {
      return true;
    }
    return false;
  }

  get generatedMainDataStandardSchema(): any {
    const inspected = this.fullCodingForm.find((value) => value.header === 'IsInspected');
    if (inspected?.value == null) {
      return {};
    }
    if (inspected.value === true || this.isEditing) {
      return this.dataStandardSchema;
    }
    return this.couldNotInspectDataStandardSchema;
  }

  get generatedDataStandardJson(): any {
    const inspected = this.fullCodingForm.find((value) => value.header === 'IsInspected');
    if (inspected?.value == null) {
      return {};
    }
    if (inspected.value === true || this.isEditing) {
      return this.dataStandardJson;
    }
    return {};
  }

  get generatedCurrentFormPageData(): any {
    if (this.subDataFormFlag) {
      return this.currentFormPageData;
    }
    const inspected = this.fullCodingForm.find((value) => value.header === 'IsInspected');
    if (inspected?.value == null || inspected.value === true || this.isEditing) {
      this.selectedTypeChange();
      return this.currentFormPageData;
    }
    return this.couldNotInspectData;
  }

  get generatedValidate(): any {
    if (this.subDataFormFlag) {
      return this.subValidate;
    }
    const inspected = this.fullCodingForm.find((value) => value.header === 'IsInspected');
    if (inspected?.value == null || inspected.value === true) {
      return this.validate;
    }
    return this.couldNotInspectValidate;
  }

  getSegmentTabFromAssetId(assetId: string): string | undefined {
    const index = this.synchedSelectedItems.findIndex((value) => value.nodes.name === assetId);
    if (index > -1) {
      return `Segment ${index + 1}: ${assetId}`;
    }
    return undefined;
  }

  get codingFormValidBool(): boolean {
    return (
      this.errors !== undefined
      && (this.errors.length > 0 || (this.subErrors.length > 0 && this.isInspected))
    );
    // return true;
  }

  get mpsDataRequirements(): DataRequirement[] {
    if (!this.mpsData || !this.mpsData.dataRequirements || this.mpsData.dataRequirements === '') return [];

    return JSON.parse(this.mpsData.dataRequirements);
  }

  get projectInfoPlatform(): string {
    let retVal = '';

    if (this.mpsDataRequirements.length > 0) {
      this.mpsDataRequirements.forEach((dr) => {
        retVal += `${dr.inspectionType}, `;
      });
    }

    return retVal.substring(0, retVal.length - 2);
  }

  get projectInfoSensors(): string {
    let retVal = 'CCTV, ';

    if (this.mpsDataRequirements.length > 0) {
      const msi = this.mpsDataRequirements.find((mdr) => mdr.inspectionType === 'Multi-Sensor Inspection');
      if (msi) {
        const { sensors } = msi.inspectionDetails;

        if (sensors.Laser) retVal += 'Laser, ';
        if (sensors.Sonar) retVal += 'Sonar, ';
        if (sensors.Gas) retVal += 'Gas, ';
        if (sensors.Temperature) retVal += 'Temperature, ';
      }
    }

    return retVal.substring(0, retVal.length - 2);
  }

  get projectInfoOther(): any {
    const retVal = {
      photoRequirements: [],
      macpLevel: this.mpsData.macpLevel,
      macpSections: JSON.parse(this.mpsData.macpSections),
      gisReceived: this.mpsData.gisReceived ? 'Yes' : 'No',
      gisType: this.mpsData.gisType ?? '',
      gisContact: this.mpsData.gisContact ?? '',
      gisExpectations: this.mpsData.gisExpectations ?? '',
      customFields: [],
    };

    if (!this.mpsData) return retVal;

    const photoReq = [];
    if (this.mpsData.photoRequirementSurfaceCover) photoReq.push('The surface cover with manhole number clearly visible');
    if (this.mpsData.photoRequirementRim) photoReq.push('The rim of the manhole');
    if (this.mpsData.photoRequirementConnections) photoReq.push('Down the manhole to see pipe connections');
    if (this.mpsData.photoRequirementSurroundingAreas) photoReq.push('The surrounding areas');
    if (this.mpsData.photoRequirementOtherContent !== '') photoReq.push(this.mpsData.photoRequirementOtherContent);
    retVal.photoRequirements = photoReq;

    return retVal;
  }

  get dropdownItems(): AssignmentShort[] {
    // We are filtering by the first assets task type so
    //   we cannot select anything else but that specific task type (Responder, CCTV, Profiler)
    return this.projectWorkOrders
      .filter((wo) => wo.nodes.typeGuid === NODEITEM_LINESEGMENT_GUID
        && wo.taskTypeGuid === this.synchedSelectedItems[0].taskTypeGuid)
      .sort(this.sortWorkOrderByAsset);
  }

  get dragOptions(): any {
    return {
      animation: 200,
      group: 'description',
      disabled: false,
      ghostClass: 'ghost',
    };
  }

  async mounted(): Promise<void> {
    await this.fetchDataStandards();
  }

  @Watch('inspectionId')
  async onInspectionChange(): Promise<void> {
    await this.reset();
  }

  @Watch('selectedType')
  selectedTypeChange(): void {
    // TODO: split into functions
    // sub ds
    if (this.selectedType === undefined) {
      this.selectedType = this.selectedTypeOld;
      return;
    }

    if (this.selectedType === 'Form' && this.openForm) {
      this.selectedType = 'Deployment';
    }

    this.selectedTypeOld = this.selectedType;

    const isSubDataForm = this.selectedType.includes('Seg');
    this.subDataFormFlag = isSubDataForm;
    const currentDataStandardFormat = isSubDataForm
      ? this.subDataStandardFormat
      : this.dataStandardFormat;

    const foundAssetID = this.synchedSelectedItems.find(
      (value) => this.getSegmentTabFromAssetId(value.nodes.name) === this.selectedType,
    );
    if (foundAssetID != null) {
      let segmentIndex = -1;
      this.allSubDataSchemaData.forEach((a, i) => {
        if (segmentIndex === -1) {
          const found = a.find((segment: CodingFormData) => segment.header === 'AssetID' && segment.value === foundAssetID.nodes.name) !== undefined;
          if (found) segmentIndex = i;
        }
      });
      this.subFullCodingForm = this.allSubDataSchemaData[segmentIndex];
    }

    const searchParam = isSubDataForm ? 'Segments' : 'Main';
    const formPage = currentDataStandardFormat?.formpage.find(
      (fp) => fp.pagename === searchParam,
    );

    if (formPage && formPage.formpagedata) {
      this.currentFormPageData = formPage.formpagedata as FormPageData[];
    }

    this.currentFormPageData.forEach((form: FormPageData) => {
      // look for prefilled in main form
      const oldFormData = this.oldFormData[form.headername];
      form.prefilled = false;

      if (oldFormData != null && oldFormData.toString().trim() !== '') {
        form.prefilled = true;
      }

      form.required = this.requiredFields.has(form.headername)
        && this.requiredFields.get(form.headername);

      // look for hints
      if (
        this.dataStandardJson?.Requirements
        && Object.keys(this.dataStandardJson.Requirements.HeaderValues).includes(
          form.headername,
        )
      ) {
        form.hint = this.dataStandardJson.Requirements.HeaderValues[form.headername];
      }

      // look for enums
      const currentSchema = isSubDataForm
        ? this.subDataStandardSchema
        : this.generatedMainDataStandardSchema;
      const properties = Object.entries(currentSchema).find(
        (e) => e[0] === 'properties',
      )[1];

      this.requiredFieldObjects = this.findRequiredFields(currentSchema);

      if (properties === null) {
        return;
      }

      const currPropArr = Object.entries(properties).find(
        (p) => p[0] === form.headername,
      );

      if (
        currPropArr === undefined
        || currPropArr[1] === undefined
        || currPropArr[1].enum === undefined
      ) {
        return;
      }

      const currProp = currPropArr[1];
      form.enum = currProp.enum;
      this.validateForm();
    });
  }

  async onBigPipeDeploymentDataChange(): Promise<void> {
    // Return if we're not editing because this is only for the deployment table edit
    if (!this.isEditing) {
      return;
    }
    const inspected = this.fullCodingForm.find((value) => value.header === 'IsInspected');
    if (inspected != null) {
      inspected.value = true;
    }
    if (this.bigPipeDeploymentData != null) {
      this.fullCodingForm.forEach((cf: CodingFormData) => {
        const newKey = cf.header;
        if (newKey === 'Crew') {
          const users = this.getUsers();
          cf.value = [];
          this.bigPipeDeploymentData[newKey].forEach((value) => {
            const found = users.find((u) => u.guid === value);
            if (found) {
              (cf.value as string[]).push(found.text);
            }
          });
        } else if (newKey in this.bigPipeDeploymentData) {
          cf.value = this.bigPipeDeploymentData[newKey];
        }
      });
      const sortedOrder: string[] = Array(this.bigPipeDeploymentData.Segments.length).fill(null);
      this.bigPipeDeploymentData.Segments.forEach((value) => {
        const jsonParsed: BigPipeSegment = JSON.parse(value);
        const found = this.allSubDataSchemaData.find((subValue) => subValue.find((seg) => seg.header === 'AssetID' && seg.value === jsonParsed.AssetID) != null);
        if (found) {
          found.forEach((cf: CodingFormData) => {
            const newKey = cf.header;
            if (newKey in jsonParsed) {
              cf.value = jsonParsed[newKey];
            }
          });

          if (jsonParsed.index !== undefined) {
            sortedOrder[jsonParsed.index] = jsonParsed.AssetID;
          }
        }
      });
      this.sortSynchedSelectedItems(sortedOrder);
    }
  }

  @Watch('deploymentStandards')
  async onDeploymentStandardsUpdate(): Promise<void> {
    await this.reset();

    if (this.deploymentStandards === undefined) return;

    this.dataStandardFormat = JSON.parse(
      this.deploymentStandards.dataStandardMaindatastandardcomponentFormat.jsondata,
    );
    this.dataStandardSchema = JSON.parse(
      this.deploymentStandards.dataStandardMaindatastandardcomponentSchema.jsondata,
    );
    this.subDataStandardFormat = JSON.parse(
      this.deploymentStandards.dataStandardSubdatastandardcomponentFormat.jsondata,
    );
    this.subDataStandardSchema = JSON.parse(
      this.deploymentStandards.dataStandardSubdatastandardcomponentSchema.jsondata,
    );
    this.dataStandardJson = this.deploymentStandards.jsondata;

    if (!this.dataStandardFormat) {
      this.error = 'Error: Invalid Data Standard Format';
      this.errorExists = true;
      return;
    }
    if (!this.dataStandardSchema) {
      this.error = 'Error: Invalid Data Standard Schema';
      this.errorExists = true;
      return;
    }

    const reqs: string[] = [];
    this.pages = this.dataStandardFormat.formpage.map((m) => m.pagename);

    if (this.subDataStandardFormat != null) {
      this.subPages = this.subDataStandardFormat.formpage.map(
        (m) => m.pagename,
      );
    }

    this.pages.forEach((page) => {
      const uniqueNums = [];

      const formPage = this.dataStandardFormat.formpage.find(
        (fp) => fp.pagename === page,
      );
      if (formPage !== undefined) {
        formPage.formpagedata.forEach((pageData) => {
          const oldVal = this.oldFormData[pageData.headername];

          // check if header has already been added, if it was, skip
          if (
            // !this.isPopout
            this.fullCodingForm.findIndex(
              (fc) => fc.header === pageData.headername,
            ) === -1
          ) {
            this.fullCodingForm.push({
              header: pageData.headername,
              // instead of null we should check data type and fill as appropriate
              // strings = '' ints/nums = 0
              value: this.getCodingFormDataValue(oldVal, pageData),
              isChanged: !!(
                oldVal
                || (reqs && reqs.includes(pageData.headername))
              ),
              headerNumber: pageData.headernumber,
            });
          }

          // look for checkbox values
          const currNum = pageData.headernumber;
          if (pageData.type !== 'array') {
            if (!uniqueNums.includes(currNum) && pageData.type !== 'boolean') {
              uniqueNums.push(currNum);
            } else if (
              !uniqueNums.includes(currNum)
              && pageData.type === 'boolean'
            ) {
              uniqueNums.push(currNum);
              this.checkBoxHeaderNumbers.push(currNum);
            } else {
              this.checkBoxHeaderNumbers.push(currNum);
            }
          }
        });
      }
    });

    // Push any missing Full Coding Form data for the Could Not Inspect page view
    this.couldNotInspectFullCodingForm.forEach((value) => {
      const foundValue = this.fullCodingForm.find((fcf) => value.header === fcf.header);
      const found = foundValue != null;
      if (!found) {
        const valueCopy = JSON.parse(JSON.stringify(value));
        valueCopy.value = value.value != null ? value.value : '';
        this.fullCodingForm.push(valueCopy);
      } else {
        foundValue.value = value.value;
      }
    });

    this.subPages.forEach((page) => {
      const uniqueNums = [];

      const formPage = this.subDataStandardFormat.formpage.find(
        (fp) => fp.pagename === page,
      );
      if (formPage !== undefined) {
        formPage.formpagedata.forEach((pageData) => {
          const oldVal = this.oldSubFormData[pageData.headername];

          // check if header has already been added, if it was, skip
          if (
            // !this.isPopout
            this.emptySubFullCodingForm.findIndex(
              (fc) => fc.header === pageData.headername,
            ) === -1
          ) {
            this.emptySubFullCodingForm.push({
              header: pageData.headername,
              // instead of null we should check data type and fill as appropriate
              // strings = '' ints/nums = 0
              value: this.getCodingFormDataValue(oldVal, pageData),
              isChanged: !!(
                oldVal
                || (reqs && reqs.includes(pageData.headername))
              ),
              headerNumber: pageData.headernumber,
            });
          }

          // look for checkbox values
          const currNum = pageData.headernumber;
          if (pageData.type !== 'array') {
            if (!uniqueNums.includes(currNum) && pageData.type !== 'boolean') {
              uniqueNums.push(currNum);
            } else if (
              !uniqueNums.includes(currNum)
              && pageData.type === 'boolean'
            ) {
              uniqueNums.push(currNum);
              this.subCheckBoxHeaderNumbers.push(currNum);
            } else {
              this.subCheckBoxHeaderNumbers.push(currNum);
            }
          }
        });
      }
    });

    this.checkCheckBoxValues();

    // See if crew is in main: if so do something
    const crewFpd = this.dataStandardFormat.formpage[0].formpagedata.find((fp) => fp.exportname === 'Crew');
    crewFpd.enum = this.getUsers();
    crewFpd.multiple = true;

    // See if Sensors is in main: if so do something
    const sensorFpd = this.dataStandardFormat.formpage[0].formpagedata.find((fp) => fp.exportname === 'Sensors');
    sensorFpd.InputType = 'MultiText';
    sensorFpd.enum = ['CCTV', 'Laser', 'Lidar', 'Sonar', 'Gas', 'Temperature'];
    sensorFpd.multiple = true;

    // See if trucks is in main: if so do something
    const trucksFpd = this.dataStandardFormat.formpage[0].formpagedata.find((fp) => fp.exportname === 'Truck');
    trucksFpd.InputType = 'Text';

    // See if robot is in main: if so do something
    const robotFpd = this.dataStandardFormat.formpage[0].formpagedata.find((fp) => fp.exportname === 'Robot');
    robotFpd.InputType = 'Text';

    const deploymentNumber = this.dataStandardFormat.formpage[0].formpagedata.find((fp) => fp.exportname === 'DeploymentNumber');
    deploymentNumber.InputType = 'Text';

    // See if result is in sub
    const resultFpdIndex = this.subDataStandardFormat.formpage[0].formpagedata
      .findIndex((fp) => fp.exportname === 'Result');
    this.subDataStandardFormat.formpage[0].formpagedata.push(
      this.subDataStandardFormat.formpage[0].formpagedata.splice(resultFpdIndex, 1)[0],
    );

    // See if we can fill in assets dropdown
    await this.onSelectedItemsChange();

    // eslint-disable-next-line prefer-destructuring
    this.selectedType = 'Deployment';

    this.requiredFieldObjects = this.findRequiredFields(
      this.generatedMainDataStandardSchema,
    );

    // fill segments if we have any
    this.fillSegments();

    // Ajv throws an error if we try to add formats if they already exist
    if (Object.keys(this.ajv.formats).length === 0) {
      addFormats(this.ajv);
    }
    this.validate = this.ajv.compile(this.dataStandardSchema);
    this.couldNotInspectValidate = this.ajv.compile(this.couldNotInspectDataStandardSchema);
    if (this.subDataStandardSchema) {
      this.subValidate = this.ajv.compile(this.subDataStandardSchema);
    }
    this.validateForm();
  }

  @Watch('selectedItems')
  async onSelectedItemsChange(): Promise<void> {
    this.reset();
    if (this.assetType === 'Line Segment') {
      const workOrderGuids = this.synchedSelectedItems.map((si) => si.guid);
      if (workOrderGuids.length === 0) {
        return;
      }
      await this.fetchLineSegmentAccessPoints(workOrderGuids);
      // console.log(this.accessPoints);
      this.allSubDataSchemaData = [];
      this.fillSegments();
    }
    // Look for the inspected radio button and change it to false if it's true.
    const inspectedRadioButtons = this.fullCodingForm.find((value) => value.header === 'IsInspected');
    if (inspectedRadioButtons != null && inspectedRadioButtons.value !== true) {
      inspectedRadioButtons.value = false;
    }
    // Look for crew
    const crewForm = this.fullCodingForm.find((fcf) => fcf.header === 'Crew');
    if (this.synchedSelectedItems) {
      crewForm.value = Array.from(
        new Set(this.synchedSelectedItems.map((x) => x.workerNames).flat()),
      );
    }
    // Look for sensors
    const sensorForm = this.fullCodingForm.find((fcf) => fcf.header === 'Sensors');
    if (this.accessPoints) sensorForm.value = this.accessPoints[0].sensors;
    // See if we can fill in assets dropdown
    const deploymentFpd = this.dataStandardFormat.formpage[0].formpagedata.filter(
      (fp) => this.headersToAddAssetAsEnums.includes(fp.exportname),
    );
    const deploymentSubUpMH = this.subDataStandardFormat.formpage[0].formpagedata.find((fp) => fp.exportname === 'UpStreamMh');
    const deploymentSubDownMH = this.subDataStandardFormat.formpage[0].formpagedata.find((fp) => fp.exportname === 'DnStreamMh');
    // if (this.assetType === 'Manhole') {
    //   deploymentFpd.enum = this.selectedItems.map((item) => item.nodes.name);
    // }
    if (this.assetType === 'Line Segment' && this.accessPoints !== undefined) {
      const accessPoints = this.accessPoints.flatMap((ap) => [ap.upStream.name, ap.dnStream.name]);
      deploymentFpd.forEach((value) => {
        if (value != null) {
          value.enum = accessPoints;
        }
      });
    }
    let wasSubDataEnumsSet = false;
    if (deploymentFpd != null) {
      const foundEnumValue = deploymentFpd.find((value) => value.enum != null);
      if (foundEnumValue != null) {
        deploymentSubUpMH.enum = [...foundEnumValue.enum];
        deploymentSubDownMH.enum = [...foundEnumValue.enum];
        wasSubDataEnumsSet = true;
      }
    }
    if (!wasSubDataEnumsSet) {
      deploymentSubUpMH.enum = [];
      deploymentSubDownMH.enum = [];
    }
    if (this.isEditing) {
      await this.onBigPipeDeploymentDataChange();
    }

    // repopulate deployment number on scope change
    this.onDeploymentNumberDataChange();
    this.validateForm();
  }

  @Watch('deploymentNumberData')
  onDeploymentNumberDataChange(): void {
    const deploymentNumberForm = this.fullCodingForm.find((fcf) => fcf.header === 'DeploymentNumber');
    if (this.editDeploymentGuid) {
      return;
    }
    deploymentNumberForm.value = `${this.deploymentNumberData}`;
  }

  @Watch('fullCodingForm', { deep: true })
  onFullCodingFormChange(): void {
    // Populate the sensors dropdown with any sensors that aren't included in the default options
    // but is included in the deployment
    const sensorFpd = this.dataStandardFormat.formpage[0].formpagedata.find((fp) => fp.exportname === 'Sensors');
    const sensors = this.fullCodingForm.find((fcf) => fcf.header === 'Sensors');
    const sensorsValue = sensors.value as Array<string>;

    if (sensorsValue.length > 0) {
      sensorsValue.forEach((sensor) => {
        if (!sensorFpd.enum.includes(sensor)) {
          sensorFpd.enum.push(sensor);
        }
      });
    }
  }

  fillSegments(): void {
    this.synchedSelectedItems.map((si) => si.nodes).forEach((node) => {
      const found = this.allSubDataSchemaData.find((a) => a.find((segment) => segment.header === 'AssetID' && segment.value === node.name)) !== undefined;
      if (!found) {
        const newSegment: CodingFormData[] = JSON.parse(
          JSON.stringify(this.emptySubFullCodingForm),
        );
        newSegment.find((segment) => segment.header === 'AssetID').value = node.name;
        const foundLength = newSegment.find((value) => value.header === 'GisLength');
        const foundLocation = newSegment.find((value) => value.header === 'Location');
        const foundUp = newSegment.find((value) => value.header === 'UpStreamMh');
        const foundDn = newSegment.find((value) => value.header === 'DnStreamMh');
        const foundPipeMaterial = newSegment.find((value) => value.header === 'PipeMaterial');
        const foundPipeSize = newSegment.find((value) => value.header === 'PipeSize');
        const foundPipeShape = newSegment.find((value) => value.header === 'PipeShape');
        const foundPipeLining = newSegment.find((value) => value.header === 'PipeLining');
        const foundPurpose = newSegment.find((value) => value.header === 'Purpose');
        const foundStartTime = newSegment.find((value) => value.header === 'StartTime');
        const foundEndTime = newSegment.find((value) => value.header === 'EndTime');
        const foundComments = newSegment.find((value) => value.header === 'SegmentCommments');
        const foundMeasurement = newSegment.find((value) => value.header === 'Measurement');
        const foundAccessPoint: LineSegmentAccessPoint = this.accessPoints.find(
          (value) => value.guid === node.guid,
        );
        if (foundAccessPoint != null) {
          if (foundAccessPoint.gisLength != null && foundLength != null) {
            foundLength.value = foundAccessPoint.gisLength;
          }
          if (foundAccessPoint.location != null && foundLocation != null) {
            foundLocation.value = foundAccessPoint.location;
          }
          if (foundAccessPoint.upStream
              && foundAccessPoint.upStream.name
              && foundUp !== undefined) {
            foundUp.value = foundAccessPoint.upStream.name;
          }
          if (foundAccessPoint.dnStream
              && foundAccessPoint.dnStream.name
              && foundDn !== undefined) {
            foundDn.value = foundAccessPoint.dnStream.name;
          }
          if (foundPipeMaterial != null && foundAccessPoint.pipeMaterial) {
            foundPipeMaterial.value = foundAccessPoint.pipeMaterial;
          }
          if (foundPipeSize != null && foundAccessPoint.pipeSize) {
            foundPipeSize.value = foundAccessPoint.pipeSize;
          }
          if (foundPipeShape != null && foundAccessPoint.pipeShape) {
            foundPipeShape.value = foundAccessPoint.pipeShape;
          }
          if (foundPipeLining != null && foundAccessPoint.pipeLining) {
            foundPipeLining.value = foundAccessPoint.pipeLining;
          }
          if (foundPurpose != null && foundAccessPoint.purpose) {
            foundPurpose.value = foundAccessPoint.purpose;
          }
          if (foundStartTime != null && foundAccessPoint.startTime) {
            foundStartTime.value = foundAccessPoint.startTime;
          }
          if (foundEndTime != null && foundAccessPoint.endTime) {
            foundEndTime.value = foundAccessPoint.endTime;
          }
          if (foundComments != null && foundAccessPoint.comments) {
            foundComments.value = foundAccessPoint.comments;
          }
          if (foundMeasurement != null && foundAccessPoint.measurement) {
            foundMeasurement.value = foundAccessPoint.measurement;
          }
        }
        this.allSubDataSchemaData.push(newSegment);
      }
    });
  }

  formDataArrayToObject(codingForm: any, mainInspection: boolean): any {
    if (!codingForm) {
      return false;
    }
    const retVal = codingForm.reduce(
      (a, v) => ({ ...a, [v.header]: v.value }),
      {},
    );
    if (mainInspection) {
      // remove unchanged values
      const delHeaders = this.fullCodingForm
        .filter((fc) => fc.isChanged === false)
        .map((fc) => fc.header);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      delHeaders.forEach((header) => {
        // delete retVal[header];
      });
    }
    return retVal;
  }

  validateForm(): void {
    // validateForm
    this.requiredFieldObjects = this.findRequiredFields(
      this.subDataFormFlag ? this.subDataStandardSchema : this.generatedMainDataStandardSchema,
    );
    this.applyRequiredFields(this.requiredFieldObjects);
    this.applyHintFields();
    this.autoFillAutoProcessedFields();
    const currValidFunc = this.generatedValidate;
    if (typeof currValidFunc === 'function') {
      const currCodingForm = this.subDataFormFlag ? this.subFullCodingForm : this.fullCodingForm;
      const valid = currValidFunc(
        this.formDataArrayToObject(currCodingForm, true),
      );
      if (!valid && currValidFunc.errors != null) {
        if (this.subDataFormFlag) {
          // console.log('sub errors');
          this.subErrors = currValidFunc.errors;
          // console.log(currValidFunc.errors);
        } else {
          this.errors = currValidFunc.errors;
          // console.log('regular errors');
        }
      } else if (this.subDataFormFlag) {
        this.subErrors = [];
      } else { this.errors = []; }
    }
    this.forceUpdateSubmitButton = uuid.v4();
  }

  preSubmitForm(): void {
    if (this.getSegmentDataIssue().mainMessage !== ''
      && !this.submitWithWarningConfirmDialogOpen
    ) {
      this.toggleSubmitWithWarningConfirmDialogOpen();
      return;
    }
    if (this.submitWithWarningConfirmDialogOpen) {
      this.toggleSubmitWithWarningConfirmDialogOpen();
    }
    this.submitForm();
  }

  @Emit('saveResults')
  async submitForm(): Promise<string> {
    // format data for post
    // translate crew names back to guids
    if (this.isInspected) {
      const crewCf = this.fullCodingForm.find((cf) => cf.header === 'Crew');
      const crewNames = crewCf !== undefined ? crewCf.value : [];
      const crewGuids = [];
      const users = this.getUsers();
      (crewNames as any[]).forEach((name) => {
        if (name != null && name !== '') {
          const match = users.find(
            (u) => name === u.text,
          );
          if (match !== undefined) crewGuids.push(match.guid);
        }
      });
      this.fullCodingForm.forEach((cf: CodingFormData) => {
        const newKey = cf.header;
        const newVal = cf.value;
        const newObj = { [newKey]: newVal };
        Object.assign(this.oldFormData, newObj);
      });
      this.oldFormData['Crew'] = crewGuids;

      const segments = [];
      this.allSubDataSchemaData.forEach((subData, i) => {
        segments[i] = {};
        let assetId = '';
        let result = '';
        subData.forEach((cf: CodingFormData) => {
          const newKey = cf.header;
          const newVal = cf.value;
          const newObj = { [newKey]: newVal };
          Object.assign(segments[i], newObj);
          // Match headers
          if (cf.header === 'AssetID') assetId = cf.value as string;
          if (cf.header === 'Result') result = cf.value as string;
        });
        let workOrderGuid = '';
        let resultGuid = '';
        this.synchedSelectedItems.forEach((si) => {
          if (si.nodes.name === assetId) workOrderGuid = si.guid;
        });
        if (TASK_RESULT_DETAILS.map((trd) => trd.description).includes(result)) {
          resultGuid = TASK_RESULT_DETAILS.find((trd) => trd.description === result).guid;
        }
        const workOrderGuidObject = {
          WorkOrderGuid:
        workOrderGuid,
        };
        const resultGuidObject = {
          Result:
        resultGuid,
        };
        const foundItem = this.synchedSelectedItems
          .findIndex((value) => value.nodes.name === assetId);
        const indexObject = {
          index: foundItem,
        };
        Object.assign(segments[i], workOrderGuidObject);
        Object.assign(segments[i], resultGuidObject);
        Object.assign(segments[i], indexObject);
      });

      const segmentsJson = [];
      segments.forEach((segment) => {
        segmentsJson.push(JSON.stringify(segment));
      });

      this.oldFormData['Segments'] = segmentsJson;
      try {
        if (this.isEditing) {
          await this.patchBigPipeDeployment({
            deploymentGuid: this.editDeploymentGuid,
            deploymentData: this.oldFormData,
          });
        } else {
          await this.postDeploymentData(this.oldFormData);
        }
        return 'OK';
      } catch (e) {
        return 'Error when saving deployment';
      }
    } else {
      const result = this.fullCodingForm.find((value) => value.header === 'Result');
      if (result?.value === null) {
        return 'Error: Deployment form didn\'t have a result.';
      }
      const comments = this.fullCodingForm.find((value) => value.header === 'Comments');
      if (comments?.value === null) {
        return 'Error: Deployment form didn\'t have a comment.';
      }

      const dtos: DeploymentFormDoNotInspectDTO[] = [];

      this.synchedSelectedItems.forEach((value) => {
        dtos.push({
          workOrderGuid: value.guid,
          taskResultGuid: TASK_RESULT_DETAILS.find((tr) => tr.description === result.value).guid,
          comments: comments.value.toString(),
        });
      });

      try {
        await this.patchDeploymentFormWorkOrders(dtos);
        return 'OK';
      } catch (e) {
        return 'Error when saving deployment';
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  getCodingFormDataValue(
    oldVal: any,
    pageData: any,
  ): string | number | boolean | any[] {
    if (oldVal) {
      if (pageData.type === 'array') {
        return Array.isArray(oldVal) ? oldVal : [];
      }
      if ((oldVal as string).length === 0) {
        // eslint-disable-next-line no-param-reassign
        oldVal = null;
      } else if (pageData.type === 'integer') {
        // eslint-disable-next-line no-param-reassign
        oldVal = parseInt(oldVal as string, 10);
      } else if (pageData.type === 'number') {
        // eslint-disable-next-line no-param-reassign
        oldVal = parseFloat(oldVal as string);
      }
      return oldVal;
    }

    if (oldVal === 0) {
      if (pageData.type === 'integer' || pageData.type === 'number') {
        return 0;
      }
    }

    if (pageData.type === 'string') return '';
    if (pageData.type === 'integer' || pageData.type === 'number') return null;
    if (pageData.type === 'boolean') return false;
    if (pageData.type === 'array') return [];
    return null;
  }

  // false means not empty
  isEmptyValue(value: string | number | boolean | []): boolean {
    if (typeof value === 'string') return (value as string).length === 0;
    if (Array.isArray(value)) return value.length === 0;
    return false;
  }

  checkCheckBoxValues(): void {
    // check full coding form
    const fcCheckBoxes = this.fullCodingForm.filter(
      (fc: CodingFormData) => this.checkBoxHeaderNumbers.includes(fc.headerNumber),
    );
    const checkedBoxes = fcCheckBoxes.find(
      (fc: CodingFormData) => fc.value === true,
    );
    if (checkedBoxes !== undefined) {
      for (let i = 0; i < fcCheckBoxes.length; i += 1) {
        fcCheckBoxes[i].isChanged = true;
      }
    }
    // check sub  coding form
    const scCheckBoxes = this.emptySubFullCodingForm.filter(
      (fc: CodingFormData) => this.checkBoxHeaderNumbers.includes(fc.headerNumber),
    );
    const scCheckedBoxes = scCheckBoxes.find(
      (fc: CodingFormData) => fc.value === true,
    );
    if (scCheckedBoxes !== undefined) {
      for (let i = 0; i < scCheckBoxes.length; i += 1) {
        scCheckBoxes[i].isChanged = true;
      }
    }
  }

  toggleWarningDialog(): void {
    this.warningDialogOpen = !this.warningDialogOpen;
  }

  toggleSubmitWithWarningConfirmDialogOpen(): void {
    this.submitWithWarningConfirmDialogOpen = !this.submitWithWarningConfirmDialogOpen;
  }

  getSaveButtonColor(): string | undefined {
    if (this.getSegmentDataIssue().mainMessage !== '') {
      return 'amber';
    }
    return undefined;
  }

  getSaveButtonTooltip(): string | undefined {
    if (this.getSegmentDataIssue().mainMessage !== '') {
      return 'Submit valid coding - Check warnings';
    }
    return 'Submit valid coding';
  }

  getSegmentDataIssue(): DeploymentFormSegmentIssueEntry {
    const inspected = this.fullCodingForm.find((value) => value.header === 'IsInspected');
    if (inspected?.value == null || inspected.value === false) {
      return { mainMessage: '', helpMessage: '' };
    }
    const foundFormDestinationMH = this.fullCodingForm.find((value) => value.header === 'DestinationMH');
    if (foundFormDestinationMH?.value == null && foundFormDestinationMH?.value === '') {
      return {
        mainMessage: 'Could not find the destination manhole',
        helpMessage: 'Please select a destination',
      };
    }
    this.fullCodingForm.forEach((cf: CodingFormData) => {
      const newKey = cf.header;
      const newVal = cf.value;
      const newObj = { [newKey]: newVal };
      Object.assign(this.oldFormData, newObj);
    });
    const segments = [];
    this.allSubDataSchemaData.forEach((subData, i) => {
      segments[i] = {};
      subData.forEach((cf: CodingFormData) => {
        const newKey = cf.header;
        const newVal = cf.value;
        const newObj = { [newKey]: newVal };
        Object.assign(segments[i], newObj);
      });
    });
    const isUpstream = (this.oldFormData['Direction'] as string)?.toLowerCase()?.charAt(0) === 'u';
    const startingSegment = segments.find((value) => {
      if (isUpstream && value['DnStreamMh'] === this.oldFormData['DeploymentMH']) {
        return true;
      }
      if (!isUpstream && value['UpStreamMh'] === this.oldFormData['DeploymentMH']) {
        return true;
      }
      return false;
    });
    if (startingSegment == null) {
      if (this.oldFormData['DeploymentMH'] == null || this.oldFormData['DeploymentMH'] === '') {
        return {
          mainMessage: 'Invalid deployment manhole',
          helpMessage: 'Please select a deployment manhole',
        };
      }
      return {
        mainMessage: 'Invalid deployment manhole',
        helpMessage: `${this.oldFormData['DeploymentMH']} does not match ${isUpstream ? 'a downstream' : 'an upstream'} manhole`,
      };
    }
    if (this.oldFormData['DestinationMH'] == null || this.oldFormData['DestinationMH'] === '') {
      return {
        mainMessage: 'Invalid destination manhole',
        helpMessage: 'Please select a destination manhole',
      };
    }
    if (this.oldFormData['DeploymentMH'] === this.oldFormData['DestinationMH']) {
      return {
        mainMessage: 'Duplicate manhole entries',
        helpMessage: 'Please ensure deployment and destination manholes are the same',
      };
    }
    let destManole = '';
    for (let i = 0; i < segments.length; i += 1) {
      if (segments[i]['UpStreamMh'] == null || segments[i]['UpStreamMh'] === '') {
        return {
          mainMessage: 'Invalid upstream manhole',
          helpMessage: `Segment ${segments[i]['AssetID']} does not have an upstream manhole`,
        };
      }
      if (segments[i]['DnStreamMh'] == null || segments[i]['DnStreamMh'] === '') {
        return {
          mainMessage: 'Invalid downstream manhole',
          helpMessage: `Segment ${segments[i]['AssetID']} does not have a downstream manhole`,
        };
      }
      if (segments[i]['UpStreamMh'] === segments[i]['DnStreamMh']) {
        return {
          mainMessage: 'Duplicate manhole entries',
          helpMessage: `Please ensure segment ${segments[i]['AssetID']} upstream and downstream manholes are correct`,
        };
      }
      destManole = isUpstream
        ? startingSegment['UpStreamMh'] : startingSegment['DnStreamMh'];
      const typescriptSafeDestManole = destManole;
      const foundSegment = segments.find((value) => {
        if (isUpstream && value['DnStreamMh'] === typescriptSafeDestManole) {
          return true;
        }
        if (!isUpstream && value['UpStreamMh'] === typescriptSafeDestManole) {
          return true;
        }
        return false;
      });
      if (foundSegment == null && i < segments.length - 1) {
        // isUpstream in this message is backwards because we want the entry manhole
        // which will be the opposite of what direction we're going
        return {
          mainMessage: 'Impossible destination manhole',
          helpMessage: `Cannot find segment with ${destManole} as the ${isUpstream ? 'downstream' : 'upstream'} manhole`,
        };
      }
      if (i === segments.length - 1) {
        return { mainMessage: '', helpMessage: '' };
      }
      const newDestManhole = isUpstream ? foundSegment['UpStreamMh'] : foundSegment['DnStreamMh'];
      if (newDestManhole != null && newDestManhole !== '') {
        destManole = newDestManhole;
      } else {
        return {
          mainMessage: 'Impossible destination manhole',
          helpMessage: `Segment ${segments[i]['AssetID']} does not have ${isUpstream ? 'an upstream' : 'a downstream'} manhole`,
        };
      }
    }
    if (destManole === '') {
      return {
        mainMessage: 'Impossible destination manhole',
        helpMessage: `Starting segment does not have ${isUpstream ? 'an upstream' : 'a downstream'} manhole`,
      };
    }

    return { mainMessage: '', helpMessage: '' };
  }

  getSegmentsWithDestinationManholeConnectionIssues(): string[] {
    const returnValue: string[] = [];
    return returnValue;
  }

  setDefaultDestinationManhole(segments: any[]): void {
    const isUpstream = (this.oldFormData['Direction'] as string).toLowerCase().charAt(0) === 'u';
    const startingSegment = segments.find((value) => {
      if (isUpstream && value['DnStreamMh'] === this.oldFormData['DeploymentMH']) {
        return true;
      }
      if (!isUpstream && value['UpStreamMh'] === this.oldFormData['DeploymentMH']) {
        return true;
      }
      return false;
    });
    if (startingSegment != null) {
      this.oldFormData['DestinationMH'] = isUpstream
        ? startingSegment['UpStreamMh'] : startingSegment['DnStreamMh'];
      for (let i = 0; i < segments.length; i += 1) {
        const foundSegment = segments.find((value) => {
          if (isUpstream && value['DnStreamMh'] === this.oldFormData['DestinationMH']) {
            return true;
          }
          if (!isUpstream && value['UpStreamMh'] === this.oldFormData['DestinationMH']) {
            return true;
          }
          return false;
        });
        if (foundSegment == null) {
          break;
        }
        this.oldFormData['DestinationMH'] = isUpstream ? foundSegment['UpStreamMh'] : foundSegment['DnStreamMh'];
      }
    }
    this.oldFormData['DestinationMH'] = '';
  }

  popOutForm(): void {
    this.$emit('togglePopout');
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  beforeUnloadPop(_event: any): void {
    window.opener.postMessage(
      {
        fullCodingForm: this.fullCodingForm,
        subFullCodingForm: this.subFullCodingForm,
      },
      window.location.origin,
    );
  }

  getErrorCount(formPageName: string): number {
    let retVal = 0;
    const formPage = this.dataStandardFormat?.formpage.find(
      (fp) => fp.pagename === formPageName,
    );
    if (formPage !== null && formPage !== undefined) {
      formPage.formpagedata.forEach((d) => {
        if (
          this.errors.find((e) => e.instancePath.split('/')[1] === d.headername)
        ) {
          retVal += 1;
        }
      });
    }
    return retVal;
  }

  findRequiredFields(schema: any): ConditionalRequirement {
    if (schema?.required) {
      return {
        conditionalField: this.ALWAYSREQUIRED,
        comparerValue: true,
        requiredOnThen: schema.required as string[],
        // everything else can be empty
        requiredOnElse: [],
        conditionalsOnElse: [],
        conditionalsOnThen: [],
        optionalOnElse: [],
        optionalOnThen: [],
      };
    }
    return this.findRequiredFieldsRecursive(schema);
  }

  findRequiredFieldsRecursive(schema: any): ConditionalRequirement {
    if (schema == null) return null;
    const isOf = this.checkForOf(schema);
    if (isOf) {
      return {
        conditionalField: this.ALWAYSREQUIRED,
        comparerValue: true,
        conditionalsOnThen: (() => {
          const retVal: ConditionalRequirement[] = [];
          schema[isOf].forEach((element) => {
            const newRequirement = this.findRequiredFieldsRecursive(element);
            if (newRequirement != null) retVal.push(newRequirement);
          });
          return retVal;
        })(),
        // everything else can be empty
        requiredOnElse: [],
        requiredOnThen: [],
        conditionalsOnElse: [],
        optionalOnElse: [],
        optionalOnThen: [],
      };
    }
    const conditionalRequirement: ConditionalRequirement = {
      conditionalField: '',
      comparerValue: '',
      requiredOnElse: [],
      conditionalsOnElse: [],
      requiredOnThen: [],
      conditionalsOnThen: [],
      optionalOnElse: [],
      optionalOnThen: [],
    };
    // check for property and const or enum
    if (schema.if) {
      // eslint-disable-next-line prefer-destructuring
      const propName = Object.entries(schema.if.properties)[0][0];
      const path = schema.if.properties[propName];
      conditionalRequirement.conditionalField = propName;
      // eslint-disable-next-line prefer-destructuring
      conditionalRequirement.comparerValue = Object.entries(path)[0][1] as any;
    }
    if (schema.else) {
      if (schema.else.required) {
        conditionalRequirement.requiredOnElse = schema.else.required;
      }
      const keys = Object.keys(schema.else);
      keys.forEach((key) => {
        if (ConditionalType.includes(key)) {
          conditionalRequirement.conditionalsOnElse.push(
            this.findRequiredFieldsRecursive(schema.else),
          );
        }
        if (key === 'properties') {
          Object.entries(schema.else.properties).forEach((entry) => {
            const property = this.checkProperties(entry);
            if (property) {
              conditionalRequirement.optionalOnElse.push(property);
            }
          });
        }
      });
    }
    if (schema.then) {
      if (schema.then.required) {
        conditionalRequirement.requiredOnThen = schema.then.required;
      }
      const keys = Object.keys(schema.then);
      keys.forEach((key) => {
        if (ConditionalType.includes(key)) {
          conditionalRequirement.conditionalsOnThen.push(
            this.findRequiredFieldsRecursive(schema.then),
          );
        }
        if (key === 'properties') {
          Object.entries(schema.then.properties).forEach((entry) => {
            const property = this.checkProperties(entry);
            if (property) {
              conditionalRequirement.optionalOnThen.push(property);
            }
          });
        }
      });
    }
    return conditionalRequirement;
  }

  checkForOf(schema: any): string | null {
    if (schema.allOf) {
      return 'allOf';
    }
    if (schema.anyOf) {
      return 'anyOf';
    }
    return null;
  }

  // return property name when minlength is 0 or type includes null
  checkProperties(entry: any): string | undefined {
    if (entry[1].minLength !== undefined || entry[1].type !== undefined) {
      if (entry[1].minLength === 0) {
        return entry[0];
      }
      if (Array.isArray(entry[1].type) && entry[1].type.includes('null')) {
        return entry[0];
      }
    }
    return undefined;
  }

  applyHintFields(): void {
    this.currentFormPageData.forEach((form: FormPageData) => {
      // look for hints
      if (
        this.dataStandardJson?.Requirements
        && Object.keys(this.dataStandardJson.Requirements.HeaderValues).includes(
          form.headername,
        )
      ) {
        form.hint = this.dataStandardJson.Requirements.HeaderValues[form.headername];
      }
    });
  }

  applyRequiredFields(conditionalRequirement: ConditionalRequirement): void {
    // clear required fields
    this.requiredFields.clear();
    this.currentFormPageData.forEach((fpd) => {
      fpd.required = false;
    });
    this.applyRequiredFieldsRecursive(conditionalRequirement);
    // apply required to current form page
    this.requiredFields.forEach((value, requiredField) => {
      const field = this.currentFormPageData.find(
        (fpd) => fpd.headername === requiredField,
      );
      if (field !== undefined) {
        field.required = value;
      }
    });
  }

  applyRequiredFieldsRecursive(
    conditionalRequirement: ConditionalRequirement,
  ): void {
    if (!conditionalRequirement) return;
    let conditionalMet = false;
    const { conditionalField, comparerValue } = conditionalRequirement;
    // find field value
    const fieldValue = conditionalField === this.ALWAYSREQUIRED
      ? true
      : this.fullCodingForm.find((cf) => cf.header === conditionalField)
        ?.value;

    // compare to comparer values
    // skip if field is undefined
    if (fieldValue !== undefined) {
      // see if comparer value is valid depending on the conditional type
      // have to check it differently if it's an array
      if (Array.isArray(comparerValue)) {
        if (comparerValue.some((cv) => cv === (fieldValue as string))) {
          conditionalMet = true;
        }
      } else if (comparerValue === fieldValue) {
        conditionalMet = true;
      }
      // true, process on thens
      if (conditionalMet) {
        const { requiredOnThen, conditionalsOnThen, optionalOnThen } = conditionalRequirement;
        requiredOnThen.forEach((field) => {
          this.requiredFields.set(field, true);
        });
        optionalOnThen.forEach((field) => {
          this.requiredFields.set(field, false);
        });
        conditionalsOnThen.forEach((conditional) => {
          this.applyRequiredFieldsRecursive(conditional);
        });
      }
      // false, process on elses
      if (!conditionalMet) {
        const { requiredOnElse, conditionalsOnElse, optionalOnElse } = conditionalRequirement;
        requiredOnElse.forEach((field) => {
          this.requiredFields.set(field, true);
        });
        optionalOnElse.forEach((field) => {
          this.requiredFields.set(field, false);
        });
        conditionalsOnElse.forEach((conditional) => {
          this.applyRequiredFieldsRecursive(conditional);
        });
      }
    }
  }

  reset(): void {
    this.subDataFormFlag = false;
    this.subCodeIndex = -1;
    this.errors = [];
    this.subErrors = [];
    this.errorExists = false;
    this.error = '';
    this.selectedType = 'Deployment';
    this.selectedType = 'Form';
    this.currentFormPageData = [];
    this.oldSubFormData = [];
    // this.checkBoxHeaderNumbers = [];
    this.oldFormData = {};
    this.deploymentStandardsInspections = [];

    if (this.dataStandardFormat !== undefined) {
      this.pages = this.dataStandardFormat.formpage.map((m) => m.pagename);

      if (this.subDataStandardFormat != null) {
        this.subPages = this.subDataStandardFormat.formpage.map(
          (m) => m.pagename,
        );
      }

      this.pages.forEach((page) => {
        const formPage = this.dataStandardFormat.formpage.find(
          (fp) => fp.pagename === page,
        );
        if (formPage !== undefined) {
          formPage.formpagedata.forEach((pageData) => {
            const oldVal = this.oldFormData[pageData.headername];
            this.fullCodingForm.find(
              (fc) => fc.header === pageData.headername,
            ).value = this.getCodingFormDataValue(oldVal, pageData);
          });
        }
      });

      this.subPages.forEach((page) => {
        const formPage = this.subDataStandardFormat.formpage.find(
          (fp) => fp.pagename === page,
        );
        if (formPage !== undefined) {
          formPage.formpagedata.forEach((pageData) => {
            const oldVal = this.oldSubFormData[pageData.headername];
            this.emptySubFullCodingForm.find(
              (fc) => fc.header === pageData.headername,
            ).value = this.getCodingFormDataValue(oldVal, pageData);
          });
        }
      });
    }
  }

  getBackgroundColor(): any {
    const retVal = this.selectedType === 'Report'
      ? { background: '#CCCCCC' }
      : { background: 'white' };
    return retVal;
  }

  generateReport(): void {
    this.hasDownloaded = false;
    // eslint-disable-next-line dot-notation
    this.$refs.html2Pdf['generatePdf']();
  }

  autoFillAutoProcessedFields(): void {
    this.currentFormPageData.forEach((form: FormPageData) => {
      // look for hints
      if (form.hint != null && form.hint !== '') {
        const splitHint = form.hint.split(' ');
        const item = this.fullCodingForm.find(
          (f) => f.header === form.headername,
        );
        const val1 = this.fullCodingForm.find(
          (f) => f.header === splitHint[0],
        ).value;
        const val2 = this.fullCodingForm.find(
          (f) => f.header === splitHint[2],
        ).value;
        const operator = splitHint[1];
        switch (operator) {
          case '+':
            item.value = (val1 as number) + (val2 as number);
            break;
          case '-':
            item.value = (val1 as number) - (val2 as number);
            break;
          default:
            throw new Error('Invalid operator');
        }
      }
    });
  }

  getUsers(): any[] {
    const newCrewNameList = [];
    this.allUsers.forEach((user) => {
      if (
        user.role === FIELD_CREW[0]
        || user.role === FIELD_CREW[1]
        || user.role === CREW_LEAD
      ) {
        newCrewNameList.push({
          text: `${user.firstname} ${user.lastname}`,
          guid: user.guid,
        });
      }
    });
    return newCrewNameList;
  }

  initializeScopeEdit(): void {
    const assignments = [];

    // find the assets using the assignments
    this.synchedSelectedItems.forEach((assignment) => {
      assignments.push(assignment);
    });

    this.editItems = assignments.map((assignment, index) => ({ assignment, index: index + 1 }));
    this.showEditScope = true;
    this.allowCreateNewSegment = false;
    this.resetCreateScope();
  }

  closeScopeEdit(): void {
    this.showEditScope = false;
  }

  saveScopeEdit(): void {
    this.synchedSelectedItems = this.editItems.map((item) => item.assignment);
    this.closeScopeEdit();
  }

  addScope(): void {
    this.editItems.push(
      {
        index: this.editItems.length + 1,
        assignment: this.dropdownItems[0],
      },
    );
  }

  createScope(): void {
    this.resetCreateScope();
    this.allowCreateNewSegment = true;
  }

  async createScopeConfirm(): Promise<void> {
    // if the user inputs a US and/or DS manhole that does not exist,
    // set US and/or DS manhole to new manhole to be created in DWI
    if (typeof this.usManhole === 'string') {
      const usName = this.usManhole;
      this.usManhole = {
        guid: uuid.v4(),
        name: usName,
      };
    }

    if (typeof this.dsManhole === 'string') {
      const dsName = this.dsManhole;
      this.dsManhole = {
        guid: uuid.v4(),
        name: dsName,
      };
    }

    await this.postCreateNewLineSegmentAccessPoint(
      {
        USMH: this.usManhole,
        DSMH: this.dsManhole,
        taskType: this.editItems[0].assignment.taskTypeGuid,
        projectGuid: this.editItems[0].assignment.projectGuid,
      },
    );

    if (this.newLineSegmentAccessPointError) {
      this.showSnackBar({
        message: 'Unable to Create New Segment',
        color: '#e61e25',
        showSnackBar: true,
      });
      return;
    }

    this.editItems.push(
      {
        index: this.editItems.length + 1,
        assignment: this.newLineSegmentAccessPoint,
      },
    );

    this.projectWorkOrders.push(this.newLineSegmentAccessPoint);
    this.synchedSelectedItems.push(this.newLineSegmentAccessPoint);

    this.showSnackBar({
      message: `Created New Segment ${this.newLineSegmentAccessPoint.nodes.name}`,
      color: 'green',
      showSnackBar: true,
    });

    this.resetCreateScope();
    this.allowCreateNewSegment = false;
  }

  resetCreateScope(): void {
    this.usManhole = null;
    this.dsManhole = null;
  }

  removeScope(item: AssignmentShort): void {
    const index = this.editItems.indexOf(item);
    if (index > -1) {
      this.editItems.splice(index, 1);
    }
  }

  sortWorkOrderByAsset(a: AssignmentShort, b: AssignmentShort): number {
    if (a.nodes.name < b.nodes.name) return -1;
    if (a.nodes.name > b.nodes.name) return 1;
    return 0;
  }

  /**
   * @description Sort synchedSelectedItems by a list of node names
   * @param {string[]} sortedOrder - List of node names in the intended order
   */
  sortSynchedSelectedItems(sortedOrder: string[]) {
    if (this.hasSorted) {
      return;
    }
    this.hasSorted = true;

    // Don't bother sorting if a segment is missing an index
    if (!sortedOrder.every((x) => x !== null)) {
      return;
    }

    this.synchedSelectedItems
      .sort((a, b) => sortedOrder.findIndex((val) => val === a.nodes.name)
        - sortedOrder.findIndex((val) => val === b.nodes.name));
  }
}
