






























































































































































































































import { BasicSelect } from 'vue-search-select';
import { AdminActions } from '@/store/admin/actions';
import { User } from '@/store/admin/types';
import { ProjectActions } from '@/store/project/actions';
import { ProjectListing } from '@/store/project/types';
import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { DetailedUserData } from '@/store/users/types';

const adminModule = namespace('admin');
const projectModule = namespace('project');

@Component({
  components: {
    BasicSelect,
  },
})
export default class CreateUser extends Vue {
  @adminModule.Action(AdminActions.POST_NEW_USER) postUserData;

  @adminModule.State('loadError') postUserError;

  @adminModule.State('loading') loadingPostUsers;

  @adminModule.State('response') userpostResponse;

  @projectModule.Action(ProjectActions.FETCH_ALL_PROJECTS_DATA)
  fetchAllProjectsData;

  @projectModule.State('allProjectsData') allProjects:
    | ProjectListing[]
    | undefined;

  @projectModule.State('loadError') loadError;

  @projectModule.State('loading') loading;

  @Prop() readonly allUsers: DetailedUserData[];

  projects: Array<{ value: number; text: string; guid: string }> = [];

  snack = false;

  snackBarMessage = '';

  snackColor = 'black';

  startDateModel = false;

  endDateModel = false;

  addRole = false;

  // Variables for form for user
  userGuid = null as string;

  firstName = '' as string;

  lastName = '' as string;

  fieldName = '' as string;

  email = '' as string;

  lastProjectGuid = null as string;

  nasscoId = '' as string;

  // Variables for form for user roles

  userRoleGuid = null as string;

  roleGuid = '45696b00-e107-11ec-a869-2b45f636ca36' as string;

  managerGuid = null as string;

  startDate = null as string;

  endDate = null as string;

  selectedProject = { value: 0, text: '', guid: '' } as {
    value: number;
    text: string;
    guid: string;
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, arrow-parens
  requiredField = [(v) => !!v || v === 0 || 'This is a required field.'];

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  emailRules = [
    (v: string): string | boolean => !v
      || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v)
      || 'E-mail must be valid',
  ];

  valid = false as boolean;

  addUserDialog = false as boolean;

  @Watch('allProjects')
  allProjectsChange(): void {
    this.projects = [];
    if (this.allProjects != null && this.allProjects.length > 0) {
      this.allProjects.forEach((proj, i) => {
        this.projects.push({
          value: i,
          text: proj.name,
          guid: proj.guid,
        });
      });
      // eslint-disable-next-line prefer-destructuring
      this.selectedProject = this.projects[0];
    }

    this.$forceUpdate();
  }

  @Watch('userpostResponse')
  userPostResponseChange(): void {
    if (this.userpostResponse == null) {
      return;
    }
    this.snackBarMessage = this.userpostResponse.data;
    this.snackColor = 'green';
    this.snack = true;
  }

  @Watch('postUserError')
  userPostErrorChange(): void {
    if (this.postUserError == null) {
      return;
    }
    this.snackBarMessage = 'An error occured posting that User';
    this.snackColor = '#e61e25';
    this.snack = true;
  }

  async mounted(): Promise<void> {
    this.fetchAllProjectsData().catch(() => {
      this.$router.push({
        name: 'Error',
        params: { catchAll: 'Error', message: 'There was an error retrieving your projects. Please try again later. If this issue persists, please contact support.' },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
      }).catch((e) => {});
    });
  }

  async submit(): Promise<void> {
    const useridentity = this.$auth.user.id;
    const currentUser = this.allUsers.find((u) => u.useridentity === `auth0|${useridentity}`);
    this.managerGuid = currentUser == null ? '' : currentUser.guid;
    let user = this.createUser();
    try {
      user = await this.$auth.createUser(user);
      await this.postUserData(user);
    } catch (ex) {
      this.snackBarMessage = (ex as Error).message;
      this.snackColor = '#e61e25';
      this.snack = true;
      return;
    }
    await this.$auth.sendPasswordReset(this.email);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    await this.resetForm();

    this.addUserDialog = false;
    this.$emit('onAddUser');
  }

  async resetForm(): Promise<void> {
    this.roleGuid = '45696b00-e107-11ec-a869-2b45f636ca36';

    this.userGuid = '';
    this.email = '';
    this.firstName = '';
    this.lastName = '';
    this.fieldName = '';
    this.email = '';
    this.lastProjectGuid = '';
    this.nasscoId = '';
    this.userRoleGuid = '';
    this.managerGuid = '';
    this.startDate = '';
    this.endDate = '';
    await (this.$refs.userCreateForm as HTMLFormElement).resetValidation();
  }

  getRoleName(role: string): string {
    switch (role) {
      case '45696b00-e107-11ec-a869-2b45f636ca36':
        return 'Customer';
      default:
        return '';
    }
  }

  createUser(): User {
    const user = {} as User;
    user.Guid = this.userGuid;
    user.Nickname = this.email;
    user.Firstname = this.firstName;
    user.Lastname = this.lastName;
    user.Fieldname = this.fieldName;
    user.Email = this.email;
    user.LastProjectGuid = this.lastProjectGuid;
    user.Nasscoid = this.nasscoId;
    if (this.addRole) {
      user.UserRoleGuid = this.userRoleGuid;
      user.Role = this.roleGuid;
      user.ManagerUserGuid = this.managerGuid;
      user.StartDate = new Date(this.startDate);
      user.EndDate = new Date(this.endDate);
      user.ProjectGuid = this.selectedProject.guid;
    } else {
      user.UserRoleGuid = '';
      user.Role = '';
      user.ManagerUserGuid = '';
      user.StartDate = null;
      user.EndDate = null;
      user.ProjectGuid = '';
    }
    return user;
  }
}
