



























import {
  Component, Prop,
} from 'vue-property-decorator';
import {
  CrewCountsWithAverages,
  CrewDataDeliveryProjectMetrics,
  FetchCrewCountsPayload,
  ProjectCrewPlatform,
} from '@/store/metrics/types';
import CrewCountsAverageCard from '@/components/Dashboards/PmDashboardComponents/ProjectTotals/CrewCounts/CrewCountsAverageCard.vue';
import DataDeliveryChartCompact from '@/components/Dashboards/PmDashboardComponents/ProjectTotals/CrewCounts/DataDeliveryChartCompact.vue';
import CrewBurndown from '@/components/Dashboards/PmDashboardComponents/ProjectTotals/CrewCounts/CrewBurndown.vue';
import CrewDashboardOverview from './CrewDashboardOverview.vue';
import CrewDashboardMixin from './CrewDashboardMixin.vue';

@Component({
  components: {
    CrewDashboardOverview,
    CrewBurndown,
    CrewCountsAverageCard,
    DataDeliveryChartCompact,
  },
})
export default class CrewDashboardCard extends CrewDashboardMixin {
  @Prop() readonly projectGuid: string;

  @Prop() readonly selectedcrewName: string;

  @Prop() readonly selectedPlatformGuid: string;

  @Prop() readonly selectedPlatformName: string;

  @Prop() readonly crewCountAverages: CrewCountsWithAverages;

  projectedFinish = '';

  get selectedPlatformCrew(): ProjectCrewPlatform | undefined {
    if (
      !this.selectedcrewName
      || !this.selectedPlatformGuid
      || !this.platformCrewCounts
    ) {
      return;
    }
    // eslint-disable-next-line consistent-return
    return this.platformCrewCounts.find(
      (plat) => plat.crewName === this.selectedcrewName
        && plat.platformGuid === this.selectedPlatformGuid,
    );
  }

  async refreshCrewAverageCard(
    platformName: string,
    crewName: string,
    startDate: string,
    endDate: string,
  ): Promise<void> {
    const dto = this.crewCountsWithAverages.find((value) => value.crewName === crewName);

    const crewCountsPayload: FetchCrewCountsPayload = {
      projectGuid: this.projectGuid,
      crewName,
      platformName,
      startDate,
      endDate,
    };
    await this.fetchSingleCrewCountAverage(crewCountsPayload);
    if (this.updateCrewCountAverageDTO == null) {
      return;
    }
    if (dto == null) {
      if (this.crewCountsWithAverages == null) {
        this.setCrewCountAveragesData([]);
      }
      const newCrewCountAveragesDTOList = [...this.crewCountsWithAverages];
      newCrewCountAveragesDTOList.push({ ...this.updateCrewCountAverageDTO });
      this.setCrewCountAveragesData(newCrewCountAveragesDTOList);
      return;
    }
    const newCrewCountAveragesDTOList = [...this.crewCountsWithAverages];
    const indexToReplace = newCrewCountAveragesDTOList.findIndex(
      (value) => value.jobName === platformName,
    );
    newCrewCountAveragesDTOList[indexToReplace] = { ...this.updateCrewCountAverageDTO };
    this.setCrewCountAveragesData(newCrewCountAveragesDTOList);
  }

  getDataDeliveryProjectMetrics(crewName: string): CrewDataDeliveryProjectMetrics {
    return this.crewDataDeliveryProjectMetrics?.find((value) => value.crewName === crewName);
  }
}
