/* eslint-disable max-len */
import { ActionTree } from 'vuex';
import { parseJwt } from '@/auth/authUtil';
import { RootState } from '../types';
import { UserPermission, UserPermissionsState } from './types';
import { UserPermissionsMutations } from './mutations';

// eslint-disable-next-line no-shadow
export enum UserPermissionsActions {
  FETCH_USER_PERMISSIONS = 'FETCH_USER_PERMISSIONS',
}

export const actions: ActionTree<UserPermissionsState, RootState> = {
  /**
     * @description Sets the user permissions in the store to the permissions in the jwt
     * @param param0 Vuex param
     * @param jwtToken The jwt from auth0 (Do not put 'bearer' in front of the token)
     */
  [UserPermissionsActions.FETCH_USER_PERMISSIONS]({ commit }, jwtToken: string): void {
    commit(UserPermissionsMutations.SET_PERMISSIONS, []);
    commit(UserPermissionsMutations.SET_LOADING, true);
    commit(UserPermissionsMutations.SET_ERROR, undefined);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const enumValues = <any>Object.values(UserPermission);

    const permissions: UserPermission[] = [];

    try {
      if (jwtToken != null && jwtToken !== '') {
        const parsedJWT = parseJwt(jwtToken);
        if (parsedJWT != null && 'permissions' in parsedJWT) {
          (parsedJWT.permissions as string[]).forEach((p: string) => {
            if (enumValues.includes(p)) {
              permissions.push(p as UserPermission);
            }
          });
        }
      }
      commit(UserPermissionsMutations.SET_PERMISSIONS, [...permissions]);
    } catch (e) {
      commit(UserPermissionsMutations.SET_ERROR, e);
      throw e;
    } finally {
      commit(UserPermissionsMutations.SET_LOADING, false);
    }
  },
};
