




































































































































































































































































































































































































































































































































































































































/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  Component, Prop, PropSync, Vue, Watch,
} from 'vue-property-decorator';
import { MatchFilter } from '@/types';
import { namespace } from 'vuex-class';
import { uuid } from 'vue-uuid';
import utils from './utils';
import { DataTableHeader } from '../AssetTable/types';
import getRouteColor from '../Routing/RoutingUtils';
import IntegrityDateSelectTextBox from '../IntegrityDateSelectTextBox/IntegrityDateSelectTextBox.vue';

const userPrefsModule = namespace('userPrefs');

@Component({
  components: {
    IntegrityDateSelectTextBox,
  },
})
export default class IntegrityTable extends Vue {
  @userPrefsModule.State('displayImperial') displayImperial: boolean;

  @Prop({ default: `default-id-${uuid.v4()}` }) readonly tableID: string;

  @PropSync('data') synchedData: unknown[];

  @PropSync('headers') synchedHeaders: DataTableHeader[];

  @Prop() readonly sortBy: string;

  @Prop({ default: false }) readonly showSelect: boolean;

  @Prop({ default: -1 }) readonly selectLimit: number;

  @Prop() readonly loading: boolean;

  @Prop({ default: false }) readonly hideDefaultFooter: boolean;

  @Prop({ default: -1 }) readonly itemsPerPage: number;

  @Prop() readonly footerProps: unknown;

  @Prop({ default: true }) readonly singleExpand: boolean;

  @Prop() readonly showExpand: boolean;

  // Each index is a different css rule
  // For example ['td { background:black; color: white;}', 'td.sticky  { color:red;}']
  @Prop() readonly cssRules: string[];

  @Prop() readonly filterValues: unknown[];

  @Prop() readonly canEdit: boolean;

  @Prop() readonly search: string | undefined;

  @Prop({ default: '' }) readonly tableType: string;

  @Prop({ default: 'guid' }) readonly tableUniqueKey: string;

  @Prop({ default: false }) disableActionEdit

  @Prop({ default: false }) disableActionDelete

  @PropSync('expanded') synchedExpanded: unknown[];

  @PropSync('selectedItems') synchedSelectedItems: unknown[];

  @PropSync('matchFilters') synchedMatchFilters: MatchFilter[];

  @PropSync('height') synchedHeight: number | string | null;

  @PropSync('blankMatchFilters', { default: () => ({}) }) synchedBlankMatchFilters: any;

  localSearch = '';

  refreshFilterKey = 0;

  get editableHeaders(): DataTableHeader[] {
    return this.canEdit ? this.synchedHeaders.filter(
      (header) => header.editable,
    ) : [];
  }

  get editableHeadersOptions(): DataTableHeader[] {
    return this.canEdit ? this.synchedHeaders.filter(
      (header) => header.editable && header.options,
    ) : [];
  }

  get limitReached(): boolean {
    return this.selectLimit !== -1 && this.synchedSelectedItems.length >= this.selectLimit;
  }

  matchFilterMethod = {
    number: ['>', '>=', '<=', '<', '=', '!='],
    string: ['Exactly', 'Includes', 'Does Not Include'],
    date: ['Before', 'After', 'Between'],
    color: [],
  }

  tableHeight = 0;

  updateFilterButtonKey: string = uuid.v4();

  get tableData(): any[] {
    // This is how vue wants you to force updates for computed values
    // eslint-disable-next-line no-unused-expressions
    this.refreshFilterKey;

    if (this.synchedMatchFilters && this.synchedData && this.synchedBlankMatchFilters) {
      this.$emit('table-data-change', this.synchedData.filter(this.filterData));
      return this.synchedData.filter(this.filterData);
    }
    this.$emit('table-data-change', this.synchedData);
    return this.synchedData;
  }

  @Watch('synchedData', { deep: true })
  /**
   * @description Watch for a change of the main data from props and rerender the filter buttons.
   * Very important for long load time tables to update which buttons need to rerender.
   */
  onSynchedDataChange(): void {
    this.forceFilterButtonRerender();
  }

  updateFilterKey(): void {
    this.refreshFilterKey += 1;
  }

  created(): void {
    window.addEventListener('resize', this.onResize);
  }

  mounted(): void {
    this.timeoutStickyColumns();
    if (this.cssRules && this.cssRules.length > 0) {
      const sheet = new CSSStyleSheet();
      this.cssRules.forEach((style) => (sheet).insertRule(`#${this.tableID} ${style}`));
      (document as any).adoptedStyleSheets = [...(document as any).adoptedStyleSheets, sheet];
    }
    this.synchedBlankMatchFilters = {};

    if (!this.synchedMatchFilters) {
      return;
    }

    this.synchedMatchFilters.forEach((matchFilter) => {
      this.synchedBlankMatchFilters[matchFilter.header] = false;
    });
  }

  updated(): void {
    // Time out added so the function can chill out and
    // let the page load fully before setting the sticky columns
    setTimeout(() => {
      this.timeoutStickyColumns();
    }, 10);
  }

  destroyed(): void {
    window.removeEventListener('resize', this.onResize);
  }

  applyFilter(header: string): void {
    if (!this.synchedMatchFilters) {
      return;
    }
    const filter = this.synchedMatchFilters.find((f) => f.header === header);
    filter.value = filter.tempValue;
  }

  clearAllMatchFilters(): void{
    if (!this.synchedMatchFilters) {
      return;
    }
    this.synchedMatchFilters.forEach((filter) => {
      this.clearMatchFilter(filter.header);
    });
  }

  clearMatchFilter(headerValue: string): void {
    if (!this.synchedMatchFilters) {
      return;
    }
    const filter = this.synchedMatchFilters.find((f) => f.header === headerValue);

    filter.value = '';
    filter.method = '';
    filter.tempValue = '';
    filter.options = [];

    this.synchedBlankMatchFilters[headerValue] = false;
  }

  // If needed, convert the items in the array
  // to match what is shown in the table using the conversionFunction
  // Otherwise, return the normal value from item
  getFilterItemValue(filter: MatchFilter, item: any): any {
    return filter.conversionFunction
      ? filter.conversionFunction(item)
      : item[`${filter.header}`];
  }

  filterData(item: unknown): boolean {
    let itemIncluded = true;
    this.synchedMatchFilters.every((filter) => {
      const headerValue = filter.header;
      const isBlankCheckboxSelected = this.synchedBlankMatchFilters[headerValue];
      if (filter.options.length > 0 || isBlankCheckboxSelected) {
        const itemValue = this.getFilterItemValue(filter, item);
        itemIncluded = Array.isArray(itemValue)
          ? filter.options.some((option) => itemValue.includes(option))
          : filter.options.includes(itemValue);
        itemIncluded = itemIncluded
          || (!item[headerValue] && !itemValue && isBlankCheckboxSelected);
      } else if (filter.value !== '') {
        let headerSplit = [];

        if (filter.header.includes('.')) {
          headerSplit = filter.header.split('.');
        }

        if (filter.type === 'string') {
          const filterVal = (filter.value as string).toLowerCase();
          let itemVal = headerSplit.length > 0
            ? headerSplit.reduce((a, v) => a[v], item)
            : this.getFilterItemValue(filter, item);
          if (Array.isArray(itemVal)) {
            itemVal = itemVal.join(', ');
          }
          itemVal = itemVal ? itemVal.toLowerCase() : '';

          switch (filter.method) {
            case 'Exactly':
              itemIncluded = itemVal === filterVal;
              break;
            case 'Includes':
              itemIncluded = itemVal.includes(filterVal);
              break;
            case 'Does Not Include':
              itemIncluded = !itemVal.includes(filterVal);
              break;
            default:
              break;
          }
        } else if (filter.type === 'number') {
          const itemVal = parseInt(item[`${filter.header}`], 10);
          const filterVal = parseInt(filter.value as string, 10);

          switch (filter.method) {
            case '>':
              itemIncluded = itemVal > filterVal;
              break;
            case '>=':
              itemIncluded = itemVal >= filterVal;
              break;
            case '<=':
              itemIncluded = itemVal <= filterVal;
              break;
            case '<':
              itemIncluded = itemVal < filterVal;
              break;
            case '=':
              itemIncluded = itemVal === filterVal;
              break;
            case '!=':
              itemIncluded = itemVal !== filterVal;
              break;
            default:
              break;
          }
        } else if (filter.type === 'date') {
          const itemVal = Date.parse(item[`${filter.header}`]);

          const isBetweenMethod = filter.method === 'Between';
          if (isBetweenMethod !== Array.isArray(filter.value)) {
            // Make sure we don't try to apply between to only 1 value
            // Or before/after to 2 values

            // eslint-disable-next-line no-param-reassign
            filter.value = '';
            return true;
          }

          let filterVal;
          if (isBetweenMethod) {
            filterVal = (filter.value as string[]).map((x) => Date.parse(x)).sort();
          } else {
            filterVal = Date.parse(filter.value as string);
          }
          switch (filter.method) {
            case 'Before':
              itemIncluded = itemVal < filterVal;
              break;
            case 'After':
              itemIncluded = itemVal > filterVal;
              break;
            case 'Between':
              itemIncluded = itemVal > filterVal[0] && itemVal < filterVal[1];
              break;
            default:
              break;
          }
        }
      }
      return itemIncluded;
    });

    return itemIncluded;
  }

  getDisplayDistanceFtM(distance: number): number {
    return utils.getDisplayDistanceFtM(this.displayImperial, distance);
  }

  getFilterIcon(header: string): string {
    if (!this.synchedMatchFilters) {
      return '';
    }
    const filter = this.synchedMatchFilters.find((f) => f.header === header);

    return filter !== undefined && (filter.value !== '' || filter.options.length > 0 || this.synchedBlankMatchFilters[header])
      ? 'mdi-filter'
      : 'mdi-filter-outline';
  }

  getFormattedDate(date: Date): string {
    let dateString = '';

    if (date) {
      dateString = new Date(date).toISOString().substring(0, 10);
    }

    return dateString;
  }

  processDate(dateString: string): string {
    return !dateString ? null : dateString.substring(0, 10);
  }

  getMatchFilterMethod(headerValue: string): string[] {
    const filter = this.synchedMatchFilters.find((f) => f.header === headerValue);
    switch (filter.type) {
      case 'number':
        return this.matchFilterMethod.number;
      case 'color':
        return this.matchFilterMethod.color;
      case 'date':
        return this.matchFilterMethod.date;
      case 'string':
      default:
        return this.matchFilterMethod.string;
    }
  }

  setTableHeight(): void {
    this.tableHeight = 0;
    setTimeout(() => {
      const tableLayout = document.getElementById('integrity-table-layout');
      this.tableHeight = tableLayout ? tableLayout.offsetHeight : 0;
    }, 1);
  }

  isUpdated(headerValue: string): boolean {
    const filter = this.synchedMatchFilters.find((f) => f.header === headerValue);

    return filter !== null
      ? filter.value !== filter.tempValue
      : false;
  }

  timeoutStickyColumns(): void {
    setTimeout(() => {
      this.setStickyColumns();
    }, 1);
  }

  onResize(): void {
    this.timeoutStickyColumns();
    this.setTableHeight();
  }

  getStickColsTotals(): number {
    return this.getStickyBeginCols() + this.getStickyEndCols();
  }

  getStickyBeginCols(): number {
    return this.synchedHeaders.filter((header) => header.class === 'sticky').length;
  }

  getStickyEndCols(): number {
    return this.synchedHeaders.filter((header) => header.class === 'sticky-end').length;
  }

  setStickyColumns(): void {
    const stickyHeaders = document.querySelectorAll(`#${this.tableID} > div.v-data-table__wrapper > table > thead > tr > th.sticky`);
    const stickyColumns = document.querySelectorAll(`#${this.tableID} > div.v-data-table__wrapper > table > tbody > tr > .sticky, #${this.tableID} > div.v-data-table__wrapper > table > thead > tr > .sticky`);
    const stickyHeadersEnd = document.querySelectorAll(`#${this.tableID} > div.v-data-table__wrapper > table > thead > tr > th.sticky-end`);
    const stickyColumnsEnd = document.querySelectorAll(`#${this.tableID} > div.v-data-table__wrapper > table > tbody > tr > .sticky-end, #${this.tableID} > div.v-data-table__wrapper > table > thead > tr > .sticky-end`);
    let columnOffsets = this.showExpand ? [56] : [0];
    columnOffsets = this.showSelect ? [64] : columnOffsets;
    const columnOffsetsEnd = [0];

    const tableRows = document.querySelectorAll(`#${this.tableID} > div.v-data-table__wrapper > table > tbody > tr, #${this.tableID} > div.v-data-table__wrapper > table > thead > tr`);

    // Make the expand column sticky
    if (tableRows.length > 0 && (this.showExpand || this.showSelect)) {
      Array.from(tableRows).forEach((row, index) => {
        const expandCell = tableRows[index].firstElementChild as HTMLElement;
        expandCell.style.position = 'sticky';
        expandCell.style.left = '0';
        expandCell.style.backgroundColor = expandCell.tagName === 'TD' ? 'inherit' : '#ffffff';
        expandCell.style.borderRight = 'none';
        expandCell.style.setProperty('z-index', '15', 'important');
        if (this.showExpand) {
          expandCell.className = 'expandCell';
        }
      });
    }
    // set sticky columns at start of table
    if (stickyColumns.length > 0) {
      let coIndex = 0;
      Array.from(stickyHeaders).forEach((header) => {
        const offset = Math.round((header as HTMLElement).offsetWidth);
        columnOffsets.push(columnOffsets[coIndex] + offset - 0.4);
        coIndex += 1;
      });

      columnOffsets.pop();

      Array.from(stickyColumns).forEach((_col, index) => {
        const stickyColumnCell = stickyColumns[index] as HTMLElement;
        stickyColumnCell.style.left = `${columnOffsets[index % columnOffsets.length]}px`;

        if (index % stickyHeaders.length === stickyHeaders.length - 1) {
          stickyColumnCell.style.borderRight = '2px solid #d9d9d9';
        }
      });
    }

    // set sticky columns at end of table
    if (stickyColumnsEnd.length > 0) {
      let coIndexEnd = 0;
      Array.from(stickyHeadersEnd).reverse().forEach((header) => {
        const offset = (header as HTMLElement).offsetWidth;
        columnOffsetsEnd.push(columnOffsetsEnd[coIndexEnd] + offset - 0.4);
        coIndexEnd += 1;
      });

      columnOffsetsEnd.pop();
      columnOffsetsEnd.reverse();

      Array.from(stickyColumnsEnd).forEach((_col, index) => {
        const stickyColumnCell = stickyColumnsEnd[index] as HTMLElement;

        stickyColumnCell.style.right = `${columnOffsetsEnd[index % columnOffsetsEnd.length]}px`;

        if (index % stickyHeadersEnd.length === 0) {
          stickyColumnCell.style.borderLeft = '1px solid #d9d9d9';
        }
      });
    }
  }

  onClickRow(item: any): void{
    this.$emit('clickRow', item);
  }

  formatFilterValue(item: any, header: string): string {
    let retVal = '';

    if (item !== null && item !== undefined) {
      if (header.includes('date')) {
        retVal = this.getFormattedDate(new Date(Date.parse(item)));
      } else if ((header === 'score' || header === 'grade') && item === -1) {
        retVal = 'Unscored';
      } else {
        retVal = item.toString();
      }
    }

    return retVal;
  }

  getRouteColor(color: string): string | undefined {
    return getRouteColor(color);
  }

  currentMatchFilter(header: DataTableHeader): MatchFilter {
    return this.synchedMatchFilters.find((f) => f.header === header.value);
  }

  updateInlineValue(item: any, header: any, value: any): void {
    // eslint-disable-next-line no-param-reassign
    item[header.value] = value;
    this.$emit('inlineEdit', item);
  }

  getUnitLabel(): string {
    return this.displayImperial ? 'ft' : 'm';
  }

  /**
   * @description force the filter buttons to rerender by setting a new key
   */
  forceFilterButtonRerender(): void {
    this.updateFilterButtonKey = uuid.v4();
  }

  /**
   * @description Returns if a header value has any filter values
   */
  hasFilterValues(headerValue: string): boolean {
    if (this.filterValues != null) {
      return this.filterValues[headerValue]?.length > 0;
    }
    return false;
  }

  /**
   * @description Closes the v-menu via clicking the button.
   * Pass in a ref list and it will close the first element.
   */
  closeMenu(headerRef: any[]): void {
    // eslint-disable-next-line no-param-reassign
    headerRef[0].save();
  }
}
