import { Module } from 'vuex';
import { RootState } from '@/store/types';
import getters from '@/store/planning/getters';
import { actions } from '@/store/planning/actions';
import { mutations } from './mutations';
import { PlanningState } from './types';

const state : PlanningState = {
  loading: false,
  loadError: undefined,
  deleteLoading: false,
  data: undefined,
  patchLoading: false,
  patchError: undefined,
  patchData: undefined,
  workOrderLoading: false,
  workOrderData: undefined,
  workOrderError: undefined,
  patchWorkOrderLoading: false,
  patchWorkOrderData: undefined,
  patchWorkOrderError: undefined,
};

const planning: Module<PlanningState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default planning;
