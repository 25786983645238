

































































































































































import { DeploymentData, EditFieldObject } from '@/store/deployments/types';
import {
  Component, Prop, PropSync, Watch,
} from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { ProjectData } from '@/store/project/types';
import DeploymentsMixin from './DeploymentsMixin.vue';

const projectModule = namespace('project');

@Component({})
/* eslint-disable object-curly-newline */
/* eslint-disable no-param-reassign */
export default class EditDeploymentForm extends DeploymentsMixin {
  @Prop() readonly deployment: DeploymentData;

  @PropSync('editFields') syncedEditFields: EditFieldObject[];

  @Prop({ default: false }) loading: boolean;

  @PropSync('isOpen') showDialog: boolean;

  @projectModule.State('data') project: ProjectData | undefined;

  originalDeployment: DeploymentData = {
    deploymentGuid: '',
    name: '',
    assetType: '',
    usmh: '',
    dsmh: '',
    entryMH: '',
    date: '',
    dueDate: '',
    direction: '',
    expectedLength: '',
    distance: '',
    turnaroundReason: '',
    deploymentNumber: '',
    additionalInfo: '',
    operator: '',
    robotName: '',
    robotNumber: '',
    nodeGuid: '',
    codingStatus: '',
    mediaUploaded: '',
    isActive: '',
    feedback: '',
    platform: '',
    platformDesc: '',
    result: '',
    deliveryStatus: '',
    releaseStatus: '',
  };

  deploymentUpdate: DeploymentData = {
    deploymentGuid: '',
    name: '',
    assetType: '',
    usmh: '',
    dsmh: '',
    entryMH: '',
    date: '',
    dueDate: '',
    direction: '',
    expectedLength: '',
    distance: '',
    turnaroundReason: '',
    deploymentNumber: '',
    additionalInfo: '',
    operator: '',
    robotName: '',
    robotNumber: '',
    nodeGuid: '',
    codingStatus: '',
    mediaUploaded: '',
    isActive: '',
    feedback: '',
    platform: '',
    platformDesc: '',
    result: '',
    deliveryStatus: '',
    releaseStatus: '',
  };

  showSnackbar = false;

  snackbarColor = '';

  snackbarMessage = '';

  showInspDatePicker = false;

  doNotCode = false;

  showWarning = false;

  showWarningNewAsset = false;

  newField: EditFieldObject;

  newMhOptions: string[] = [];

  newLsOptions: string[] = [];

  @Watch('loading')
  onLoadingChange(): void {
    this.onDeploymentChange();
    this.$forceUpdate();
  }

  @Watch('deployment')
  onDeploymentChange(): void {
    if (this.deployment) {
      this.deploymentUpdate = { ...this.deployment };
      this.originalDeployment = { ...this.deployment };
      this.doNotCode = this.deploymentUpdate.codingStatus === 'No';
      [this.deploymentUpdate.date] = this.deploymentUpdate.date.split('T');
    }
  }

  @Watch('deploymentUpdate.name', { deep: true })
  onDeploymentUpdateNameChange(val: string): void {
    if (this.project) {
      const asset = this.project.assets.find((a) => a.name === val);
      if (asset) {
        this.deploymentUpdate.nodeGuid = asset.guid;
        if (this.deploymentUpdate.assetType === 'Line Segment') {
          this.deploymentUpdate.usmh = asset.upstream;
          this.deploymentUpdate.dsmh = asset.downstream;
          this.syncedEditFields.find((f) => f.desc === 'entryMH').options = [asset.upstream, asset.downstream];
          this.deploymentUpdate.entryMH = this.deploymentUpdate.direction === 'Downstream' ? asset.upstream : asset.downstream;
        }
      }
    }
  }

  /**
   * @description Warns user that they are trying to creat a new Manhole
   * @param {EditFieldObject} field - Current forum field
   * @param {string} event - Current value in field
   */
  onMhUpdate(field: EditFieldObject, event: string): void {
    // do not update on fields that aren't usmh or dsmh
    if (field.desc.includes('mh')) {
      if (!field.options.includes(event) && !this.newMhOptions.includes(event)) {
        this.newField = field;
        this.showWarningNewAsset = true;
      }
    }
  }

  /**
   * @description Backup check, since input event doesn't always catch enter
   * @param {EditFieldObject} field - Current forum field
   * @param {KeyboardEvent} event - Event containing info on key entered
   */
  onKeyDown(field: EditFieldObject, event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      this.onMhUpdate(field, this.deploymentUpdate[field.desc]);
    }
  }

  /**
   * @description Create new manhole and associated line segment if true, resets forum otherwise
   * @param {boolean} accept - True if new asset should be added
   */
  acceptNewAsset(accept: boolean): void {
    if (accept) {
      this.originalDeployment[this.newField.desc] = this.deploymentUpdate[this.newField.desc];
      this.newMhOptions.push(this.deploymentUpdate[this.newField.desc]);

      const newLs = `${this.deploymentUpdate['usmh']}_${this.deploymentUpdate['dsmh']}`;
      this.newLsOptions.push(newLs);
      this.deploymentUpdate.name = newLs;
      this.originalDeployment.name = newLs;
    } else {
      this.deploymentUpdate[this.newField.desc] = this.originalDeployment[this.newField.desc];
    }
    this.showWarningNewAsset = false;
  }

  /**
   * @description Returns options for current combo box,
   *  combining with locally created options when available
   * @param {EditFieldObject} field - Current field
   * @returns Combobox options
   */
  getComboboxItems(field: EditFieldObject): string[] {
    if (field.desc.includes('mh')) {
      return [...field.options, ...this.newMhOptions];
    }
    if (field.desc === 'name') {
      return [...field.options, ...this.newLsOptions];
    }
    return [...field.options];
  }

  async saveEdit(): Promise<void> {
    try {
      this.deploymentUpdate.codingStatus = this.doNotCode ? 'No' : 'Yes';
      this.deploymentUpdate.isActive = this.deploymentUpdate.isActive === 'Yes';
      if (this.deploymentUpdate.assetType === 'Manhole') {
        this.deploymentUpdate.macp = this.deploymentUpdate.macp === 'Yes';
      } else {
        this.deploymentUpdate.macp = null;
      }
      await this.patchDeploymentData([this.deploymentUpdate]);
    } catch (e) {
      this.snackbarColor = 'error';
      this.snackbarMessage = 'An error occurred while updating the deployment';
      this.showSnackbar = true;
      return;
    }

    this.snackbarColor = 'success';
    this.snackbarMessage = 'Deployment Updated';
    this.showDialog = false;
    this.showSnackbar = true;
    this.$emit('updateDeploymentData', this.deploymentUpdate);
  }

  cancelEdit(): void {
    this.showWarning = false;
    this.showDialog = false;
  }
}
