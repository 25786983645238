/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionTree } from 'vuex';
import { DeploymentPostObject } from '@/components/DeploymentCodingForm/types';
import { RootState } from '../types';
import { DeploymentsMutations } from './mutations';
import { DeploymentData, DeploymentsState } from './types';
import storeUtility from '../utils';

// eslint-disable-next-line no-shadow
export enum DeploymentsActions {
  FETCH_DEPLOYMENTS_DATA = 'FETCH_DEPLOYMENTS_DATA',
  FETCH_DEPLOYMENT_COUNTS = 'FETCH_DEPLOYMENT_COUNTS',
  PATCH_DEPLOYMENT_DATA = 'PATCH_DEPLOYMENT_DATA',
  POST_DEPLOYMENT_DATA = 'POST_DEPLOYMENT_DATA',
  EXPORT_DEPLOYMENT_CSV = 'EXPORT_DEPLOYMENT_CSV',
  FETCH_DEPLOYMENT_DATA_STANDARDS = 'FETCH_DEPLOYMENT_DATA_STANDARDS',
  FETCH_BIG_PIPE_DEPLOYMENT_DATA = 'FETCH_BIG_PIPE_DEPLOYMENT_DATA',
  PATCH_BIG_PIPE_DEPLOYMENT = 'PATCH_BIG_PIPE_DEPLOYMENT',
  FETCH_DEPLOYMENT_NUMBER = 'FETCH_DEPLOYMENT_NUMBER'
}

export const actions: ActionTree<DeploymentsState, RootState> = {
  async [DeploymentsActions.FETCH_DEPLOYMENTS_DATA]({ commit }, guid: string[]): Promise<void> {
    commit(DeploymentsMutations.SET_LOADING_STATE, true);

    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.getDeploymentData(guid);

      commit(DeploymentsMutations.SET_DEPLOYMENTS_DATA, response.data);
    } catch (e) {
      commit(DeploymentsMutations.SET_ERROR_STATE, (e as Error).message);
      throw Error('API Error occurred.');
    } finally {
      commit(DeploymentsMutations.SET_LOADING_STATE, false);
    }
  },

  async [DeploymentsActions.FETCH_DEPLOYMENT_COUNTS](
    { commit }, projectGuids: string[],
  ): Promise<void> {
    commit(DeploymentsMutations.SET_COUNTS_LOADING_STATE, true);

    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.getDeploymentCounts(projectGuids);

      commit(DeploymentsMutations.SET_DEPLOYMENT_COUNTS, response.data);
    } catch (e) {
      commit(DeploymentsMutations.SET_ERROR_STATE, (e as Error).message);
      throw Error('API Error occurred.');
    } finally {
      commit(DeploymentsMutations.SET_COUNTS_LOADING_STATE, false);
    }
  },

  async [DeploymentsActions.PATCH_DEPLOYMENT_DATA](
    { commit }, deployment: DeploymentData[],
  ): Promise<void> {
    commit(DeploymentsMutations.SET_PATCH_LOADING_STATE, true);

    try {
      const api = await storeUtility.useIntegrityAPI();
      await api.patchDeployment(deployment);
    } catch (e) {
      commit(DeploymentsMutations.SET_ERROR_STATE, (e as Error).message);
      throw Error('API Error occurred.');
    } finally {
      commit(DeploymentsMutations.SET_PATCH_LOADING_STATE, false);
    }
  },

  async [DeploymentsActions.POST_DEPLOYMENT_DATA](
    { commit }, deployment: DeploymentPostObject,
  ): Promise<void> {
    commit(DeploymentsMutations.SET_LOADING_STATE, true);

    try {
      const api = await storeUtility.useIntegrityAPI();
      const res = await api.postDeployment(deployment);
      return res;
    } catch (e) {
      commit(DeploymentsMutations.SET_ERROR_STATE, (e as Error).message);
      throw Error('API Error occurred.');
    } finally {
      commit(DeploymentsMutations.SET_LOADING_STATE, false);
    }
  },

  async [DeploymentsActions.EXPORT_DEPLOYMENT_CSV]({ commit }, exportObject: any): Promise<void> {
    commit(DeploymentsMutations.SET_LOADING_STATE, true);

    try {
      const api = await storeUtility.useIntegrityAPI();
      await api.exportDeploymentCsv(exportObject);
    } catch (e) {
      commit(DeploymentsMutations.SET_ERROR_STATE, (e as Error).message);
      throw Error('API Error occurred.');
    } finally {
      commit(DeploymentsMutations.SET_LOADING_STATE, false);
    }
  },

  async [DeploymentsActions.FETCH_DEPLOYMENT_DATA_STANDARDS]({ commit }):
  Promise<void> {
    commit(DeploymentsMutations.SET_LOADING_STATE, true);
    commit(DeploymentsMutations.SET_ERROR_STATE, undefined);
    try {
      const deploymentDataStandardGuid = '86a948d2-3fe6-454f-a0ff-92d4ca902ed6';
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.getDataStandard(deploymentDataStandardGuid);
      commit(DeploymentsMutations.SET_DEPLOYMENT_STANDARDS, response.data);
    } catch (e) {
      commit(DeploymentsMutations.SET_ERROR_STATE, (e as any).data.Message);
      throw (e as any).data.Message;
    } finally {
      commit(DeploymentsMutations.SET_LOADING_STATE, false);
    }
  },

  async [DeploymentsActions.FETCH_BIG_PIPE_DEPLOYMENT_DATA]({ commit }, deploymentGuid: string):
  Promise<void> {
    commit(DeploymentsMutations.SET_BIG_PIPE_DEPLOYMENT_DATA_LOADING, true);
    commit(DeploymentsMutations.SET_BIG_PIPE_DEPLOYMENT_DATA, undefined);
    commit(DeploymentsMutations.SET_BIG_PIPE_DEPLOYMENT_DATA_ERROR, undefined);
    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.getBigPipeDeploymentData(deploymentGuid);
      commit(DeploymentsMutations.SET_BIG_PIPE_DEPLOYMENT_DATA, JSON.parse(response.data.data));
    } catch (e) {
      commit(DeploymentsMutations.SET_BIG_PIPE_DEPLOYMENT_DATA_ERROR, (e as any).data.Message);
      throw (e as any).data.Message;
    } finally {
      commit(DeploymentsMutations.SET_BIG_PIPE_DEPLOYMENT_DATA_LOADING, false);
    }
  },

  async [DeploymentsActions.PATCH_BIG_PIPE_DEPLOYMENT]({ commit }, payload: {
    deploymentGuid: string,
    deploymentData: any
  }):
  Promise<void> {
    commit(DeploymentsMutations.SET_BIG_PIPE_DEPLOYMENT_PATCH_LOADING, true);
    commit(DeploymentsMutations.SET_BIG_PIPE_DEPLOYMENT_PATCH_ERROR, undefined);
    try {
      const api = await storeUtility.useIntegrityAPI();
      await api.patchBigPipeDeployment(payload);
    } catch (e) {
      commit(DeploymentsMutations.SET_BIG_PIPE_DEPLOYMENT_PATCH_ERROR, (e as any).data.Message);
      throw (e as any).data.Message;
    } finally {
      commit(DeploymentsMutations.SET_BIG_PIPE_DEPLOYMENT_PATCH_LOADING, false);
    }
  },

  async [DeploymentsActions.FETCH_DEPLOYMENT_NUMBER]({ commit }, workOrderGuid: string):
  Promise<void> {
    commit(DeploymentsMutations.SET_DEPLOYMENT_NUMBER_LOADING, true);
    commit(DeploymentsMutations.SET_DEPLOYMENT_NUMBER, undefined);
    commit(DeploymentsMutations.SET_DEPLOYMENT_NUMBER_ERROR, undefined);
    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.getDeploymentNumber(workOrderGuid);
      commit(DeploymentsMutations.SET_DEPLOYMENT_NUMBER, response.data.data);
    } catch (e) {
      commit(DeploymentsMutations.SET_DEPLOYMENT_NUMBER_ERROR, (e as any).data.Message);
      throw (e as any).data.Message;
    } finally {
      commit(DeploymentsMutations.SET_DEPLOYMENT_NUMBER_LOADING, false);
    }
  },
};
