















































































































































/* eslint-disable @typescript-eslint/no-explicit-any */
import { BasicSelect } from 'vue-search-select';
import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { AdminActions } from '@/store/admin/actions';
import { UserRole } from '@/store/users/types';
import { ProjectListing } from '@/store/project/types';
import IntegrityTable from '@/components/IntegrityTable/IntegrityTable.vue';
import IntegrityDelete from '@/components/IntegrityDelete/IntegrityDelete.vue';

const adminModule = namespace('admin');

@Component({
  components: {
    BasicSelect,
    IntegrityTable,
    IntegrityDelete,
  },
})
export default class ProjectList extends Vue {
  @Prop() readonly user!: any;

  @Prop() readonly projects!: ProjectListing[] | undefined;

  @Prop() readonly hasPermissionAdminDeleteUser!: boolean;

  @Prop() readonly hasPermissionAdminSetDateRangeForActivation!: boolean;

  @Prop() readonly search!: string;

  @adminModule.Action(AdminActions.DELETE_USER_ROLE) deleteUserRoleAction;

  @adminModule.Action(AdminActions.PATCH_USER_ROLE) patchUserRoleAction;

  @adminModule.State('loading') loading;

  projectsCopy = this.projects == null ? [] as ProjectListing[] : this.projects;

  editDialogVisible = false;

  startDateMenu = false as boolean;

  endDateMenu = false as boolean;

  startDate = null as string | undefined;

  endDate = null as string;

  userRoleToEdit = {} as UserRole;

  userRoleToDelete = {} as UserRole;

  snackbarMessage = '';

  snackbarColor = '';

  snackbarVisible = false;

  allHeaders = [
    {
      text: 'Project Name', value: 'projectName', sortable: false, filterable: true,
    },
    {
      text: 'Project Guid', value: 'projectGuid', sortable: false, filterable: true,
    },
    {
      text: 'Start Date', value: 'scheduledStartTime', sortable: false, filterable: true,
    },
    {
      text: 'End Date', value: 'scheduledEndTime', sortable: false, filterable: true,
    },
    {
      text: 'Actions',
      value: 'actions',
      align: 'center',
      sortable: false,
      class: 'sticky-end',
      cellClass: 'sticky-end',
    },
  ]

  @Watch('projects')
  onProjectChange(): void{
    if (this.projects != null) {
      this.projectsCopy = this.projects;
    }
  }

  get filteredRoles(): any[] {
    return this.user?.detailedUserData?.role != null
      ? this.user.detailedUserData.role.map((value) => (
        {
          ...value,
          projectName: this.getProjectName(value.projectGuid),
          scheduledStartTime: this.getDateString(value.startDate),
          scheduledEndTime: this.getDateString(value.endDate),
        }
      ))
      : [];
  }

  getProjectName(guidToMatch: string): string {
    // eslint-disable-next-line no-nested-ternary
    return this.projectsCopy.length > 0
      ? (this.projectsCopy.find((value) => value.guid === guidToMatch) != null
        ? this.projectsCopy.find((value) => value.guid === guidToMatch).name
        : '')
      : '';
  }

  get headers(): any[] {
    let list = this.allHeaders;
    if (!this.hasPermissionAdminDeleteUser) {
      list = list.filter((item) => item.value !== 'delete');
    }
    if (!this.hasPermissionAdminSetDateRangeForActivation) {
      list = list.filter((item) => item.value !== 'edit');
    }
    return list;
  }

  editDialog(userRoleToEdit: UserRole): void{
    this.startDate = this.getDateString(userRoleToEdit.startDate);
    this.endDate = this.getDateString(userRoleToEdit.endDate);
    this.userRoleToEdit = userRoleToEdit;
    this.editDialogVisible = true;
    this.$forceUpdate();
  }

  async deleteUserRole(userRoleToDelete: UserRole): Promise<boolean> {
    try {
      await this.deleteUserRoleAction(userRoleToDelete.guid);

      this.user.detailedUserData.role.splice(
        this.user.detailedUserData.role.findIndex((value) => value === userRoleToDelete),
        1,
      );
      if (this.user.detailedUserData.role.length <= 0) {
        this.$emit('deleteUser', this.user.detailedUserData.guid);
      }
    } catch (e) {
      return false;
    }
    return true;
  }

  async editUserRole(userRoleToEdit: UserRole): Promise<void> {
    // eslint-disable-next-line no-param-reassign
    userRoleToEdit.startDate = !this.startDate ? null : new Date(this.startDate);
    // eslint-disable-next-line no-param-reassign
    userRoleToEdit.endDate = !this.endDate ? null : new Date(this.endDate);

    try {
      await this.patchUserRoleAction(userRoleToEdit);

      this.snackbarMessage = 'User role edited';
      this.snackbarColor = 'green';
    } catch (e) {
      this.snackbarMessage = (e as Error).message;
      this.snackbarColor = '#e61e25';
    }

    this.snackbarVisible = true;
    this.editDialogVisible = false;
  }

  getDateString(date: Date): string {
    return !date ? null : new Date(date).toISOString().substr(0, 10);
  }
}
