






























import { NODEITEM_LINESEGMENT_GUID, NODEITEM_MANHOLE_GUID } from '@/common/Constants';
import { RoutingActions } from '@/store/routing/actions';
import { SimpleRoutingData } from '@/store/routing/types';
import {
  Component, Prop, Vue,
} from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const routingModule = namespace('routing');

@Component({})
export default class RouteSelect extends Vue {
  @routingModule.Action(RoutingActions.FETCH_ROUTE_ASSETS) fetchRouteAssets;

  @Prop() readonly routingData: SimpleRoutingData[];

  @Prop() readonly projectGuid: string;

  @Prop() readonly displayed: boolean;

  tabItems = [
    {
      name: 'Manhole',
      guid: NODEITEM_MANHOLE_GUID,
    },
    {
      name: 'Line Segment',
      guid: NODEITEM_LINESEGMENT_GUID,
    },
  ];

  tab = 0;

  selectedRoute = null;

  get routes(): Array<SimpleRoutingData> {
    if (this.routingData === undefined) return [];
    return this.routingData.filter(
      (route) => route.categoryItemGuid === this.tabItems[this.tab].guid,
    );
  }

  getRouteDescription(description: string): string {
    if (description.length > 28) {
      return description.substring(0, 25).concat('...');
    }
    return description;
  }

  async getRouteAssets(guid: string): Promise<void> {
    await this.fetchRouteAssets(guid);
  }
}
