












































import {
  Component, Prop, Vue,
} from 'vue-property-decorator';
import ImportNassco from '@/components/ImportNassco/ImportNassco.vue';
import { PROJECT_MANAGER, SUPER_USER } from '@/auth/roles';
import AddScopeBaseVue from '@/components/ScopeAddition/AddScopeBase.vue';
import { namespace } from 'vuex-class';
import { ProjectActions } from '@/store/project/actions';
import UserPermissionsMixin from '@/components/UserPermissions/UserPermissionsMixin.vue';
import { UserPermission } from '@/store/userpermissions/types';
import { options } from './types';
import ImportAgol from '../../components/ImportAgol/ImportAgol.vue';
import ImportMsi from '../../components/ImportMsi/ImportMsi.vue';

const projectModule = namespace('project');

@Component({
  components: {
    ImportNassco,
    ImportAgol,
    ImportMsi,
  },
})
export default class ImportBase extends UserPermissionsMixin {
  @projectModule.Action(ProjectActions.FETCH_HEADER_DATA) fetchHeaderData;

  @Prop() readonly projectGuid: string | undefined;

  isPM = false;

  selectedTypeModel: {component: unknown, name: string, id: string} = {
    // component: ImportNassco,
    // name: 'Import DB',
    // id: 'importNassco',
    component: ImportAgol,
    name: 'Import AGOL Feature Server',
    id: 'importAgol',
  };

  async mounted(): Promise<void> {
    if (!this.hasPermissionImportCommon) {
      this.goToErrorPage();
    }
    this.fetchHeaderData([this.projectGuid]);
    if (this.$auth.user.id != null) {
      const roles = await this.getRoles();
      if (roles.includes(PROJECT_MANAGER) || roles.includes(SUPER_USER)) {
        this.isPM = true;
      }
    }
  }

  async getRoles(): Promise<string[]> {
    return this.$auth.getRoles(`auth0|${this.$auth.user.id}`);
  }

  get options(): options {
    if (this.isPM) {
      return {
        importNassco: {
          component: ImportNassco,
          name: 'Import DB',
          id: 'importNassco',
        },
        importAgol: {
          component: ImportAgol,
          name: 'Import AGOL Feature Server',
          id: 'importAgol',
        },
        importMsi: {
          component: ImportMsi,
          name: 'Import MSI Data',
          id: 'importMsi',
        },
        addScope: {
          component: AddScopeBaseVue,
          name: 'Import Scope',
          id: 'addScope',
        },
      };
    }
    return {
      importNassco: {
        component: ImportNassco,
        name: 'Import DB',
        id: 'importNassco',
      },
    };
  }

  /**
   * @returns true if the user has the permission IMPORT_COMMON
   */
  get hasPermissionImportCommon(): boolean {
    return this.hasPermission(UserPermission.IMPORT_COMMON);
  }
}
