




































































































/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable object-curly-newline */
import { InspectionRelease } from '@/store/releases/types';
import Vue from 'vue';
import { Component, Emit, Prop, PropSync } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import { namespace } from 'vuex-class';
import utils from '@/components/Report/util';
import IntegrityTable from '@/components/IntegrityTable/IntegrityTable.vue';

const releasesModule = namespace('releases');

@Component({
  components: {
    IntegrityTable,
  },
})
export default class CustomerPrep extends Vue {
  @PropSync('activeTab') readonly syncedActiveTab: number;

  @Prop() readonly projectGuids: string;

  @releasesModule.State('inspectionReleases')
  inspectionReleases: InspectionRelease[];

  @releasesModule.State('loadingInspectionReleases')
  loadingInspectionReleases: boolean;

  tableHeight: string | number = '100%';

  customerPrepTableModel = [];

  search = '';

  tabOptions = [
    { name: 'Manhole', value: 'Manhole' },
    { name: 'Line Segment', value: 'Line Segment' },
  ];

  custMatchFilters = [
    {
      header: 'assetId',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'status',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'score',
      type: 'number',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'release',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'platform',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'dateInspected',
      type: 'date',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'dateReleased',
      type: 'date',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'dateShipped',
      type: 'date',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'upManhole',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'dnManhole',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'direction',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'lengthSurveyed',
      type: 'number',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
  ]

  get isCustomerPrepReleaseButtonDisabled(): boolean {
    return this.customerPrepTableModel == null
      || this.customerPrepTableModel.length === 0;
  }

  get custPrepHeaders(): DataTableHeader[] {
    const headers = [
      {
        text: 'Asset ID',
        value: 'assetId',
        type: 'string',
        sortable: true,
        filterable: true,
        class: 'sticky',
        cellClass: 'sticky',
      },
      {
        text: '',
        value: 'hasCustomerDeliverables',
        type: 'boolean',
        sortable: false,
        filterable: false,
        class: 'sticky',
        cellClass: 'sticky',
      },
      {
        text: 'Reporting Status',
        value: 'status',
        type: 'string',
        sortable: true,
        filterable: true,
      },
      {
        text: 'Score',
        value: 'score',
        type: 'number',
        sortable: true,
        filterable: true,
      },
      {
        text: 'Release',
        value: 'release',
        type: 'string',
        sortable: true,
        filterable: true,
      },
      {
        text: 'Platform',
        value: 'platform',
        type: 'string',
        sortable: true,
        filterable: true,
      },
      {
        text: 'Date Inspected',
        value: 'dateInspected',
        type: 'date',
        sortable: true,
        filterable: true,
      },
      {
        text: 'Date Released',
        value: 'dateReleased',
        type: 'date',
        sortable: true,
        filterable: true,
      },
      {
        text: 'Date Shipped',
        value: 'dateShipped',
        type: 'date',
        sortable: true,
        filterable: true,
      },
    ];

    const lsHeaders = [
      {
        text: 'US MH',
        value: 'upManhole',
        type: 'string',
        sortable: true,
        filterable: true,
      },
      {
        text: 'DS MH',
        value: 'dnManhole',
        type: 'string',
        sortable: true,
        filterable: true,
      },
      {
        text: 'Direction',
        value: 'direction',
        type: 'string',
        sortable: true,
        filterable: true,
      },
      {
        text: 'Length Surveyed',
        value: 'lengthSurveyed',
        type: 'number',
        sortable: true,
        filterable: true,
      },
    ];

    if (this.syncedActiveTab === 1) headers.splice(4, 0, ...lsHeaders);

    return headers;
  }

  get filterValues(): any {
    if (!this.customerPrepTableData) return {};

    const retVal = {};
    this.custMatchFilters.forEach((filter) => {
      retVal[filter.header] = [];
      this.customerPrepTableData.forEach((release) => {
        const valToPush = release[filter.header];
        const valExists = retVal[filter.header].includes(valToPush);

        if (!valExists && valToPush != null && valToPush !== '') {
          retVal[filter.header].push(valToPush);
        }

        retVal[filter.header].sort();
      });
    });

    return retVal;
  }

  get customerPrepTableData(): InspectionRelease[] {
    // check bad data
    if (
      this.inspectionReleases === undefined
      || this.inspectionReleases.length === 0
    ) {
      return [];
    }
    // Manholes only
    if (this.syncedActiveTab === 0) {
      return this.inspectionReleases.filter(
        (insp) => insp.assetType === 'Manhole'
        && (insp.status === 'QA Required'
          || insp.status === 'Ready For Release'
          || insp.status === 'Released'
        ),
      );
    }
    return this.inspectionReleases.filter(
      (insp) => insp.assetType === 'Line Segment'
      && (insp.status === 'QA Required'
        || insp.status === 'Ready For Release'
        || insp.status === 'Released'
      ),
    );
  }

  @Emit('setIsCustomerPrepReleaseDialogOpen')
  setIsCustomerPrepReleaseDialogOpen(): any[] {
    return this.customerPrepTableModel;
  }

  getScoreColorTable(score: number): string {
    return utils.getScoreColor(score);
  }

  reset(): void {
    this.customerPrepTableModel = [];
  }
}
