
import { UserPermissionsActions } from '@/store/userpermissions/actions';
import { UserPermission } from '@/store/userpermissions/types';
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const userPermissionsModule = namespace('userPermissions');

@Component
export default class UserPermissionsMixin extends Vue {
  @userPermissionsModule.Action(UserPermissionsActions.FETCH_USER_PERMISSIONS) fetchUserPermissions;

  @userPermissionsModule.State('permissions') userPermissions: UserPermission[];

  @userPermissionsModule.State('loading') userPermissionsLoading: boolean;

  @userPermissionsModule.State('error') userPermissionsError: string | undefined;

  permissions = UserPermission;

  /**
 * @description Check if user has a single permission
 * @returns true if user has a single permission
 */
  hasPermission(permission: UserPermission): boolean {
    if (process.env.JEST_WORKER_ID !== undefined) {
      return true;
    }
    return this.userPermissions.includes(permission);
  }

  /**
 * @description Check if user has a list of permissions
 * @returns true if user has all requested permissions
 */
  hasPermissions(permissions: UserPermission[]): boolean {
    if (process.env.JEST_WORKER_ID !== undefined) {
      return true;
    }
    const userPermissionsSet = new Set(this.userPermissions);
    const requestedPermissionsSet = new Set(permissions);
    const difference = [...requestedPermissionsSet]
      .filter((element) => !userPermissionsSet.has(element));
    return difference.length === 0;
  }

  /**
   * @description Send user to error page
   */
  goToErrorPage(): void {
    this.$router.push({
      name: 'Error',
      params: {
        catchAll: 'Error',
        message:
          'You do not have permission to view this page. If this an error, please contact support.',
      },
    });
  }
}
