











import {
  Component, Prop, Vue,
} from 'vue-property-decorator';
import UserEdit from '@/components/Admin/User/UserEdit.vue';

@Component({
  components: {
    UserEdit,
  },
})
export default class EditUser extends Vue {
  @Prop() readonly id!: string;

  @Prop() readonly auth0Id!: string;

  @Prop() readonly userData;

  resetUser(): void{
    this.$emit('setUserGuid', '');
    this.$emit('setUserAuth0Id', '');
    this.$emit('toggleEditUser');
  }
}
