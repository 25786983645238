


























































































































































































































































































































































/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Component, Prop, PropSync, Vue,
} from 'vue-property-decorator';

@Component({})
export default class MPSSectionInformation extends Vue {
  @Prop() readonly canEditMPS: boolean;

  @Prop() readonly requiredField;

  @PropSync('bookedDate') synchedBookedDate;

  @PropSync('city') synchedCity;

  @PropSync('contractAmount') synchedContractAmount;

  @PropSync('contractNumber') synchedContractNumber;

  @PropSync('endDate') synchedEndDate;

  @PropSync('jobNumber') synchedJobNumber;

  @PropSync('opportunityNumber') synchedOpportunityNumber;

  @PropSync('otherProjectReason') synchedOtherProjectReason;

  @PropSync('poNumber') synchedPONumber;

  @PropSync('projectName') synchedProjectName;

  @PropSync('projectScope') synchedProjectScope;

  @PropSync('startDate') synchedStartDate;

  @PropSync('reasonForProject') synchedReasonForProject;

  @PropSync('stateSelected') synchedStateSelected;

  @PropSync('totalFootage') synchedTotalFootage;

  @PropSync('totalVertical') synchedTotalVertical;

  @PropSync('projectOwner') synchedProjectOwner;

  @PropSync('inspectionPurpose') synchedInspectionPurpose;

  @PropSync('projectCustomer') synchedProjectCustomer;

  @PropSync('valid') synchedValid;

  bookedMenu = false;

  endDateMenu = false;

  projectReasonOptions = [
    'Capital Improvement',
    'General Condition Assessment',
    'New Construction',
    'Rehabilitation Planning',
    'Cleaning',
    'Other',
  ];

  startDateMenu = false;

  states = [
    'Alabama',
    'Alaska',
    'Arizona',
    'Arkansas',
    'California',
    'Colorado',
    'Connecticut',
    'Deleware',
    'Florida',
    'Georgia',
    'Hawaii',
    'Idaho',
    'Illinois',
    'Indiana',
    'Iowa',
    'Kansas',
    'Kentucky',
    'Louisiana',
    'Maine',
    'Maryland',
    'Massachusetts',
    'Michigan',
    'Minnesota',
    'Mississippi',
    'Missouri',
    'Montana',
    'Nebraska',
    'Nevada',
    'New Hampshire',
    'New Jersey',
    'New Mexico',
    'New York',
    'North Carolina',
    'North Dakota',
    'Ohio',
    'Oklahoma',
    'Oregon',
    'Pennsylvania',
    'Rhode Island',
    'South Carolina',
    'South Dakota',
    'Tennessee',
    'Texas',
    'Utah',
    'Vermont',
    'Virginia',
    'Washington',
    'West Virginia',
    'Wisconsin',
    'Wyoming',
  ];

  projectInspectionPurposeOptions = [
    'Capital improvement Program Assessment',
    'Infiltration/Inflow Investigation',
    'Maintenance Related',
    'Not Known',
    'Post Rehabilitation Survey',
    'Pre-Acceptance',
    'Resurvey',
    'Reversal',
    'Routine Assessment',
  ];

  resetValidation(): void {
    (this.$refs.infoForm as any).reset();
  }
}
