

































































































































































































/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Prop, Vue } from 'vue-property-decorator';
import { InspectionResponse } from '@/components/CodingForm/types';
import { namespace } from 'vuex-class';
import { AssetData } from '@/store/asset/types';
import { AssetActions } from '@/store/asset/actions';
import ReportObservation from '../ReportObservation.vue';
import ReportFooter from '../ReportFooter.vue';
import util from '../util';
import { structureInspectionReportFields } from './types';

const assetModule = namespace('asset');

@Component({
  components: {
    ReportFooter,
    ReportObservation,
  },
})
export default class GeneralMaintenanceReport extends Vue {
  @assetModule.State('asset') asset!: AssetData;

  @assetModule.Action(AssetActions.FETCH_ASSET_DATA) fetchAssetData;

  @Prop({ default: true }) readonly displayImperial!: boolean;

  @Prop() codingDetail: InspectionResponse;

  @Prop() assetType: string;

  @Prop({ default: '' }) workOrderReport: string;

  @Prop({ default: null }) item: any;

  structureInspectionReportFields: structureInspectionReportFields | any = {};

  workOrderJson: any;

  // eslint-disable-next-line class-methods-use-this
  formatText(text: string): string {
    if (!text) return '';
    let newText = text;

    newText = newText.replace(/&gt;/g, '>');
    newText = newText.replace(/&lt;/g, '<');
    newText = newText.replace(/&quot;/g, '"');

    return newText;
  }

  getDisplayDistanceInMM(value: number): string {
    return value || value === 0
      ? util.getDisplayDistanceInMM(this.displayImperial, value)
          + util.getDistanceUnitsInMM(this.displayImperial)
      : '';
  }

  getDisplayDistanceFtM(value: number): string {
    return value || value === 0
      ? util.getDisplayDistanceFtM(this.displayImperial, value)
          + util.getDistanceUnitsFtM(this.displayImperial)
      : '';
  }

  mounted(): void {
    this.workOrderJson = JSON.parse(this.codingDetail.inspections[0].jsonData);
    // fill fields
    this.fillStructureInspectionReportFields();
    // this.fetchAsset();
    this.$forceUpdate();
  }

  fillStructureInspectionReportFields(): void {
    this.structureInspectionReportFields = {
      name: this.item && this.item.nodes.name ? this.item.nodes.name : '',
      assignedTo: this.workOrderJson ? this.workOrderJson.Surveyor : '',
      priority: this.item ? this.item.priorityDescription : '',
      activity: this.workOrderJson ? this.workOrderJson.Activity : '',
      area: this.workOrderJson ? this.workOrderJson.Area : '',
      frequency: '', // ?
      route: '', // ?
      status: this.item ? this.item.status : '',
      workOrderNumber:
        this.item && this.item.workOrderNumber ? this.item.workOrderNumber : '',
      dateIssued: this.item ? this.formatDate(this.item.createdDate) : '',
      planDueDate: this.workOrderJson ? this.formatDate(this.workOrderJson.InspDate) : '',
      workDescription: this.workOrderJson ? this.workOrderJson.Description : '',
      specialInstructions: this.workOrderJson ? this.workOrderJson.SpecialInstruction : '',
      assetNumber: this.workOrderJson ? this.workOrderJson.ManholeNumber : '',
      streetLocation: this.workOrderJson ? this.workOrderJson.Address : '',
      crossStreet: this.workOrderJson ? this.workOrderJson.CrossStreet : '',
      structureType: this.workOrderJson ? this.workOrderJson.StructureType : '',
      specialConditions: this.workOrderJson ? this.workOrderJson.SpecialConditions : '',
      dateCompleted: this.workOrderJson ? this.formatDate(this.workOrderJson.WorkCompletedBy) : '',
      conditionsGood: this.workOrderJson ? this.workOrderJson.ConditionGFP === 'Good' : false,
      conditionsFair: this.workOrderJson ? this.workOrderJson.ConditionGFP === 'Fair' : false,
      conditionsPoor: this.workOrderJson ? this.workOrderJson.ConditionGFP === 'Poor' : false,
      followUpClean: this.workOrderJson ? this.workOrderJson.FollowUp_Clean : false,
      followUpRepair: this.workOrderJson ? this.workOrderJson.FollowUp_RepairRehab : false,
      followUpReInspect: this.workOrderJson ? this.workOrderJson.FollowUp_ReInspect : false,
      followUpOther: this.workOrderJson ? this.workOrderJson.FollowUp_Other : false,
      inspectionComments: this.workOrderJson ? this.workOrderJson.Comments : '',
    };
  }

  fetchAsset(): void {
    this.fetchAssetData({
      assetId: this.item.nodes.guid,
      inspectionId: this.item.guid,
    }).then(() => {
      this.$forceUpdate();
    });
  }

  get workOrderType(): string {
    if (!this.structureInspectionReportFields?.workOrderNumber) {
      return '';
    }
    return this.structureInspectionReportFields.workOrderNumber.split('-')[0];
  }

  formatDate(dateString: string): string {
    if (!dateString) {
      return '';
    }
    let date = new Date(dateString.substring(0, 10));

    if (date.toString() === 'Invalid Date') {
      return '';
    }

    const userTimezoneOffset = date.getTimezoneOffset() * 60000;
    date = new Date(date.getTime() + userTimezoneOffset);

    const year = date.getFullYear();
    const month = (1 + date.getMonth());
    const day = date.getDate();

    return `${month}/${day}/${year}`;
  }
}
