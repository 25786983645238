





















import {
  Component,
} from 'vue-property-decorator';
import ManageUsers from '@/components/Admin/User/ManageUsers.vue';
import EditUser from '@/views/Admin/EditUser.vue';
import UserPermissionsMixin from '@/components/UserPermissions/UserPermissionsMixin.vue';
import { UserPermission } from '@/store/userpermissions/types';

@Component({
  components: {
    ManageUsers,
    EditUser,
  },
})
export default class AdminUser extends UserPermissionsMixin {
    editUser = false;

    userGuid = '';

    userAuth0Id = '';

    userData = [];

    mounted(): void {
      if (!this.hasPermissionAdminCommon) {
        this.goToErrorPage();
      }
    }

    get validUser(): boolean {
      return this.userGuid !== '' && this.userAuth0Id !== '';
    }

    setUserGuid(guid: string): void {
      this.userGuid = guid;
    }

    setUserAuth0Id(guid: string): void {
      this.userAuth0Id = guid;
    }

    toggleEditUser(): void {
      this.editUser = !this.editUser;
    }

    /**
     * @returns true if the user has the permission ADMIN_COMMON
     */
    get hasPermissionAdminCommon(): boolean {
      return this.hasPermission(UserPermission.ADMIN_COMMON);
    }
}
