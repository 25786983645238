























































































































































































































































































/* eslint-disable @typescript-eslint/no-explicit-any */
import { InspectionResponse } from '@/components/CodingForm/types';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { AssetActions } from '@/store/asset/actions';
import { AssetData } from '@/store/asset/types';
import { ServiceCallReportFields } from './types';
import ReportFooter from '../ReportFooter.vue';

const assetModule = namespace('asset');

@Component({ components: { ReportFooter } })
export default class ServiceCallReport extends Vue {
  @assetModule.State('asset') asset!: AssetData;

  @assetModule.Action(AssetActions.FETCH_ASSET_DATA) fetchAssetData;

  @Prop({ default: null }) readonly item: any;

  @Prop() codingDetail: InspectionResponse;

  serviceCallReportFields: ServiceCallReportFields | any = {};

  get jsonData(): any {
    return JSON.parse(this.codingDetail.inspections[0].jsonData);
  }

  get dataStandardFormat(): any {
    return JSON.parse(this.codingDetail.inspections[0].dataStandard_format);
  }

  get sso(): string {
    return this.jsonData.TriBoolean_Overflow
      ? `Yes, ${this.jsonData.EstimatedFlowRate} gals`
      : 'No';
  }

  async mounted(): Promise<void> {
    await this.fetchAssetData({ assetId: this.item.nodes.guid });
    this.fillReportFields();
  }

  fillReportFields(): void {
    this.serviceCallReportFields = {
      customer: this.asset?.inspections.length > 0
        && this.asset?.inspections[0].report?.generalInformation ? this.asset.inspections[0].report.generalInformation['customer'] : '',
      assignedTo: this.item ? this.item.resourceGroupName : '',
      status: this.item ? this.item.status : '',
      workOrder: this.item ? this.item.workOrderNumber : '',
      dateIssued: this.item ? this.formatDate(this.item.createdDate) : '',
      dateCompleted: this.item ? this.formatDate(this.jsonData.CallDate) : '',
      caller: this.jsonData ? this.jsonData.Caller : '',
      callReceivedBy: this.jsonData ? this.jsonData.ComplaintReceivedBy : '',
      natureOfCall: this.formatMultiSelect('NatureofCall'),
      locationOfProblem: this.jsonData ? this.jsonData.GeneralArea : '',
      dispatchReferredTo: this.jsonData ? this.jsonData.ReferredTo : '',
      address: this.jsonData ? this.jsonData.Address : '',
      callDate: this.jsonData ? this.formatDate(this.jsonData.CallTime) : '',
      callDesc: this.jsonData ? this.jsonData.ProblemDescription : '',
      reportedBy: this.formatMultiSelect('ReportedBy'),
      dispatchReferredBy: this.jsonData ? this.jsonData.ReferredBy : '',
      telephone: this.jsonData ? this.jsonData.BusPhone : '',
      dispatchDate: this.jsonData ? this.formatDate(this.jsonData.DispatchTime) : '',
      responderName: this.jsonData ? this.jsonData.ResponderName : '',
      responderPhone: this.jsonData ? this.jsonData.TroubleCallNum : '',
      asset: this.jsonData ? this.jsonData.Wastewater : '',
      problem: this.formatMultiSelect('Problem'),
      obRemarks: '',
      arrivalDate: this.jsonData ? this.formatDate(this.jsonData.ArrivalTime) : '',
      location: this.formatMultiSelect('Location'),
      cause: this.formatMultiSelect('Cause'),
      weather: this.jsonData ? this.jsonData.WeatherType : '',
      sso: this.sso,
      ssoReachedWaterBody: this.jsonData?.TriBoolean_OverflowToH20 ? 'Yes' : 'No',
      activities: this.formatMultiSelect('Performed'),
      activityRemarks: this.jsonData ? this.jsonData.ActivityRemarks : '',
      activitiesRequested: this.formatMultiSelect('Rec'),
      recommendationRemarks: this.jsonData ? this.jsonData.RecRemarks : '',
      followUpPriority: this.jsonData ? this.jsonData.Priority : '',
      callerNotifiedBy: this.jsonData ? this.jsonData.NotifiedBy : '',
      notifiedBy: this.formatMultiSelect('NotifiedBy'),
      concReferredTo: this.jsonData ? this.jsonData.FollowUpReferredTo : '',
      concRemarks: this.jsonData ? this.jsonData.FollowUpRemarks : '',
      explainWhyNone: this.jsonData ? this.jsonData.ExplanationIfNoneOther : '',
      concDate1: this.jsonData ? this.formatDate(this.jsonData.NotificationDate) : '',
      concReferredBy: this.jsonData ? this.jsonData.FollowReferralBy : '',
      letterOfAppreciation: this.jsonData?.TriBoolean_LOARequired ? 'Yes' : 'No',
      letterSentDate: this.jsonData ? this.formatDate(this.jsonData.LOASentDate) : '',
      concDate2: this.jsonData ? this.formatDate(this.jsonData.FollowReferralDate) : '',
    };
  }

  formatDate(dateISOString: string) : string {
    if (!dateISOString) {
      return '';
    }

    const splitStrings = (dateISOString).split('T');
    const date = new Date(splitStrings[0]).toLocaleString().split(', ')[0];

    return date;
  }

  formatMultiSelect(groupName: string): string {
    if (!this.jsonData) {
      return '';
    }
    const values = [];
    const keys = Object.keys(this.jsonData);

    keys.forEach((key) => {
      if (key.includes(groupName) && typeof this.jsonData[key] === 'boolean' && this.jsonData[key]) {
        const option = this.dataStandardFormat.formpage[0].formpagedata.find(
          (form) => form.headername === key,
        );
        const value = option.description.split(':')[1];

        values.push(value);
      }
    });

    return values.join(', ');
  }
}
