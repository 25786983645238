import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { mutations } from './mutations';
import { MetricsState } from './types';
import { actions } from './actions';
import getters from './getters';

const state : MetricsState = {
  loadingWorkOrders: false,
  loadingStructGrade: false,
  loadingAssignments: false,
  loadError: undefined,
  workOrderMetrics: undefined,
  structGradeMetrics: undefined,
  assignmentMetrics: undefined,
  loadingOmMetrics: false,
  omDefectMetrics: undefined,
  loadingDataDeliveryProjectMetrics: false,
  dataDeliveryProjectMetrics: undefined,
  projectTotalsData: undefined,
  projectTotalsError: undefined,
  projectTotalsLoading: false,
  loadingWorkOrderProjectMetrics: false,
  workOrderProjectMetrics: undefined,
  loadingAssetCountsMetrics: false,
  assetCountsMetrics: undefined,
  assetCountsMetricsError: undefined,
  loadingProjectOverviewMetrics: false,
  projectOverviewMetrics: undefined,
  projectOverviewMetricsError: undefined,
  loadingOverviewTotalCounts: false,
  overviewTotalCounts: undefined,
  overviewTotalCountsError: undefined,
  crewCountsData: undefined,
  crewCountsLoading: false,
  crewCountsError: undefined,
  crewCountsUpdateData: undefined,
  crewCountsAveragesData: undefined,
  crewCountsAveragesLoading: false,
  crewCountsAveragesError: undefined,
  crewCountAveragesUpdateData: undefined,
  dashboardDefectData: undefined,
  dashboardDefectLoading: false,
  dashboardDefectError: undefined,
  projectDefectsData: undefined,
  projectDefectsLoading: false,
  projectDefectsError: undefined,
  nasscoInspectionScoresLoading: false,
  nasscoInspectionScoresData: undefined,
  platformCrewCountsLoading: false,
  platformCrewCounts: undefined,
  clientActionItems: undefined,
  loadingClientActionItems: false,
  clientActionItemsError: undefined,
  loadingCrewCountsByDay: false,
  crewCountsByDay: undefined,
  crewCountsByDayError: undefined,
  crewDataDeliveryProjectMetrics: undefined,
  crewDataDeliveryProjectMetricsLoading: false,
  crewDataDeliveryProjectMetricsError: undefined,
  customerDashboardWorkOrders: undefined,
  customerDashboardWorkOrdersLoading: false,
  customerDashboardWorkOrdersError: undefined,
  customerDataWorkOrderCounts: undefined,
  customerDataWorkOrderLoading: false,
  customerDataWorkOrderError: undefined,
  scheduledDate: { value: 'All', dateValues: null },
  completedDate: { value: 'All', dateValues: null },
};

const mapPages: Module<MetricsState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default mapPages;
