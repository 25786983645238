








































































































































































































































import { BasicSelect } from 'vue-search-select';
import { Component, Prop, Watch } from 'vue-property-decorator';
import ProjectList from '@/components/Admin/User/ProjectList.vue';
import CreateUser from '@/components/Admin/User/CreateUser.vue';
import { namespace } from 'vuex-class';
import { UserActions } from '@/store/users/actions';
import { DetailedUserData } from '@/store/users/types';
import { AdminActions } from '@/store/admin/actions';
import { ProjectListing } from '@/store/project/types';
import { ProjectActions } from '@/store/project/actions';
import { EdititableUser, UserRoleObject } from '@/store/admin/types';
import UserPermissionsMixin from '@/components/UserPermissions/UserPermissionsMixin.vue';
import { UserPermission } from '@/store/userpermissions/types';
import {
  getRoleGuid,
  getRoleGuidString,
  getRoleId,
  getRoleString,
  AuthUserRoles,
} from '../../../auth/roles';
import PermissionsMatrix from './PermissionsMatrix.vue';

const userModule = namespace('users');
const adminModule = namespace('admin');
const projectModule = namespace('project');

@Component({
  components: {
    BasicSelect,
    ProjectList,
    CreateUser,
    PermissionsMatrix,
  },
})
export default class UserEdit extends UserPermissionsMixin {
  @Prop() readonly id!: string;

  @Prop() readonly auth0Id!: string;

  @Prop() readonly super!: boolean;

  @Prop() readonly userData;

  isActive = true;

  addProjectDialog = false as boolean;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, arrow-parens
  requiredField = [(v) => !!v || v === 0 || 'This is a required field.'];

  saveEditUserValid = false as boolean;

  isExpanded = [];

  fullName = '' as string;

  email = '' as string;

  showSnackbar = false as boolean;

  snackbarColor = '' as string;

  snackbarMessage = '' as string;

  selectedProjectName = '';

  startDateMenu = false as boolean;

  endDateMenu = false as boolean;

  startDate = null;

  endDate = null;

  userRole = '';

  currentRole = '';

  selectedUser = {
    detailedUserData: {
      guid: '',
      firstname: '',
      lastname: '',
      email: null,
      role: [],
    },
  };

  @userModule.Action(UserActions.FETCH_ALL_DETAILED_USER_DATA)
  fetchAllDetailedUserData;

  @userModule.State('allDetailedUserData') allUsers: DetailedUserData[];

  @adminModule.State('response') adminResponse;

  @adminModule.State('loadError') adminLoadError;

  @projectModule.State('allProjectsData') allProjects:
    | ProjectListing[]
    | undefined;

  @projectModule.Action(ProjectActions.FETCH_ALL_PROJECTS_DATA)
  fetchAllProjectsData;

  @adminModule.Action(AdminActions.PATCH_USER) patchUser;

  get roleItems(): string[] {
    return AuthUserRoles.map((r) => r.name);
  }

  goToAddUser(): void {
    this.$emit('resetUser');
    // window.location.href = '/admin';
  }

  async saveUser(): Promise<void> {
    try {
      const userEdit = this.createUser();
      await this.patchUser(userEdit);
      // add new roles
      const newRole = getRoleId(this.currentRole);
      await this.$auth.deleteRoles(this.auth0Id, [this.userRole]);
      const addResults = await this.$auth.addRoles(this.auth0Id, [newRole]);
      if (addResults === 'false') {
        throw new Error('Unable to Add Roles');
      }
      // update state to match users new role
      this.userRole = newRole;
    } catch (ex) {
      this.snackbarColor = '#e61e25';
      this.snackbarMessage = (ex as Error).message;
      this.showSnackbar = true;
      this.$forceUpdate();
      return;
    }
    this.snackbarColor = 'green';
    this.snackbarMessage = 'Changes Saved';
    this.showSnackbar = true;
    this.$forceUpdate();
  }

  async mounted(): Promise<void> {
    this.fetchAllProjectsData().catch(() => {
      this.$router
        .push({
          name: 'Error',
          params: {
            catchAll: 'Error',
            message:
              'There was an error retrieving your projects. Please try again later. If this issue persists, please contact support.',
          },
          // eslint-disable-next-line @typescript-eslint/no-empty-function
        }).catch((e) => {});
    });

    this.setUserRoles();
    this.selectedUser = this.userData.find(
      (x) => x.detailedUserData.guid === this.id,
    );
    if (
      this.selectedUser.detailedUserData
      && this.selectedUser.detailedUserData.firstname
      && this.selectedUser.detailedUserData.lastname
    ) {
      this.fullName = `${this.selectedUser.detailedUserData.firstname} ${this.selectedUser.detailedUserData.lastname}`;
    }
    this.email = this.selectedUser.detailedUserData.email;

    const dbUser = this.allUsers.find(
      (u) => u.useridentity === this.selectedUser.detailedUserData['useridentity'],
    );
    this.isActive = dbUser?.isActive ?? false;
  }

  @Watch('selectedUser')
  onSelectedUserChange(): void {
    this.setUserRoles();
  }

  createUser(): EdititableUser {
    const fullNameArray = this.fullName.split(' ');
    const userRoleObjects: UserRoleObject[] = this.selectedUser.detailedUserData.role
      .map((role) => ({
        ProjectGuid: role.projectGuid,
        RoleGuid: getRoleGuid(this.currentRole),
        StartDate: role.startDate,
        EndDate: role.endDate,
      }));
    const user = {
      Guid: this.id,
      Firstname: fullNameArray[0],
      Lastname: fullNameArray[1],
      userRoleObjects,
      isActive: this.isActive,
    };
    return user;
  }

  saveEditUser(): void {
    const selectedProject = this.allProjects.find(
      (p) => p.name === this.selectedProjectName,
    );
    this.selectedUser.detailedUserData.role.push({
      projectGuid: selectedProject.guid,
      roleGuid: getRoleGuid(this.currentRole),
      startDate: this.startDate,
      endDate: this.endDate,
    });

    this.selectedProjectName = '';
    this.startDate = null;
    this.endDate = null;

    this.addProjectDialog = false;
    this.$forceUpdate();
  }

  getRoleString(role: string): string {
    return getRoleString(role);
  }

  get filteredProjects(): string[] {
    if (!this.allProjects) {
      return [];
    }
    return this.allProjects
      .filter(
        (proj) => !this.selectedUser.detailedUserData.role.find(
          (userProject) => userProject.projectGuid === proj.guid,
        ),
      )
      .map((project) => project.name)
      .sort();
  }

  async setUserRoles(): Promise<void> {
    const userRoles = await this.$auth.getRoles(`${this.auth0Id}`);

    if (!userRoles) {
      return;
    }

    // If user has more than 1 row, then delete extra roles to bring user down to 1 role
    if (userRoles.length > 1) {
      console.error('User has multiple roles');
      await this.$auth.deleteRoles(this.auth0Id, userRoles.slice(1));
    }
    // eslint-disable-next-line prefer-destructuring
    this.userRole = userRoles[0];

    this.currentRole = getRoleString(this.userRole);
  }

  /**
   * @returns true if the user has the permission ADMIN_DELETEUSER
   */
  get hasPermissionAdminDeleteUser(): boolean {
    return this.hasPermission(UserPermission.ADMIN_DELETEUSER);
  }

  /**
   * @returns true if the user has the permission ADMIN_SETDATERANGEFORACTIVATION
   */
  get hasPermissionAdminSetDateRangeForActivation(): boolean {
    return this.hasPermission(UserPermission.ADMIN_SETDATERANGEFORACTIVATION);
  }
}
