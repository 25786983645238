










































































































































































































/* eslint-disable object-curly-newline */
/* eslint-disable @typescript-eslint/no-explicit-any */
import IntegrityTable from '@/components/IntegrityTable/IntegrityTable.vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { ProjectActions } from '@/store/project/actions';
import { ProjectGraphics, ProjectHeaders, ProjectListing } from '@/store/project/types';
import { UserPermission } from '@/store/userpermissions/types';
import { TableMode } from '@/components/ExportDataPopout/types';
import utils from '@/components/IntegrityTable/utils';
import { cloneDeep } from 'lodash';
import { UserActions } from '@/store/users/actions';
import DeploymentEditSoloPipe from './DeploymentEditSoloPipe.vue';
import { DeploymentData } from '../../store/deployments/types';
import DeploymentsMixin from './DeploymentsMixin.vue';
import DeploymentEditMHVertue from './DeploymentEditMHVertue.vue';

const projectModule = namespace('project');
const userPrefsModule = namespace('userPrefs');
const userModule = namespace('users');

@Component({
  components: {
    IntegrityTable,
    DeploymentEditSoloPipe,
    DeploymentEditMHVertue,
  },
})
export default class Deployments extends DeploymentsMixin {
  @Prop() readonly ids!: string;

  @projectModule.State('headers') projectHeaders: ProjectHeaders | undefined;

  @projectModule.State('graphics') projectGraphics: ProjectGraphics[] | undefined;

  @projectModule.State('dashboardMetrics') projectMetrics: ProjectListing | undefined;

  @userPrefsModule.State('displayImperial') displayImperial: boolean;

  @projectModule.Action(ProjectActions.FETCH_HEADER_DATA) fetchProjectHeaders;

  @projectModule.Action(ProjectActions.FETCH_GRAPHICS_DATA) fetchProjectGraphics;

  @projectModule.Action(ProjectActions.FETCH_DASHBOARD_PROJECT_METRICS) fetchDashboardMetrics;

  @projectModule.Action(ProjectActions.FETCH_PROJECT_TABLE_DATA) fetchProjectData;

  @userModule.Action(UserActions.FETCH_ALL_DETAILED_USER_DATA)
  fetchAllDetailedUserData;

  tableMode = TableMode.NORMAL;

  exportPopup = false;

  address = '';

  search = '';

  videoFileNameScheme = '';

  videoFileNameOptions = [
    'ProjectName_Date',
    'GUID',
  ];

  tabOptions = [
    { name: 'Manhole', value: 'Manhole' },
    { name: 'Line Segment', value: 'Line Segment' },
  ]

  valid = false;

  filterValues = {};

  tableHeight = '100%';

  selectedSection = '';

  activeAssetType = '';

  openEditDialog = false;

  editItem: DeploymentData | undefined = null;

  editGuidArray: string[] = [];

  @Watch('selectedSection')
  onSelectedSectionChange(): void {
    this.editItem = null;
    this.openEditDialog = false;
  }

  get headers(): any {
    switch (this.selectedSection) {
      case 'Solo MH':
      case 'Vertue':
        return this.soloVertueManholeHeaders;
      case 'Solo':
        return this.soloPipeHeaders;
      default:
        return this.defaultHeaders;
    }
  }

  get matchFilters(): any {
    switch (this.selectedSection) {
      case 'Solo MH':
      case 'Vertue':
        return this.soloVertueManholeMatchFilters;
      case 'Solo':
        return this.soloPipeMatchFilters;
      default:
        return this.defaultMatchFilters;
    }
  }

  get filteredDeploymentsData(): DeploymentData[] {
    if (!this.deploymentsData) return [];

    let platform = '';

    switch (this.selectedSection) {
      case 'Solo MH':
        this.activeAssetType = 'Manhole';
        platform = 'Solo';
        break;
      case 'Vertue':
        this.activeAssetType = 'Manhole';
        platform = 'Vertue';
        break;
      case 'Solo':
        this.activeAssetType = 'Line Segment';
        platform = 'Solo';
        break;
      case 'CCTV':
        this.activeAssetType = 'Line Segment';
        platform = 'CCTV';
        break;
      case 'Profiler':
        this.activeAssetType = 'Line Segment';
        platform = 'Profiler';
        break;
      case ' P3D':
        this.activeAssetType = 'Line Segment';
        platform = 'P3D';
        break;
      default:
        this.activeAssetType = '';
        return [];
    }

    return this.deploymentsData.filter(
      (asset) => asset.assetType === this.activeAssetType && asset.platformDesc === platform,
    );
  }

  get isEditable(): boolean {
    return this.tableMode === TableMode.EDIT;
  }

  get sectionOptions(): string[] {
    if (!this.deploymentsData) return [];

    const retVal = [];

    const hasSoloMH = this.deploymentsData.some((dd) => dd.platformDesc === 'Solo' && dd.assetType === 'Manhole');
    const hasVertue = this.deploymentsData.some((dd) => dd.platformDesc === 'Vertue');
    const hasSoloLS = this.deploymentsData.some((dd) => dd.platformDesc === 'Solo' && dd.assetType === 'Line Segment');
    const hasCCTV = this.deploymentsData.some((dd) => dd.platformDesc === 'CCTV');
    const hasProfiler = this.deploymentsData.some((dd) => dd.platformDesc === 'Profiler');
    const hasP3D = this.deploymentsData.some((dd) => dd.platformDesc === 'P3D');

    if (hasSoloMH) retVal.push('Solo MH');
    if (hasVertue) retVal.push('Vertue');
    if (hasSoloLS) retVal.push('Solo');
    if (hasCCTV) retVal.push('CCTV');
    if (hasProfiler) retVal.push('Profiler');
    if (hasP3D) retVal.push('P3D');

    return retVal;
  }

  get metricsTotal(): string {
    if (!this.projectMetrics) return '-';

    switch (this.activeAssetType) {
      case 'Manhole':
        return this.projectMetrics.totalManholes.toString();
      case 'Line Segment':
        return this.getConvertedDistance(this.projectMetrics.totalFootage);
      default:
        return '-';
    }
  }

  get metricsCollected(): string {
    if (!this.projectMetrics) return '0';

    switch (this.activeAssetType) {
      case 'Manhole':
        return this.projectMetrics.mhCollected.toString();
      case 'Line Segment':
        return this.getConvertedDistance(this.projectMetrics.footageCollected);
      default:
        return '-';
    }
  }

  /**
   * @returns true if the user has the permission DEPLOYMENTS_COMMON
   */
  get hasPermissionDeploymentsCommon(): boolean {
    return this.hasPermission(UserPermission.DEPLOYMENTS_COMMON);
  }

  /**
   * @returns true if the user has the permission DEPLOYMENTS_EDITINLINE
   */
  get hasPermissionDeploymentsEditInline(): boolean {
    return this.hasPermission(UserPermission.DEPLOYMENTS_EDITINLINE);
  }

  /**
   * @returns true if the user has the permission DEPLOYMENTS_EDITROW
   */
  get hasPermissionDeploymentsEditRow(): boolean {
    return this.hasPermission(UserPermission.DEPLOYMENTS_EDITROW);
  }

  /**
   * @returns true if the user has the permission DEPLOYMENTS_EXPORT
   */
  get hasPermissionDeploymentsExport(): boolean {
    return this.hasPermission(UserPermission.DEPLOYMENTS_EXPORT);
  }

  /**
   * @returns true if the user has the permission DEPLOYMENTS_EDIT_UNINVOLVED_DEPLOYMENT_DETAILS
   */
  get hasPermissionDeploymentsEditUninvolved(): boolean {
    return this.hasPermission(UserPermission.DEPLOYMENTS_EDIT_UNINVOLVED_DEPLOYMENT_DETAILS);
  }

  /**
   * @description Finds if the current user can use the deployment form to edit a deployment
   * @param {DeploymentData} item - deployment item
   * @returns true if the user can edit the deployment
   */
  canEditRow(item: DeploymentData): boolean {
    if (!this.hasPermissionDeploymentsEditRow) {
      return false;
    }

    // Dont let any users edit rfr or released deployment
    if (this.isReadyForRelease(item)) {
      return false;
    }

    if (this.hasPermissionDeploymentsEditUninvolved) {
      // User is allowed to edit deployments they are not involved in
      return true;
    }

    return item.isPartOfMission;
  }

  /**
   * @returns true if the deployment is 'Ready For Release' or 'Released'
   */
  isReadyForRelease(item: DeploymentData): boolean {
    return item.releaseStatus === 'Ready For Release'
      || item.releaseStatus === 'Released';
  }

  async mounted(): Promise<void> {
    this.fetchAllDetailedUserData();
    this.fetchDeploymentsData(JSON.parse(this.$route.params.ids)).then(() => {
      this.deploymentsData.forEach((data) => {
        const temp = data;

        temp.turnaroundReason = this.formatReason(temp.turnaroundReason);
        if (temp.direction) {
          temp.direction = temp.direction.replace('D: downstream', 'Downstream');
          temp.direction = temp.direction.replace('U: upstream', 'Upstream');
        }

        temp.mediaUploaded = temp.mediaUploaded ? 'Yes' : 'No';
        temp.isActive = temp.isActive ? 'Yes' : 'No';
        temp.macp = temp.macp ? 'Yes' : 'No';

        this.populateFilterValues(temp);
        this.getRouteParams();
      });
    });

    let projectGuidList: string[];
    let id: string;

    try {
      projectGuidList = JSON.parse(this.ids) as string[];
      [id] = projectGuidList;
    } catch {
      id = this.ids;
      projectGuidList = [id];
    }

    this.fetchDashboardMetrics(projectGuidList[0]);
    await this.fetchProjectHeaders(projectGuidList);
    await this.fetchProjectGraphics(projectGuidList);
    await this.fetchProjectData(projectGuidList);
    this.projectGraphics.filter((graphic) => graphic.type !== 'Lateral').forEach((graphic) => {
      this.assets.push({
        name: graphic.name,
        guid: graphic.guid,
      });
    });
    await this.fetchProjectData(projectGuidList);
  }

  /**
   *  @description Gets the route params and fills the match filters from the query
   */
  getRouteParams(): void {
    if (!this.matchFilters) {
      return;
    }
    const route = this.$route;
    if (Object.keys(route.query).length > 0) {
      const queries = Object.entries(route.query);
      const filterHeaders = this.matchFilters.map((mf) => mf.header);
      queries.forEach((pair) => {
        const [key, value] = pair;
        if (filterHeaders.includes(key)) {
          const valueArray = Array.isArray(value) ? value : [value];
          this.matchFilters.find((mf) => mf.header === key).options.push(...valueArray);
        }
      });
    }
  }

  formatReason(reason: string): string {
    let retVal = reason;

    switch (reason) {
      case 'MISSION_TURNAROUND_OPEN_CHAMBER':
        retVal = 'Open Chamber';
        break;
      case 'MISSION_TURNAROUND_SURCHARGED_MH/PIPE':
        retVal = 'Surcharged MH / Pipe';
        break;
      case 'MISSION_TURNAROUND_CANNOT_OPEN_MANHOLE':
        retVal = 'Cannot Open Manhole';
        break;
      case 'MISSION_TURNAROUND_MANHOLE_INSPECTION':
        retVal = 'Manhole Inspection';
        break;
      case 'MISSION_TURNAROUND_OTHER':
        retVal = 'Other';
        break;
      default:
        break;
    }

    return retVal;
  }

  toggleEdit(): void {
    if (this.tableMode === TableMode.EDIT) {
      this.saveEdits();
    }
    this.tableMode = this.tableMode !== TableMode.EDIT ? TableMode.EDIT : TableMode.NORMAL;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async saveDeliverable(deliverable: DeploymentData): Promise<void> {
    const deliverableGuid = deliverable.deploymentGuid;
    if (!this.editGuidArray.includes(deliverableGuid)) {
      this.editGuidArray.push(deliverableGuid);
    }
  }

  async saveEdits(): Promise<void> {
    if (this.editGuidArray.length === 0) {
      return;
    }
    const deliverableObjects = this.editGuidArray
      .map((d) => this.deploymentsData.find((dep) => dep.deploymentGuid === d));
    const deliverableClones = deliverableObjects.map((d) => cloneDeep(d));

    deliverableClones.forEach((deliverableClone) => {
      // eslint-disable-next-line no-param-reassign
      deliverableClone.nodeGuid = this.assets
        .find((asset) => asset.name === deliverableClone.name).guid;
      // eslint-disable-next-line no-param-reassign
      deliverableClone.isActive = deliverableClone.isActive === 'Yes';
      // eslint-disable-next-line no-param-reassign
      deliverableClone.macp = deliverableClone.isActive === 'Yes';
    });
    await this.patchDeploymentData(deliverableClones).catch((error) => {
      console.error(error);
    });
    this.editGuidArray = [];
  }

  populateFilterValues(data: DeploymentData): void {
    const ignoreColumns = ['name', 'deploymentNumber', 'additionalInfo'];

    this.headers.forEach((header) => {
      if (!ignoreColumns.includes(header.value)) {
        let checkVal: string;

        if (!this.filterValues[header.value]) this.filterValues[header.value] = [];

        try {
          checkVal = data[header.value];
        } catch {
          checkVal = '';
        }

        if (
          checkVal
          && checkVal !== ''
          && Array.isArray(this.filterValues[header.value])
          && !this.filterValues[header.value].includes(checkVal)
        ) {
          this.filterValues[header.value].push(checkVal);
        }

        this.filterValues[header.value].sort();
      }
    });
  }

  getConvertedDistance(distance: number): string {
    return utils.getConvertedDistance(distance, this.displayImperial);
  }

  openExportDialogue(): void {
    // eslint-disable-next-line prefer-destructuring
    this.videoFileNameScheme = this.videoFileNameOptions[0];
    this.address = '';
    this.exportPopup = true;
    if (this.$refs.emailField) {
      (this.$refs.emailField as any).reset();
    }
  }

  exportProject(): void {
    this.exportDeploymentCsv({
      guids: JSON.parse(this.$route.params.ids),
      email: this.address,
      fileName: this.videoFileNameScheme,
    }).then(() => {
      // eslint-disable-next-line no-alert
      alert('Your export request has been received. The download links will be sent shortly to the email provided.');
      this.exportPopup = false;
    });
  }

  edit(item: DeploymentData): void {
    this.openEditDialog = true;
    this.editItem = { ...item };
  }

  updateDeploymentData(deployment: DeploymentData): void {
    const deployments = [...this.deploymentsData];
    const depToUpdate = deployments.find((d) => d.deploymentGuid === deployment.deploymentGuid);
    const index = deployments.indexOf(depToUpdate);
    if (index !== -1) {
      deployments[index] = deployment;
      this.setDeploymentsData(deployments);
    }
  }
}
