var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"section-container"},[_c('div',{attrs:{"id":"table-header"}},[_c('v-tabs',{staticClass:"assetTabs",attrs:{"height":"100%","color":"#0C6599"},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},_vm._l((_vm.tabOptions),function(tab){return _c('v-tab',{key:tab.name,staticClass:"assetTableTab py-2",attrs:{"id":tab.name}},[_vm._v(" "+_vm._s(tab.value)+" ")])}),1),_c('v-spacer'),_c('div',{attrs:{"id":"asset-metrics"}},[_c('div',[_c('span',{staticClass:"metric-label"},[_vm._v("Project Total: ")]),_c('span',{staticClass:"metric-value"},[_vm._v(" "+_vm._s(_vm.deliveryTotal)+" ")])]),_c('span',[_vm._v(" | ")]),_c('div',[_c('span',{staticClass:"metric-label"},[_vm._v("Coded ")]),_c('span',{staticClass:"metric-value"},[_vm._v(" "+_vm._s(_vm.deliveryCoded)+" ")])]),_c('span',[_vm._v(" | ")]),_c('div',[_c('span',{staticClass:"metric-label"},[_vm._v("Released: ")]),_c('span',{staticClass:"metric-value"},[_vm._v(" "+_vm._s(_vm.deliveryReleased)+" ")])])]),_c('v-text-field',{staticClass:"search-bar",attrs:{"outlined":"","dense":"","hide-details":"","placeholder":"Search","clearable":"","append-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{staticClass:"outline-btn",attrs:{"outlined":""},on:{"click":_vm.clearAllFilters}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-filter-remove-outline")]),_vm._v(" Clear ")],1)],1),_c('div',{staticClass:"table-container"},[_c('IntegrityTable',{key:"release-overview-table",ref:"releaseOverviewTable",attrs:{"data":_vm.filteredTableData,"headers":_vm.tableHeaders,"matchFilters":_vm.matchFilters,"filterValues":_vm.filterValues,"blankMatchFilters":_vm.blankMatchFilters,"height":_vm.tableHeight,"itemsPerPage":100,"search":_vm.search,"footer-props":{
        'disable-items-per-page': false,
        'items-per-page-options': [100, 250, 500, -1],
      },"sortBy":_vm.sortBy},on:{"update:data":function($event){_vm.filteredTableData=$event},"update:matchFilters":function($event){_vm.matchFilters=$event},"update:match-filters":function($event){_vm.matchFilters=$event},"update:filterValues":function($event){_vm.filterValues=$event},"update:filter-values":function($event){_vm.filterValues=$event},"update:blankMatchFilters":function($event){_vm.blankMatchFilters=$event},"update:blank-match-filters":function($event){_vm.blankMatchFilters=$event},"update:height":function($event){_vm.tableHeight=$event}},scopedSlots:_vm._u([{key:"navigation",fn:function(ref){
      var item = ref.item;
return [_vm._t("guid",[_c('a',{staticStyle:{"text-decoration":"none"},attrs:{"tag":"button"},on:{"click":function($event){return _vm.$emit('changeRelease', item.releaseGuid)}}},[_c('v-icon',{attrs:{"color":"#0c6599"}},[_vm._v("mdi-arrow-right-circle")])],1)],{"item":item})]}},{key:"actions",fn:function(ref){
      var item = ref.item;
return [_c('div',{attrs:{"id":"actions-buttons"}},[_c('v-btn',{attrs:{"icon":"","disabled":!_vm.canEdit},on:{"click":function($event){return _vm.edit(item)}}},[_c('v-icon',[_vm._v("mdi-pencil-outline")])],1),_c('v-btn',{attrs:{"icon":"","disabled":!_vm.canDelete},on:{"click":function($event){return _vm.toggleUndoDialog(item.releaseName, item.releaseGuid)}}},[_c('v-icon',[_vm._v("mdi-arrow-u-left-top")])],1)],1)]}},{key:"url",fn:function(ref){
      var item = ref.item;
return [_vm._t("url",[(item.transmittalFileUrl)?_c('a',{staticStyle:{"text-decoration":"none"},attrs:{"tag":"button","target":"_blank","href":item.transmittalFileUrl}},[_c('div',[_vm._v(_vm._s(item.transmittalFileName))])]):_c('div',[_vm._v("-")])],{"item":item})]}}],null,true)})],1),_c('v-dialog',{attrs:{"width":"30vw"},model:{value:(_vm.showUndoDialog),callback:function ($$v) {_vm.showUndoDialog=$$v},expression:"showUndoDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Undo Release")]),_c('v-card-text',[_vm._v("Are you sure you want to delete Release "+_vm._s(_vm.releaseToUndo)+"?")]),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"#0c6599","outlined":""},on:{"click":function($event){_vm.showUndoDialog = false}}},[_vm._v(" No, cancel ")]),_c('v-spacer'),_c('v-btn',{staticClass:"integrity-blue-button",attrs:{"loading":_vm.deleteReleaseLoading},on:{"click":_vm.undo}},[_vm._v(" Yes, delete ")])],1)],1)],1),_c('v-snackbar',{attrs:{"color":_vm.snackbarColor,"timeout":"3000"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
      var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.snackbarShow = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snackbarShow),callback:function ($$v) {_vm.snackbarShow=$$v},expression:"snackbarShow"}},[_vm._v(" "+_vm._s(_vm.snackbarMessage)+" ")]),_c('ReleaseEdit',{attrs:{"isOpen":_vm.openReleaseEdit,"projectGuid":_vm.projectGuidsList[0],"editReleaseModel":_vm.editReleaseModel},on:{"update:isOpen":function($event){_vm.openReleaseEdit=$event},"update:is-open":function($event){_vm.openReleaseEdit=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }