





























































































































































































































































/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Prop, Vue } from 'vue-property-decorator';
import { InspectionResponse } from '@/components/CodingForm/types';
import { namespace } from 'vuex-class';
import { AssetData } from '@/store/asset/types';
import { AssetActions } from '@/store/asset/actions';
import ReportObservation from '../ReportObservation.vue';
import PipeConditionReport from '../../PipeConditionReport/PipeConditionReport.vue';
import ReportFooter from '../ReportFooter.vue';
import util from '../util';
import { pipeInspectionReportFields } from './types';

const assetModule = namespace('asset');

@Component({
  components: {
    ReportFooter,
    ReportObservation,
    PipeConditionReport,
  },
})
export default class PipeInspectionReport extends Vue {
  @assetModule.State('asset') asset!: AssetData;

  @assetModule.Action(AssetActions.FETCH_ASSET_DATA) fetchAssetData;

  @Prop({ default: true }) readonly displayImperial!: boolean;

  @Prop() codingDetail: InspectionResponse;

  @Prop() assetType: string;

  @Prop({ default: '' }) workOrderReport: string;

  @Prop({ default: null }) item: any;

  pipeInspectionReportFields: pipeInspectionReportFields | any = {};

  workOrderJson: any;

  // eslint-disable-next-line class-methods-use-this
  formatText(text: string): string {
    if (!text) return '';
    let newText = text;

    newText = newText.replace(/&gt;/g, '>');
    newText = newText.replace(/&lt;/g, '<');
    newText = newText.replace(/&quot;/g, '"');

    return newText;
  }

  getDisplayDistanceInMM(value: number): string {
    return value || value === 0
      ? util.getDisplayDistanceInMM(this.displayImperial, value)
          + util.getDistanceUnitsInMM(this.displayImperial)
      : '';
  }

  getDisplayDistanceFtM(value: number): string {
    return value || value === 0
      ? util.getDisplayDistanceFtM(this.displayImperial, value)
          + util.getDistanceUnitsFtM(this.displayImperial)
      : '';
  }

  mounted(): void {
    this.workOrderJson = JSON.parse(this.codingDetail.inspections[0].jsonData);
    // fill fields
    this.fillPipeInspectionReportFields();
    // this.fetchAsset();
    this.$forceUpdate();
  }

  fillPipeInspectionReportFields(): void {
    this.pipeInspectionReportFields = {
      name: this.item && this.item.nodes.name ? this.item.nodes.name : '',
      assignedTo: this.workOrderJson ? this.workOrderJson.SurveyedBy : '',
      route: '', // ???
      status: this.item && this.item.status ? this.item.status : '',
      workOrderNumber:
        this.item && this.item.workOrderNumber ? this.item.workOrderNumber : '',
      dateIssued: '', // ?
      completedBy: '', // ???
      workDescription: this.workOrderJson ? this.workOrderJson.Project : '',
      specialInstructions: '', // ?
      structUp: this.workOrderJson
        ? this.workOrderJson.UpStreamMH
        : '',
      structDown: this.workOrderJson
        ? this.workOrderJson.DNStreamMH
        : '',
      address: this.workOrderJson ? this.workOrderJson.Address : '',
      size: this.workOrderJson ? this.getDisplayDistanceInMM(this.workOrderJson.PipeSize_Width) : '',
      material: this.workOrderJson ? this.workOrderJson.PipeMaterial : '',
      length: this.workOrderJson ? this.getDisplayDistanceFtM(this.workOrderJson.PipeSize_Height) : '',
      comments: '', // ??
      dateInspected: this.workOrderJson ? this.workOrderJson.InspDate.slice(0, 10) : '',
      followUpComments: '', // ?
      workOrderType:
        this.item && this.item.taskTypeDefinition
          ? this.item.taskTypeDefinition
          : '',
    };
  }

  fetchAsset(): void {
    this.fetchAssetData({
      assetId: this.item.nodes.guid,
      inspectionId: this.item.guid,
    }).then(() => {
      this.$forceUpdate();
    });
  }

  get workOrderType(): string {
    if (!this.pipeInspectionReportFields?.workOrderNumber) {
      return '';
    }
    return this.pipeInspectionReportFields.workOrderNumber.split('-')[0];
  }
}
