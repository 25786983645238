








































































































































































































































/* eslint-disable object-curly-newline */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Component, Prop, Vue, PropSync, Watch,
} from 'vue-property-decorator';
import {
  PRIORITY_COLOR_LOOKUP, PRIORITY_LOW, TASK_TYPE_STRING,
} from '@/common/Constants';
import { AssignmentShort, RouteData } from '@/store/planning/types';
import IntegrityTable from '@/components/IntegrityTable/IntegrityTable.vue';
import { namespace } from 'vuex-class';
import { AssignmentActions } from '@/store/assignments/actions';
import { PROJECT_MANAGER, SUPER_USER } from '@/auth/roles';
import { UserData } from '@/store/users/types';
import { UserPermission } from '@/store/userpermissions/types';
import WorkOrderForm from './WorkOrderForm.vue';
import IntegrityDelete from '../IntegrityDelete/IntegrityDelete.vue';
import PlanningSchedule from './PlanningSchedule.vue';
import UserPermissionsMixin from '../UserPermissions/UserPermissionsMixin.vue';
import { AssignmentTaskType, commonTaskTypes, macpTaskTypes, pacpTaskTypes } from '../ScopeNavigation/NewAssignmentDialog/Constants';

const assignmentsModule = namespace('assignments');
const userModule = namespace('users');

export interface TaskTypeDisplay
{
  displayName: string,
  name: string,
}

@Component({
  components: {
    WorkOrderForm,
    PlanningSchedule,
    IntegrityTable,
    IntegrityDelete,
  },
})
export default class PlanningTable extends UserPermissionsMixin {
  @assignmentsModule.State('followUpLoading') followUpLoading;

  @assignmentsModule.State('followUpError') followUpError;

  @assignmentsModule.State('followUpWorkOrder') followUpWorkOrder;

  @assignmentsModule.Action(AssignmentActions.POST_FOLLOW_UP_WORK_ORDER) postFollowUpWorkOrder;

  @userModule.State('allUserData') allUsers: UserData[] | undefined;

  @Prop() readonly analyticsData: AssignmentShort[];

  @Prop() readonly loadingAnalyticsData: boolean;

  @Prop() readonly selectedCategoryIndex: number;

  @Prop() readonly validResourceGroups: any[];

  @Prop() readonly deleteLoading: boolean;

  @Prop() deleteAssignment;

  @PropSync('selectedAssetType') synchedSelectedAssetType: number;

  @PropSync('selectedItems') synchedSelectedItems: AssignmentShort[];

  dateFrom: string | undefined = undefined;

  dateTo: string | undefined = undefined;

  routeFilters = [];

  search = '';

  page = 1;

  pageCount = 0;

  selected = [];

  priorityColors = {};

  editDialog = false;

  isMACPLevel1 = true;

  editWorkOrderDialog = false;

  currentItem = null;

  canEdit = false;

  invalidSelectedForEditDialog = false;

  hasAppliedRouteParams = false;

  tableRules = [
    'td { height: 50px !important;}'];

  assetTypeOptions = [
    { name: 'Manhole', value: 'manhole' },
    { name: 'Line Segment', value: 'lineSegment' },
  ];

  headers = [
    { text: 'Work Order Number', value: 'workOrderNumber', align: 'start', filterable: true },
    { text: 'Asset Name', value: 'nodes.name', align: 'start', filterable: true },
    { text: 'Map Page', value: 'nodes.mapPage', align: 'start', filterable: true },
    { text: 'Status', value: 'status', align: 'start', filterable: true },
    { text: 'Task Type', value: 'taskTypeDefinition', align: 'start', filterable: true },
    { text: 'Task Result', value: 'taskResultDesc', align: 'start', filterable: true },
    { text: 'Parent Work Order', value: 'followUpRef', align: 'start', filterable: true },
    { text: 'Start Date', value: 'scheduledStartTime', align: 'start', filterable: true },
    { text: 'End Date', value: 'scheduledEndTime', align: 'start', filterable: true },
    { text: 'Scheduling', value: 'schedulingData', align: 'start', filterable: true },
    { text: 'Priority', value: 'priorityItemGuid', align: 'start', filterable: true },
    { text: 'Route', value: 'route', align: 'start', filterable: true },
    { text: 'Crew', value: 'crewLeadName', align: 'start', filterable: true },
    { text: 'Basin', value: 'basin', align: 'start', filterable: true },
    { text: 'Batch Number', value: 'batchNumber', align: 'start', filterable: true },
    { text: 'Actions', value: 'actions', align: 'start', sortable: false, class: 'sticky-end', cellClass: 'sticky-end' },
  ];

  matchFilters = [
    { header: 'workOrderNumber', type: 'string', value: '', method: '', options: [], tempValue: '', open: false },
    { header: 'nodes.name', type: 'string', value: '', method: '', options: [], tempValue: '', open: false, conversionFunction: this.nodeNameString },
    { header: 'nodes.mapPage', type: 'string', value: '', method: '', options: [], tempValue: '', open: false, conversionFunction: this.mapPageString },
    { header: 'status', type: 'string', value: '', method: '', options: [], tempValue: '', open: false },
    { header: 'taskTypeDefinition', type: 'string', value: '', method: '', options: [], tempValue: '', open: false },
    { header: 'scheduledStartTime', type: 'date', value: '', method: '', options: [], tempValue: '', open: false },
    { header: 'scheduledEndTime', type: 'date', value: '', method: '', options: [], tempValue: '', open: false },
    { header: 'priorityItemGuid', type: 'string', value: '', method: '', options: [], tempValue: '', open: false },
    { header: 'taskResultDesc', type: 'string', value: '', method: '', options: [], tempValue: '', open: false },
    { header: 'followUpRef', type: 'string', value: '', method: '', options: [], tempValue: '', open: false },
    { header: 'route', type: 'string', value: '', method: '', options: [], tempValue: '', open: false, conversionFunction: this.routeString },
    { header: 'schedulingData', type: 'string', value: '', method: '', options: [], tempValue: '', open: false, conversionFunction: this.schedulingString },
    { header: 'crewLeadName', type: 'string', value: '', method: '', options: [], tempValue: '', open: false, conversionFunction: this.getCrewMembers },
    { header: 'basin', type: 'string', value: '', method: '', options: [], tempValue: '', open: false },
    { header: 'batchNumber', type: 'number', value: '', method: '', options: [], tempValue: '', open: false },
  ]

  editItems = [];

  tableHeight: string | number = 'auto';

  today = new Date().toISOString();

  tabOptions = ['Manhole', 'Line Segment'];

  completedInitialLoad: boolean = null;

  ignoreQuery = false;

  followUpDialog = false;

  workOrderToFollowUp: AssignmentShort = null;

  selectedFollowUpTaskType = '';

  filterAssigned = false;

  filteredWorkOrders: AssignmentShort[];

  @Watch('loadingAnalyticsData')
  onLoadingAnalyticsData(): void {
    // initial load
    if (this.loadingAnalyticsData && this.completedInitialLoad === null) {
      this.completedInitialLoad = false;
      this.filteredWorkOrders = this.analyticsData;
      return;
    }
    if (!this.loadingAnalyticsData && this.completedInitialLoad === false) {
      this.filteredWorkOrders = this.analyticsData;
      this.completedInitialLoad = true;
    }
  }

  @Watch('synchedSelectedAssetType')
  onSynchedSelectedAssetTypeChange(): void {
    this.updateTable();
  }

  @Watch('selectedCategoryIndex')
  onSelectedCategoryIndexChange(): void {
    this.updateTable();
  }

  @Watch('filterAssigned')
  async filterAssignedToMe(): Promise<void> {
    const crewMatchFilter = this.matchFilters.find((match) => match.header === 'crewLeadName');
    if (this.filterAssigned) {
      const currentUser = await this.$auth.getUser();
      const currentUserData = this.allUsers.find((u) => u.email === currentUser.email);
      const currentUserName = `${currentUserData.firstname} ${currentUserData.lastname}`;
      crewMatchFilter.options = [currentUserName];
    } else {
      crewMatchFilter.options = [];
    }
  }

  @Watch('routeFilters')
  foundRouteFilters(): void {
    this.routeFilters.forEach((filter) => {
      let foundHeader = this.matchFilters.find((mf) => mf.header === filter.header);
      if (filter.header === 'assetName') {
        foundHeader = this.matchFilters.find((mf) => mf.header === 'nodes.name');
      }
      if (filter.header === 'taskType') {
        foundHeader = this.matchFilters.find((mf) => mf.header === 'taskTypeDefinition');
      }
      if (filter.header === 'issue') {
        foundHeader = this.matchFilters.find((mf) => mf.header === 'taskResultDesc');
      }
      if (foundHeader) {
        foundHeader.options = filter.options;
        foundHeader.value = filter.value;
        foundHeader.method = filter.method;
      }
    });
  }

  get filterNameHeader(): string {
    let filterNameHeader = 'All';
    switch (this.selectedCategoryIndex) {
      case 0:
        filterNameHeader = 'Unassigned';
        break;
      case 1:
        filterNameHeader = 'Pending';
        break;
      case 2:
        filterNameHeader = 'Completed';
        break;
      case 3:
        filterNameHeader = 'Incomplete';
        break;
      default:
        break;
    }
    return filterNameHeader;
  }

  get filterValues(): unknown {
    if (this.analyticsData.length <= 0) return {};

    const retVal = {};

    this.analyticsData.forEach((asset) => {
      this.headers.forEach((header) => {
        if (header.value === 'schedulingData') return;

        let checkVal: unknown;

        if (!retVal[header.value]) retVal[header.value] = [];

        try {
          checkVal = asset[header.value];
        } catch {
          checkVal = '';
        }
        if (header.value === 'route') {
          checkVal = (checkVal as RouteData)?.routeName;
        }

        if (header.value === 'nodes.name') {
          checkVal = asset.nodes['name'];
        }

        if (header.value === 'nodes.mapPage') {
          checkVal = asset.nodes.mapPage;
        }

        if (header.value === 'crewLeadName' && asset.workerNames) {
          asset.workerNames.forEach((element) => {
            if (
              element
              && element !== ''
              && Array.isArray(retVal[header.value])
              && !retVal[header.value].includes(element)
            ) {
              retVal[header.value].push(element);
            }
          });
        } else if (
          checkVal
          && checkVal !== ''
          && Array.isArray(retVal[header.value])
          && !retVal[header.value].includes(checkVal)
        ) {
          retVal[header.value].push(checkVal);
        }

        retVal[header.value].sort();
      });
    });

    return retVal;
  }

  get invalidSelectedForEdit(): boolean {
    if (this.synchedSelectedItems.length === 0) {
      return false;
    }

    const firstNode = this.synchedSelectedItems[0];
    return !this.synchedSelectedItems.every((node) => (
      node.scheduledStartTime === firstNode.scheduledStartTime
      && node.scheduledEndTime === firstNode.scheduledEndTime
    ));
  }

  get taskTypes(): TaskTypeDisplay[] {
    const currentAssetTaskTypes = this.synchedSelectedAssetType === 0
      ? macpTaskTypes : pacpTaskTypes;
    const mappedAssetTaskTypes = currentAssetTaskTypes
      .map((x) => this.createTaskTypeDisplay(x)).flat();
    const mappedCommonTaskTypes = commonTaskTypes.map((x) => this.createTaskTypeDisplay(x)).flat();
    const currentTaskTypes = mappedAssetTaskTypes.concat(mappedCommonTaskTypes);
    const typeTaskDesc = TASK_TYPE_STRING.map((tts) => tts.desc);
    return currentTaskTypes.filter((tt) => typeTaskDesc.includes(tt.name));
  }

  createTaskTypeDisplay(taskType: AssignmentTaskType): TaskTypeDisplay | TaskTypeDisplay[] {
    if (taskType.subValue) {
      // concatenate the subvalue with the main value to make it more readable
      return taskType.subValue
        .map((sub) => ({ displayName: `${taskType.name} ${sub.name}`, name: sub.name }));
    }
    return { displayName: taskType.name, name: taskType.name };
  }

  async mounted(): Promise<void> {
    if (this.$auth.user.id !== null) {
      const roles = await this.$auth.getRoles(`auth0|${this.$auth.user.id}`);
      const isPM = roles.includes(PROJECT_MANAGER) || roles.includes(SUPER_USER);
      if (!isPM) {
        this.headers = this.headers.filter((header) => header.value !== 'crewLeadName');
      }
      this.filteredWorkOrders = this.analyticsData;
    }
    await this.getRouteParams();
  }

  getRouteParams(): void {
    const route = this.$route;
    if (Object.keys(route.query).length > 0) {
      const queries = Object.entries(route.query);
      const filterHeaders = this.matchFilters.map((mf) => mf.header);
      queries.forEach((pair) => {
        const [key, value] = pair;

        if (key === 'name') {
          if (Array.isArray(value)) {
            this.matchFilters.find((mf) => mf.header === 'nodes.name').options.push(...value);
          } else {
            this.matchFilters.find((mf) => mf.header === 'nodes.name').options.push(value);
          }
        } else if (filterHeaders.includes(key)) {
          this.matchFilters.find((mf) => mf.header === key).options.push(value);
        } else if (key === 'matchFilters') {
          const updatedValue = decodeURI(value as string);
          this.routeFilters = JSON.parse(updatedValue);
        } else if (key === 'guid') {
          const foundWorkOrder = this.analyticsData.filter((ad) => ad.guid === value);
          if (foundWorkOrder.length > 0) {
            this.filteredWorkOrders = foundWorkOrder;
          }
        } else if (key === 'taskResult') {
          const taskdec: string[] = JSON.parse((decodeURI(value as string)));

          const notempty = taskdec.filter((td) => td !== '');

          const foundFilter = this.matchFilters.find((mf) => mf.header === 'taskResultDesc');

          foundFilter.options = foundFilter.options.concat(notempty);
        } else if (key === 'assetType' && !this.hasAppliedRouteParams) {
          if (value === 'LineSegments') {
            this.synchedSelectedAssetType = 1;
          } else {
            this.synchedSelectedAssetType = 0;
          }
        } else if (key === 'dateBefore') {
          this.dateFrom = value as string;
        } else if (key === 'dateAfter') {
          this.dateTo = value as string;
        }
      });
      if (this.dateFrom && this.dateTo) {
        this.filteredWorkOrders.filter(
          (ai) => new Date(ai.deploymentDate).setHours(0, 0, 0, 0)
          >= new Date(this.dateFrom).getTime()
          && new Date(ai.deploymentDate).setHours(0, 0, 0, 0) <= new Date(this.dateTo).getTime(),
        );
      }
    }
    this.hasAppliedRouteParams = true;
  }

  getDataStandard(item: AssignmentShort): void {
    this.currentItem = item;
    this.editWorkOrderDialog = true;
  }

  getPriorityColor(priorityGUID: string): string {
    return PRIORITY_COLOR_LOOKUP[priorityGUID];
  }

  async removeSelectedAssignmentItems(): Promise<void> {
    await this.removeAssignmentItem(this.synchedSelectedItems);
  }

  async removeAssignmentItem(items: AssignmentShort[]): Promise<void> {
    const deleteItemGuids = items.map((item) => item.guid);
    await this.deleteAssignment(deleteItemGuids);
    this.$emit('removeAssignment', deleteItemGuids);
  }

  async resetItems(items: AssignmentShort[]): Promise<void> {
    this.editDialog = false;

    const dataList: any[] = [];

    items.forEach((node: any) => {
      const data = {
        guid: node.guid,
        project_guid: node.projectGuid,
        priority_item: {},
        scheduled_start_date: null,
        scheduled_end_date: null,
        notes: '',
        scheduled_start_date_null: true,
        scheduled_end_date_null: true,
        crewLead: [],
        crew: [],
        status_item: { guid: '8a0c7f4c-a384-4fc7-9801-1bedd6d3744c' }, // Not Ready status
      };

      const priority = {
        guid: PRIORITY_LOW,
        category: {
          guid: 'cf12207e-9707-11ea-9cfe-6f062ca617ff',
          name: 'Priority',
        },
        description: 'Low',
        short_name: 'LOW',
        order: 1,
      };

      data.priority_item = priority;
      dataList.push(data);
    });

    this.canEdit = false;
    this.$emit('reset', {
      emails: [],
      requestList: dataList,
    });
  }

  editAssignments(): void {
    if (this.invalidSelectedForEdit) {
      this.invalidSelectedForEditDialog = true;
      return;
    }
    this.canEdit = !this.canEdit;
    this.editItems = this.synchedSelectedItems;
    this.editDialog = true;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  editAssignmentItem(editAssignment: any, shouldEditMACPLevels: boolean): void {
    this.$emit('editAssignmentItem', editAssignment, shouldEditMACPLevels);
  }

  editWorkOrderPass(): void {
    this.editWorkOrderDialog = false;
    this.$emit('editWorkOrderPass');
  }

  closeWorkOrder(): void {
    this.editWorkOrderDialog = false;
  }

  handleEdit(itemGuid: string): void {
    this.editItems = [this.analyticsData.find(
      (item) => item['guid'] === itemGuid,
    )];
    this.editDialog = true;
  }

  handleInfo(itemGuid: string): void {
    const infoItem = this.analyticsData.find(
      (item) => item['guid'] === itemGuid,
    );
    this.getDataStandard(infoItem);
  }

  handleAdd(itemGuid: string): void {
    this.workOrderToFollowUp = this.analyticsData.find((wo) => wo.guid === itemGuid);
    this.selectedFollowUpTaskType = '';
    this.followUpDialog = true;
  }

  clearAllFilters(clearQuery = false): void {
    (this.$refs.workOrderTable as IntegrityTable).clearAllMatchFilters();
    if (clearQuery) {
      this.ignoreQuery = true;
    }

    this.dateFrom = undefined;
    this.dateTo = undefined;
  }

  resetEdit(): void {
    this.canEdit = false;
    this.synchedSelectedItems = [];
  }

  async submit(data: unknown): Promise<void> {
    this.editDialog = false;
    this.$emit('submit', data);
  }

  cancel(): void{
    this.editDialog = false;
    this.canEdit = false;
    this.$emit('cancel');
  }

  getRouteColor(item: RouteData): string | undefined {
    const hexReg = /^#([0-9a-f]{3,4}){1,2}$/i;
    if (item.routeColor == null || item.routeColor === '') {
      return '#FFFFFFFF';
    }
    if (hexReg.test(item.routeColor)) {
      return item.routeColor;
    } if (hexReg.test(`#${item.routeColor}`)) {
      return `#${item.routeColor}`;
    }
    return null;
  }

  getTextColor(textBackgroundColor: string): string {
    const color = (textBackgroundColor.charAt(0) === '#') ? textBackgroundColor.substring(1, 7) : textBackgroundColor;
    const r = parseInt(color.substring(0, 2), 16); // hexToR
    const g = parseInt(color.substring(2, 4), 16); // hexToG
    const b = parseInt(color.substring(4, 6), 16); // hexToB
    return (((r * 0.299) + (g * 0.587) + (b * 0.114)) > 186)
      ? '#000000' : '#FFFFFF';
  }

  routeString(item: AssignmentShort): string {
    const { route } = item;
    if (route == null || route.routeName == null || route.routeName === '') {
      return '';
    }
    return route.routeName;
  }

  mapPageString(item: AssignmentShort): string {
    const { nodes } = item;
    if (nodes == null || nodes.mapPage == null || nodes.mapPage === '') {
      return '';
    }
    return nodes.mapPage;
  }

  nodeNameString(item: AssignmentShort): string {
    const { nodes } = item;
    if (nodes == null || nodes['name'] == null) {
      return '';
    }
    return nodes['name'];
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  schedulingString(item: any): string {
    if (item?.schedulingData === null) {
      return '';
    }
    try {
      return `Every ${item.schedulingData.interval} ${item.schedulingData.period} starting on ${item.schedulingData.startDate.toString().substring(0, 10)}`;
    } catch {
      return '';
    }
  }

  getCrewMembers(item: AssignmentShort): any {
    return item.workerNames;
  }

  async submitFollowUp(): Promise<void> {
    const taskTypeGuid = TASK_TYPE_STRING.find((tts) => tts.desc === this.selectedFollowUpTaskType);
    const followUpPayload = {
      parentWorkOrderGuid: this.workOrderToFollowUp.guid,
      taskTypeGuid: taskTypeGuid.guid,
    };
    await this.postFollowUpWorkOrder(followUpPayload);

    const refreshMsg = {
      followUpWorkOrder: this.followUpWorkOrder,
      isSuccessful: !this.followUpError,
    };

    if (refreshMsg.isSuccessful) {
      this.completedInitialLoad = false;
    }

    await this.$emit('refreshFollowUpWorkOrder', refreshMsg);
    this.closeFollowUpDialog();
  }

  updateTable(): void {
    this.clearAllFilters();
    if (!this.ignoreQuery) this.getRouteParams();
    this.filteredWorkOrders = this.analyticsData;
  }

  closeFollowUpDialog(): void {
    this.followUpDialog = false;
  }

  /**
  * @returns true if the user has the permission WORK_ORDER_RESET_PLANNING
  */
  get hasPermissionWorkOrderResetPlanning(): boolean {
    return this.hasPermission(UserPermission.WORK_ORDER_RESET_PLANNING);
  }

  /**
  * @returns true if the user has the permission WORK_ORDER_DELETE
  */
  get hasPermissionWorkOrderDelete(): boolean {
    return this.hasPermission(UserPermission.WORK_ORDER_DELETE);
  }
}
