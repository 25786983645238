import { render, staticRenderFns } from "./CrewDashboardOverview.vue?vue&type=template&id=0fe28e7a&scoped=true&"
import script from "./CrewDashboardOverview.vue?vue&type=script&lang=ts&"
export * from "./CrewDashboardOverview.vue?vue&type=script&lang=ts&"
import style0 from "./CrewDashboardOverview.vue?vue&type=style&index=0&id=0fe28e7a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0fe28e7a",
  null
  
)

export default component.exports