
























































import { BasicSelect } from 'vue-search-select';
import { AdminActions } from '@/store/admin/actions';
import { ProjectActions } from '@/store/project/actions';
import { ProjectListing } from '@/store/project/types';
import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const adminModule = namespace('admin');
const projectModule = namespace('project');

@Component({
  components: {
    BasicSelect,
  },
})
export default class EditUsers extends Vue {
  @adminModule.State('response') adminResponse;

  @adminModule.State('loadError') adminLoadError;

  @adminModule.Action(AdminActions.PATCH_USER) patchUser;

  @projectModule.Action(ProjectActions.FETCH_ALL_PROJECTS_DATA)
  fetchAllProjectsData;

  @projectModule.State('allProjectsData') allProjects:
    | ProjectListing[]
    | undefined;

  @projectModule.State('loadError') loadError;

  @projectModule.State('loading') loading;

  @Prop() readonly selectedUsers: any[];

  projects: Array<{ value: number; text: string; guid: string }> = [];

  snack = false;

  snackBarMessage = '';

  snackColor = 'black';

  selectedProject = { value: 0, text: '', guid: '' } as {
    value: number;
    text: string;
    guid: string;
  };

  editUsersDialog = false as boolean;

  valid = false as boolean;

  requiredField = [(v) => !!v || v === 0 || 'This is a required field.'];

  @Watch('allProjects')
  allProjectsChange(): void {
    this.projects = [];
    if (this.allProjects != null && this.allProjects.length > 0) {
      this.allProjects.forEach((proj, i) => {
        this.projects.push({
          value: i,
          text: proj.name,
          guid: proj.guid,
        });
      });
      // eslint-disable-next-line prefer-destructuring
      this.selectedProject = this.projects[0];
    }

    this.$forceUpdate();
  }

  async mounted(): Promise<void> {
    this.fetchAllProjectsData().catch(() => {
      this.$router.push({
        name: 'Error',
        params: { catchAll: 'Error', message: 'There was an error retrieving your projects. Please try again later. If this issue persists, please contact support.' },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
      }).catch((e) => {});
    });
  }

  async submit(): Promise<void> {
    try {
      this.selectedUsers.forEach(async (user) => {
        if (user.detailedUserData.role.find(
          (role) => role.projectGuid === this.selectedProject.guid,
        ) == null) {
          user.detailedUserData.role.push({
            projectGuid: this.selectedProject.guid,
            roleGuid: user.detailedUserData.role[0].roleGuid,
            startDate: null,
            endDate: null,
          });
          await this.patchUser({
            Guid: user.detailedUserData.guid,
            Firstname: user.detailedUserData.firstname,
            Lastname: user.detailedUserData.lastname,
            nickname: user.detailedUserData.nickname,
            userRoleObjects: user.detailedUserData.role,
            nasscoId: user.detailedUserData.nasscoid,
          });
        }
      });
      this.snackColor = 'green';
      this.snackBarMessage = 'Project Added';
      this.snack = true;
    } catch (ex) {
      this.snackBarMessage = (ex as Error).message;
      this.snackColor = '#e61e25';
      this.snack = true;
      return;
    }
    this.$forceUpdate();
    await this.resetForm();
    this.editUsersDialog = false;
  }

  async resetForm(): Promise<void> {
    this.selectedProject = null;
    await (this.$refs.editUsersForm as HTMLFormElement).resetValidation();
  }
}
