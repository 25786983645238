


























































































































































































































































































































































































/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Component, Prop, Vue, Watch, Mixins,
} from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import SideDrawer from '@/components/SideDrawer/SideDrawer.vue';
import { EventBus } from '@/common/EventBus';
import { ExportActions } from '@/store/reportExports/actions';
import { ExportPayload } from '@/store/reportExports/types';
import { CrossSectionsActions } from '@/store/crossSections/actions';
import { CrossSectionData, MSIInspectionData } from '@/store/crossSections/types';
import CodingForm from '@/components/CodingForm/CodingForm.vue';
import { CodingFormData, InspectionResponse } from '@/components/CodingForm/types';
import { REPORTING_TECH_AUTH0, SUPER_USER, SYS_ADMIN_AUTH0 } from '@/auth/roles';
import { DefectCodingAction } from '@/store/defectCoding/actions';
import { InspectionActions } from '@/store/inspection/actions';
import ReportingFeedbackPopout from '@/components/ReportingFeedbackPopout/ReportingFeedbackPopout.vue';
import { UserActions } from '@/store/users/actions';
import { DefectPanTiltZoom } from '@/store/defectCoding/types';
import UserPermissionsMixin from '@/components/UserPermissions/UserPermissionsMixin.vue';
import { AssetMutations } from '../../store/asset/mutations';
import Report from '../Report/Report.vue';
import { ReportType } from '../../types';
import DefectGraph from '../../components/DefectGraph/DefectGraph.vue';
import DefectTable from '../../components/DefectTable/DefectTable.vue';
import DataReport from '../../components/DataReport/DataReport.vue';
import Media from '../../components/Media/Media.vue';
import AssetSelector from '../../components/AssetSelector/AssetSelector.vue';
import { Marker, MSIDefectGraphState } from '../../components/DefectGraph/types';
import {
  AssetData, InspectionData, IPDEntry, URLDefect,
} from '../../store/asset/types';
import { AssetActions } from '../../store/asset/actions';
import mediaUtils from './utils';
import MSIGraph from '../../components/MSIGraph/MSIGraph.vue';
import MSICrossSection from '../../components/MSICrossSection/MSICrossSection.vue';

// eslint-disable-next-line no-shadow
export enum CodingLevel{
    MACPLEVEL1 = 'ea5518e0-2963-11ed-a65b-77d84cf14bcb',
    MACPLEVEL2 = 'ea614de0-2963-11ed-a65b-7bb47ad3378c',
    PACP = '97447908-eec0-11ea-82f7-9ffdfcec61ed',
}

const assetModule = namespace('asset');
const inspectionModule = namespace('inspection');
const exportModule = namespace('reportExports');
const crossSectionModule = namespace('crossSections');
const defectCodingModule = namespace('defectCoding');
const userModule = namespace('users');

@Component({
  components: {
    CodingForm,
    DefectGraph,
    DefectTable,
    DataReport,
    Media,
    AssetSelector,
    Report,
    SideDrawer,
    MSIGraph,
    MSICrossSection,
    ReportingFeedbackPopout,
  },
})
export default class Asset extends Mixins(Vue, UserPermissionsMixin) {
  @Prop() readonly idList!: string;

  @Prop() readonly id!: string;

  @assetModule.State('inspection') inspectionData: InspectionData | undefined =
  undefined;

  @assetModule.State('asset') asset!: AssetData;

  @inspectionModule.State('codingDetail') codingDetail!: InspectionResponse;

  @inspectionModule.State('fullCodingForm') fullCodingForm: CodingFormData[] | undefined;

  @inspectionModule.State('subFullCodingForm') subFullCodingForm: any[] | undefined;

  @assetModule.State('loadError') loadError: string | undefined = undefined;

  @assetModule.State('loading') loading: boolean | undefined = undefined;

  @assetModule.State('hudEnabled') hudEnabled: boolean | undefined = undefined;

  @assetModule.Action(AssetActions.FETCH_ASSET_DATA) fetchAssetData;

  @inspectionModule.Action(InspectionActions.FETCH_CODING_DETAIL) fetchCodingDetail;

  @inspectionModule.Action(InspectionActions.SET_CODING_FORM) setCodingForm;

  @inspectionModule.Action(InspectionActions.SET_SUB_CODING_FORM) setSubCodingForm;

  @assetModule.Mutation(AssetMutations.SET_INSPECTION) setInspection;

  @exportModule.State('data') exportResult: string | undefined;

  @exportModule.State('loadError') exportError: string;

  @exportModule.State('loading') exporting;

  @exportModule.Action(ExportActions.POST_INSPECTION_REPORTS) postInspections;

  @exportModule.Action(ExportActions.CLEAR_EXPORT_DATA) clearExport;

  @defectCodingModule.Action(DefectCodingAction.GET_DEFECT_CODING_DATA) getDefectCodingData;

  @crossSectionModule.Action(CrossSectionsActions.GET_CROSS_SECTIONS) getCrossSection;

  @crossSectionModule.State('data') crossSectionData: CrossSectionData[] | undefined;

  @crossSectionModule.Action(CrossSectionsActions.GET_DEFECT_DATA) getMSIDefectData;

  @crossSectionModule.State('defectData') MSIDefectData: MSIInspectionData | undefined;

  @userModule.Action(UserActions.FETCH_ALL_DETAILED_USER_DATA) fetchAllDetailedUserData;

  defectState = MSIDefectGraphState.ORIGINAL;

  assetGuidList: string[] = [];

  currentVideoTime = 0.0 as number;

  payoutData = 0.0;

  showReportDialog = false as boolean;

  displayReportingErrorPopop = false as boolean;

  currentMarker: Marker | undefined;

  currentVideoPercent = 0.0 as number;

  currentReportData: Record<string, unknown | undefined> = {};

  showNavMap = false as boolean;

  drawerOpen = false as boolean;

  isExported = false as boolean;

  currentQuickMenuComponent = undefined as unknown as Vue.Component;

  currentQuickMenuProps = {} as any;

  fullVideoTime = -1;

  isMSIView = false;

  msiLayout = '';

  isWM = false;

  openCoding = false;

  fetchingCodingDetail = false;

  defectToGoTo: URLDefect = {
    inspectionGuid: '',
    code: '',
  };

  msiDefectGraphStates = [
    MSIDefectGraphState.ORIGINAL,
    MSIDefectGraphState.MSI,
    MSIDefectGraphState.ALL,
  ]

  currentUserRoles: string[] = [];

  selectedVideoOption = 'Video 360';

  popOutOpen = false;

  popup: Window = null;

  isDefectGraphValid = false;

  showErrorDialog = false;

  errorsList = [];

  invalidIPD = false;

  panTiltZoom: DefectPanTiltZoom = {
    pan: 0,
    tilt: 0,
    zoom: 0,
  }

  /**
   * @returns true if the user has the permission INSPECTION_VIEWER_FEEDBACK
   */
  get hasPermissionInspectionViewerFeedback(): boolean {
    return this.hasPermission(this.permissions.INSPECTION_VIEWER_FEEDBACK);
  }

  /**
   * @returns true if the user has the permission INSPECTION_VIEWER_REPORT_EXPORT
   */
  get hasPermissionInspectionViewerReportExport(): boolean {
    return this.hasPermission(this.permissions.INSPECTION_VIEWER_REPORT_EXPORT);
  }

  /**
   * @returns true if the user has the permission INSPECTION_VIEWER_VALIDATE_SCORE
   */
  get hasPermissionInspectionViewerValidateScore(): boolean {
    return this.hasPermission(this.permissions.INSPECTION_VIEWER_VALIDATE_SCORE);
  }

  /**
   * @returns true if the user has the permission INSPECTION_VIEWER_EDIT_CODES
   */
  get hasPermissionInspectionViewerEditCodes(): boolean {
    return this.hasPermission(this.permissions.INSPECTION_VIEWER_EDIT_CODES);
  }

  /**
   * @returns true if the user has the permission INSPECTION_VIEWER_COMMON
   */
  get hasPermissionInspectionViewerCommon(): boolean {
    return this.hasPermission(this.permissions.INSPECTION_VIEWER_COMMON);
  }

  get displayImperial(): boolean {
    if (!this.inspection || !this.inspection['isImperial']) {
      return true;
    }
    return this.inspection['isImperial'];
  }

  get reportType(): ReportType {
    if (this.assetArray.length > 0) {
      switch (this.assetArray[0].type) {
        case 'Line Segment':
          return ReportType.Observation;
        case 'Manhole':
          return ReportType.MACP;
        default:
          return ReportType.Other;
      }
    }
    return ReportType.Other;
  }

  get assetArray(): AssetData[] {
    if (this.asset) {
      // Not sure what this does, but it breaks stuff if it's already jsonified
      if ((typeof this.asset.attributes as string) !== 'object') {
        this.asset.attributes = JSON.parse(
          (this.asset.attributes as any).replace(/&quot;/g, '"'),
        );
      }
      return [this.asset];
    }
    return [];
  }

  get inspection(): InspectionData | undefined {
    return this.inspectionData;
  }

  set inspection(insp: InspectionData | undefined) {
    if (!insp) return;

    // Set the current inspection
    this.setInspection(insp);
    this.$router.push({ query: { inspection: insp.guid } });

    // Setting payout will also update query string
    this.payout = 0.0;

    // this.$router.go(0);
  }

  inspectionSelectDisplay(insp: InspectionData): string {
    if (this.asset.type === 'Line Segment') {
      let { direction } = insp.report['generalInformation'] as any;
      direction = direction ?? '';
      return `${insp.inspectionDate} ${direction}`;
    }
    return `${insp.inspectionDate}`;
  }

  get usableGoToDefect(): URLDefect | undefined {
    return this.defectToGoTo?.inspectionGuid && this.defectToGoTo?.code
      ? { ...this.defectToGoTo } : undefined;
  }

  get payout(): number {
    return this.payoutData;
  }

  set payout(p: number) {
    this.payoutData = p;
  }

  get title(): string {
    return `${this.asset?.project.city}, ${this.asset?.project.state}`;
  }

  get name(): string {
    return `${this.asset?.name}`;
  }

  get ipd(): IPDEntry[] {
    // set to either payoutipd or wmpayoutipd if it's available
    if (this.inspectionData !== undefined) {
      return this.isWM ? this.inspectionData.wmPayoutIPD : this.inspectionData.payoutIPD;
    }
    return [];
  }

  get isValidReporter(): boolean {
    if (this.currentUserRoles === null && this.currentUserRoles.length === 0) return false;
    return this.currentUserRoles.includes(REPORTING_TECH_AUTH0);
  }

  get isReporterOrPM(): boolean {
    return this.isValidReporter
    || this.currentUserRoles.includes(SUPER_USER)
    || this.currentUserRoles.includes(SYS_ADMIN_AUTH0);
  }

  get videoOptions(): string[] {
    const retArray = [];
    if (this.inspection.video != null && this.inspection.video !== '') retArray.push('Video 360');
    if (this.inspection.wmVideo != null && this.inspection.wmVideo !== '') retArray.push('Raw Front');
    return retArray;
  }

  get rightCardClass(): string {
    return this.isMSIView ? 'padding-10' : '';
  }

  async mounted(): Promise<void> {
    // Fixes a test, not sure if idList will ever not be parsable in the real world
    try {
      this.assetGuidList = (JSON.parse(this.idList) as string[]);
    } catch {
      console.error('Unable to parse idList in Asset.vue');
    }

    if (!this.hasPermissionInspectionViewerCommon) {
      this.goToErrorPage();
    }

    EventBus.$on('openQuickMenu', (component, props) => {
      this.currentQuickMenuComponent = component;
      this.currentQuickMenuProps = props;
      this.drawerOpen = true;
    });

    if (this.$auth.user.id != null) {
      this.currentUserRoles = await this.getRoles();
    }

    await this.fetchAllDetailedUserData();
    this.getRouteParams();
  }

  // Whenever the id changes we need to initite the following
  @Watch('id', { immediate: true })
  onAssetIdChanged(id: number): void {
    // Hide the selection map if the id changes.
    this.showNavMap = false;
    const assetId = id;
    const inspectionId = this.$route.query.inspection;

    // Initiate a fetch of the project data
    this.fetchAssetData({ assetId, inspectionId }).then(async () => {
      let codingLevel: CodingLevel = CodingLevel.MACPLEVEL1;
      if (this.asset.type === 'Manhole') {
        if (this.inspection.macpLevel === 'Level 2') {
          codingLevel = CodingLevel.MACPLEVEL2;
        } else {
          codingLevel = CodingLevel.MACPLEVEL1;
        }
      } else if (this.asset.type === 'Line Segment') {
        codingLevel = CodingLevel.PACP;
      }
      this.getDefectCodingData(codingLevel);
      if (this.inspection == null || this.inspection.payoutIPD == null) {
        this.$router.push({
          name: 'Error',
          params: { catchAll: 'Error', message: 'No Detailed Asset Information Found' },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        }).catch((e) => {});
      } else if (this.inspection.msiExist) {
        this.getCrossSection(this.inspection.guid);
        this.getMSIDefectData(this.inspection.guid);
      }
    }).catch(() => {
      this.$router.push({
        name: 'Error',
        params: { catchAll: 'Error', message: 'No Detailed Asset Information Found' },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      }).catch((e) => {});
    });
  }

  // When we get a message back stop the export animation and clear the data
  @Watch('exportResult')
  onExportResult(): void {
    if (this.exportResult != null && this.exportResult !== '') {
      this.isExported = true;
      // snackbar being rendered so apply correct classes to it
      const snackBar = document.getElementsByClassName(
        'v-snack__wrapper',
      )[0] as HTMLElement;
      snackBar.className += ' downloadBar';
      window.open(this.exportResult, '_blank');
    }
  }

  @Watch('exportError')
  onExportError(): void {
    if (this.exportError != null) {
      this.errorsList = this.exportError.split(' / ');
      if (this.errorsList[0].includes('MainInspection')) this.errorsList.shift();

      this.showErrorDialog = true;
      // snackbar being rendered so apply correct classes to it
      const snackBar = document.getElementsByClassName(
        'v-snack__wrapper',
      )[0] as HTMLElement;
      snackBar.className += ' downloadBar';
    }
  }

  @Watch('isMSIView')
  onMSIViewChange(): void {
    this.msiLayout = this.isMSIView ? 'layout-grid-1' : '';
  }

  @Watch('fullVideoTime')
  onFullVideoTimeChange(): void {
    if (Math.abs(mediaUtils.getMaxDepth(this.inspection.payoutIPD)
     - mediaUtils.getMaxDepth(this.inspection.payoutIPD, this.fullVideoTime)) > 1) {
      this.invalidIPD = true;
    }
    this.invalidIPD = false;
  }

  onTrackPercentageChange(e: number): void {
    if (!this.inspection || this.maxDepth < 0) return;
    const payout = (e / 100.0) * this.maxDepth;
    this.payout = payout;
  }

  onPayoutRequested(val: number): void {
    this.payout = val;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (this.$refs.media as any).changeCurrentTime(
      mediaUtils.getClosestTime(this.ipd, val, 0) / 1000,
    );
  }

  onPayoutRequestIpd(val: IPDEntry): void {
    this.payout = val.payout;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (this.$refs.media as any).changeCurrentTime(
      mediaUtils.getClosestTime(this.ipd, val.payout / 100, val.timeMS) / 1000,
    );
  }

  onMarkerSelected(m: Marker): void {
    if (this.currentMarker === m) return;
    this.currentMarker = m;
  }

  onMarkerClicked(m: Marker): void {
    const reportData = m.getReportData();
    this.onDefectClicked(reportData);
  }

  onDefectClicked(report: Record<string, unknown>): void {
    this.panTiltZoom = {
      pan: report.v360CamPanAngle as number,
      tilt: report.v360CamTiltAngle as number,
      zoom: report.v360CamZoomAngle as number,
    };
  }

  async openReport(): Promise<void> {
    const drawerProps = {
      assetId: this.asset.guid,
      inspectionId: this.inspection?.guid,
      displayImperial: this.displayImperial,
      reportType: this.reportType,
      msiExist: this.inspection.msiExist,
      fullVideoTime: this.fullVideoTime,
      canExport: this.hasPermissionInspectionViewerReportExport,
    };
    EventBus.$emit('openQuickMenu', Report, drawerProps);
  }

  async openForm(): Promise<void> {
    this.fetchingCodingDetail = true;
    if (this.popOutOpen && this.popup !== null) {
      this.popOutOpen = false;
      this.popup.close();
      this.popup = null;
    }
    await this.fetchCodingDetail(this.inspection.guid);
    this.fetchingCodingDetail = false;
    const drawerProps = {
      assetId: this.asset.guid,
      inspectionId: this.inspection.guid,
      assetType: this.asset.type,
      hasModel: !!this.inspection.model?.model,
      maxDepth: this.maxDepth,
      codingDetail: this.codingDetail,
      canEdit: this.hasPermission(this.permissions.INSPECTION_VIEWER_NASSCO_EDIT),
    };
    EventBus.$emit('openQuickMenu', CodingForm, drawerProps);
  }

  openReportIssue(): void {
    this.displayReportingErrorPopop = true;
  }

  macpExport(): void {
    const inspectionGuids: string[] = [];
    this.asset.inspections.forEach((inspection) => {
      if (inspectionGuids.indexOf(inspection.guid) === -1) {
        inspectionGuids.push(inspection.guid);
      }
    });
    const exportPayload: ExportPayload = {
      projectGuid: this.asset.project.guid,
      inspections: inspectionGuids,
    };
    this.postInspections(exportPayload);
  }

  beginExport(): void {
    this.macpExport();
  }

  exportComplete(): void {
    this.isExported = false;
    this.clearExport();
  }

  updateFullVideoTime(value: number): void {
    this.fullVideoTime = value;
  }

  updateCurrentVideoTime(value: number): void {
    this.currentVideoTime = value;
  }

  updateCurrentVideoPercent(value: number): void {
    this.currentVideoPercent = value;
  }

  // eslint-disable-next-line class-methods-use-this
  get maxDepth(): number {
    if (this.inspection == null || this.ipd == null || this.ipd.length === 0) {
      const lastIndex = this.inspectionData.conditionReport.length - 1;
      if (this.inspectionData.conditionReport.length <= 0) {
        return 0;
      }
      return this.inspectionData.conditionReport[lastIndex].distance;
    }
    return mediaUtils.getMaxDepthReached(this.inspectionData, this.fullVideoTime);
  }

  timeChange(distance: number, time: number): void{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (this.$refs.media as any).timeChange(distance, time);
  }

  setLayout(val: string): void {
    this.msiLayout = val;
  }

  async screenshot(): Promise<void> {
    await this.$refs.assetSelector['screenshot']();
  }

  get reportFeedbackData(): string | undefined {
    if (!this.asset.reportFeedback?.issues) {
      return undefined;
    }

    const reportArray = this.asset.reportFeedback.issues;

    if (this.asset.reportFeedback.customResponse != null) {
      reportArray.push(this.asset.reportFeedback.customResponse);
    }

    if (reportArray.length === 0) {
      return undefined;
    }
    return reportArray.join(', ');
  }

  toggleTable(tableState: boolean): void {
    // if the table is open and we're in the msi view make the video a smol boy
    const msiVid = document.getElementById('msi-cctv') as HTMLElement;
    const defectGraph = document.getElementById('asset-layout-left') as HTMLElement;
    if (tableState) {
      msiVid.style.height = '100%';
      defectGraph.style.maxHeight = 'calc(79vh - 220px)';
    } else {
      msiVid.style.height = '100%';
      defectGraph.style.maxHeight = 'calc(100vh - 183px)';
    }
  }

  async getRoles(): Promise<string[]> {
    return this.$auth.getRoles(`auth0|${this.$auth.user.id}`);
  }

  togglePopout(): void {
    this.popOutOpen = true;
    if (this.popOutOpen && this.popup === null) {
      this.drawerOpen = false;
      this.popup = window.open(`${window.location.origin}/codingForm`, '', 'width=1200,height=1000');
      window.addEventListener('message', this.sendFormData);
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  sendFormData(event: any): void {
    // Ignore bad origin events
    if (event.origin !== window.location.origin) {
      return;
    }

    // Form first load, pass in form data
    if (event.data === 'Form Popout Loaded') {
      this.popup.postMessage({
        assetId: this.asset.guid,
        inspectionId: this.inspection.guid,
        codingDetail: this.codingDetail,
        fullCodingForm: this.fullCodingForm,
        subFullCodingForm: this.subFullCodingForm,
        inspection: this.inspection,
        assetType: this.asset.type,
      },
      window.location.origin);
    } else if (event.data.fullCodingForm !== undefined
    && event.data.subFullCodingForm !== undefined) {
      // close popup, get updated form data, reset popup state
      this.setCodingForm(event.data.fullCodingForm);
      this.setSubCodingForm(event.data.subFullCodingForm);
      window.removeEventListener('message', this.sendFormData);
      this.popOutOpen = false;
      this.popup = null;
    }
  }

  getRouteParams(): void {
    const route = this.$route;
    if (Object.keys(route.query).length > 0) {
      const queries = Object.entries(route.query);
      queries.forEach((pair) => {
        const [key, value] = pair;
        if (key === 'defectGuid') {
          this.defectToGoTo.defectGuid = value.toString();
        } else if (key === 'defectInspectionGuid') {
          this.defectToGoTo.inspectionGuid = value.toString();
        } else if (key === 'defectCode') {
          this.defectToGoTo.code = value.toString();
        } else if (key === 'defectPayout') {
          const numberValue = parseFloat(value.toString());
          if (!Number.isNaN(numberValue)) {
            this.defectToGoTo.payout = numberValue;
          }
        }
      });
    }
  }

  setInspectionEmit(inspectionGuid: string): void {
    const foundInspection = this.asset?.inspections?.find((insp) => insp.guid === inspectionGuid);
    if (foundInspection) {
      this.setInspection(foundInspection);
    }
  }

  get releasedColor(): string {
    return this.asset?.isReleased ? '#cae0d7' : '#f9d4d4';
  }

  get releasedTextColor(): string {
    return this.asset?.isReleased ? '#01734b' : '#e61e25';
  }

  get releasedText(): string {
    return this.asset?.isReleased ? 'Released' : 'Unreleased';
  }
}
