/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable class-methods-use-this */
import Vue from 'vue';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import {
  AssetData,
  AssetPayload,
  Condition,
  DeploymentFormDoNotInspectDTO,
  ReportingFeedback,
  VideoOrientation,
} from '@/store/asset/types';
import {
  ProjectData,
  ProjectListing,
  PlanningAnalytics,
  CollectionMetrics,
  ProjectGraphics,
  ProjectHeaders,
  GeoJsonJobObject,
} from '@/store/project/types';
import { ExportPayload } from '@/store/reportExports/types';
import { MapPagesData } from '@/store/mapPages/types';
import { iMPS } from '@/views/MasterProjectSummary/iMPS';
import { UserData, UserRole } from '@/store/users/types';
import { AssignmentShort } from '@/store/planning/types';
import { AccessPayload } from '@/store/dbExports/types';
import { AssetHistory, AssetWorkOrderHistory, HistoryEntry } from '@/components/ScopeHistory/types';
import { EdititableUser, User } from '@/store/admin/types';
import { AssetRef } from '@/types';
import { GasTempData, LineGraphData } from '@/store/lineGraph/types';
import { InspectionResponse } from '@/components/CodingForm/types';
import { DefectCodingModel, DefectCodingPostModel } from '@/store/defectCoding/types';
import { ZipFileMapping } from '@/store/types';
import { AICodingWorkOrder, AssignmentPost } from '@/store/assignments/types';
import { BigPipeDeploymentData, DeploymentData, DeploymentCount } from '@/store/deployments/types';
import { ImportMappingContainer } from '@/store/agol/types';
import { DeleteRoutingDataDTO } from '@/store/routing/types';
import { FetchCrewCountsPayload } from '@/store/metrics/types';
import {
  CrossSectionData, MSIInspectionData, PVDData,
} from '../store/crossSections/types';

import HttpClient from './httpClient';
import configSettings from './integrityConfiguration';
// import { integrityConfigMap } from '../../public/config';

export default class IntegrityApi extends HttpClient {
  public constructor(ENV_API_URL = '') {
    super((
      ENV_API_URL != null && ENV_API_URL !== '' && ENV_API_URL !== '{{VUE_APP_INTEGRITY_API_URL}}'
        ? ENV_API_URL : ''
    ));
    this.initializeRequestInterceptor();
  }

  private initializeRequestInterceptor = () => {
    this.instance.interceptors.request.use(
      this.handleRequest,
      this.handleError,
    );
  }

  private async handleRequest(config: AxiosRequestConfig): Promise<AxiosRequestConfig> {
    const newConfig = config;

    const token = await Vue.prototype.$auth.getTokenSilently();

    if (configSettings.VUE_APP_INTEGRITY_API_URL) {
      newConfig.baseURL = configSettings.VUE_APP_INTEGRITY_API_URL
        + configSettings.VUE_APP_INTEGRITY_API_VERSION;
    }

    newConfig.headers = {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, PUT',
      'Access-Control-Allow-Headers': '*',
    };

    return newConfig;
  }

  private createQueryStringFromArray(array: string[], fieldName: string): string {
    let arrayString = '';
    array.forEach((element) => {
      arrayString += `${fieldName}=${element}&`;
    });
    // Remove trailing ampersand
    arrayString = arrayString.substring(0, arrayString.length - 1);
    return arrayString;
  }

  protected handleError = (error: AxiosError): Promise<never> => Promise.reject(error);

  public getProjects(): Promise<AxiosResponse<ProjectListing[]>> {
    return this.instance.get<ProjectListing[]>('/projects');
  }

  public getProjectHeaders(ids: string[]): Promise<AxiosResponse<ProjectHeaders[]>> {
    const idStrings = this.createQueryStringFromArray(ids, 'guids');
    return this.instance.get<ProjectHeaders[]>(`/projects/getProjectHeaders?${idStrings}`);
  }

  public getProjectNames(ids: string[]): Promise<AxiosResponse<string[]>> {
    const idStrings = this.createQueryStringFromArray(ids, 'guids');
    return this.instance.get<string[]>(`/projects/getProjectNames?${idStrings}`);
  }

  public getProjectsSimple(): Promise<AxiosResponse<ProjectListing[]>> {
    return this.instance.get<ProjectListing[]>('/projects/list');
  }

  public getProject(ids: string[]): Promise<AxiosResponse<ProjectData>> {
    const idStrings = this.createQueryStringFromArray(ids, 'guids');
    return this.instance.get<ProjectData>(`/projects/getProject?${idStrings}`);
  }

  public getProjectTable(ids: string[]): Promise<AxiosResponse<ProjectData>> {
    const idStrings = this.createQueryStringFromArray(ids, 'guids');
    return this.instance.get<ProjectData>(`/projects/getProject/table?${idStrings}`);
  }

  public getProjectGraphics(ids: string[]): Promise<AxiosResponse<ProjectGraphics[]>> {
    const idStrings = this.createQueryStringFromArray(ids, 'guids');
    return this.instance.get<ProjectGraphics[]>(`/projects/getProjectGraphics?${idStrings}`);
  }

  public postInsertMPS(mpsData: iMPS): Promise<any> {
    return this.instance.post('/MPS', mpsData).catch((error) => {
      throw error.response;
    });
  }

  public getMPS(guid: string): Promise<any> {
    return this.instance.get(`/MPS/${guid}`).catch((error) => {
      throw error.response;
    });
  }

  public patchMPS(mpsData: iMPS): Promise<any> {
    return this.instance.patch('/MPS', mpsData).catch((error) => {
      throw error.response;
    });
  }

  public getAllUsers(): Promise<AxiosResponse<UserData[]>> {
    return this.instance.get<UserData[]>('/users');
  }

  public async getAllDetailedUsers(): Promise<any> {
    return this.instance.get('/users/detailed');
  }

  public async getInactiveDetailedUsers(): Promise<any> {
    return this.instance.get('/users/detailed/inactive');
  }

  // For testing purposes only
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // public getMapPages(id: string): Promise<AxiosResponse<MapPagesData>> {
  //   return this.instance.get<MapPagesData>('/mappage/seed');
  // }

  public getMapPages(ids: string[]): Promise<AxiosResponse<MapPagesData>> {
    const idStrings = this.createQueryStringFromArray(ids, 'projectGuids');
    return this.instance.get<MapPagesData>(`/mappage/ByExtents?${idStrings}`);
  }

  public getAsset(id: string): Promise<AxiosResponse<AssetData>> {
    return this.instance.get<AssetData>(`/assets/${id}`);
  }

  public postVideoOrientation(inspectionGuid: string,
    orientation: VideoOrientation): Promise<AxiosResponse<AssetData>> {
    return this.instance.post(`inspections/videoOrientation/${inspectionGuid}`, orientation);
  }

  public postReportingFeedback(assetGuid: string, feedback: ReportingFeedback): Promise<any> {
    return this.instance.post(`/assets/feedback/${assetGuid}`, feedback);
  }

  public postAsset(id: string, exportObject: string): Promise<AxiosResponse> {
    return this.instance.post(`/assets/${id}`, exportObject);
  }

  public patchDeployment(exportObject: DeploymentData[]): Promise<AxiosResponse> {
    return this.instance.patch('/deployments', exportObject);
  }

  public deleteAsset(ids: string[]): Promise<AxiosResponse<string>> {
    return this.instance.patch('/assets/deleteassetlist', ids);
  }

  public postExportInspections(exportPayloard: ExportPayload):
    Promise<AxiosResponse<string>> {
    let inspectionsString = '';
    exportPayloard.inspections.forEach((element) => {
      inspectionsString += `"${element}",`;
    });
    // gets rid of trailing comma
    inspectionsString = inspectionsString.substring(0, inspectionsString.length - 1);
    return this.instance.post(`/MacpExport/${exportPayloard.projectGuid}/exportinspections`,
      `{"inspectionGuids": [${inspectionsString}]}`);
  }

  public postSingleAsset(payload: AssetPayload): Promise<void> {
    return this.instance.post('/utility/addsingleasset', payload);
  }

  public postFile(payload: FormData): Promise<any> {
    return this.instance.post('/utility', payload);
  }

  public postFileMappings(payload: ZipFileMapping): Promise<any> {
    return this.instance.post('/utility/ImportMsi', payload);
  }

  public postMappings(payload: string): Promise<any> {
    return this.instance.post('/utility/ImportExcel', payload);
  }

  public postAccessDB(payload: AccessPayload): Promise<AxiosResponse<string>> {
    return this.instance.post(`/NasscoDb/importNasscoDatabase/${payload.projectGuid}`, payload.payload);
  }

  public getPlanningAnalytics(projectGuids: string[]): Promise<AxiosResponse<PlanningAnalytics>> {
    const projectString = this.createQueryStringFromArray(projectGuids, 'projectGuids');
    return this.instance.get(`/planning/PlanningCounts?${projectString}`);
  }

  public getAssignments(dateCutoff: string, taskTypeGuids: string[], projectGuids: string[]):
    Promise<AxiosResponse<AssignmentShort[]>> {
    const projectString = this.createQueryStringFromArray(projectGuids, 'projectGuids');
    const taskTypeString = this.createQueryStringFromArray(taskTypeGuids, 'taskTypeGuids');
    return this.instance.get(`/planning/workordersbytasktype?${projectString}&${taskTypeString}`, {
      params: {
        dateCutoff,
      },
    });
  }

  public patchAssignmentList(payload: unknown): Promise<AxiosResponse<string>> {
    return this.instance.patch('/planning/List', payload);
  }

  public deleteAssignment(workOrderGuids: string[]): Promise<AxiosResponse<string>> {
    return this.instance.patch('/planning/workorderdelete', workOrderGuids);
  }

  public editAssignment(editAssignment: string): Promise<AxiosResponse<string>> {
    return this.instance.patch('/planning/workorder', editAssignment);
  }

  public getAssignmentDataStandards(assignmentGuid: string): Promise<AxiosResponse<string>> {
    return this.instance.get(`/planning/workOrder/${assignmentGuid}`);
  }

  public patchAssignmentDataStandards(inspectionGuid: string, payload: any): Promise<any> {
    return this.instance.patch<string>(`/planning/workOrder/${inspectionGuid}`, payload);
  }

  public getScopeHistory(payload: string[]): Promise<AxiosResponse<HistoryEntry[]>> {
    const payloadString = this.createQueryStringFromArray(payload, 'guids');
    return this.instance.get<HistoryEntry[]>(`/scope/history?${payloadString}`);
  }

  public getAssetHistory(payload: string[]): Promise<AxiosResponse<AssetHistory[]>> {
    const payloadString = this.createQueryStringFromArray(payload, 'guids');
    return this.instance.get<AssetHistory[]>(`/assets/history?${payloadString}`);
  }

  public patchHistoryData(payload: any): Promise<any> {
    return this.instance.patch<string>('/assets/history', payload);
  }

  public getAssetWorkOrderHistory(payload: string): Promise<AxiosResponse<any>> {
    return this.instance.get(`/assets/workorderhistory/${payload}`);
  }

  public patchAssetWorkOrderHistory(payload: {
    guid: string,
    dto: {
      active: boolean,
    },
  }): Promise<AxiosResponse<any>> {
    return this.instance.patch(`/assets/workorderhistory/${payload.guid}`, payload.dto);
  }

  public getprojectDeliveryTotals(guids: string[]): Promise<AxiosResponse<any>> {
    const idStrings = this.createQueryStringFromArray(guids, 'guids');
    return this.instance.get<any>(`/assets/history/delivery?${idStrings}`);
  }

  public getCustomerPrepReleaseEmails(payload: string): Promise<AxiosResponse<any>> {
    return this.instance.get<any>(`/assets/customerPrepReleaseRecipients/${payload}`);
  }

  public getDataStandard(payload: string): Promise<AxiosResponse<any>> {
    return this.instance.get<any>(`/datastandards/getDataStandard/${payload}`);
  }

  public getBigPipeDeploymentData(payload: string): Promise<AxiosResponse<any>> {
    return this.instance.get<BigPipeDeploymentData>(`/deployments/deploymentbyguid/${payload}`);
  }

  public patchBigPipeDeployment(payload: {
    deploymentGuid: string,
    deploymentData: any
  }): Promise<AxiosResponse<any>> {
    return this.instance.patch(`/deployments/bigpipedeployment/${payload.deploymentGuid}`, payload.deploymentData);
  }

  public getDeploymentNumber(payload: string): Promise<AxiosResponse<any>> {
    return this.instance.get<string>(`/deployments/deploymentNumber/${payload}`);
  }

  public postDataStandard(payload: any): Promise<any> {
    return this.instance.post('/datastandards/savedatastandard', payload);
  }

  public patchDataStandard(payload: any): Promise<any> {
    return this.instance.patch('/datastandards/updatedatastandard', payload);
  }

  public exportAssets(exportOptions: FormData):
    Promise<AxiosResponse<string>> {
    return this.instance.post('/massexport', exportOptions);
  }

  public postNewUser(user: User): Promise<any> {
    return this.instance.post('/admin/createuser', user);
  }

  public patchUser(user: EdititableUser): Promise<any> {
    return this.instance.patch('/admin/edituser', user);
  }

  public deleteUser(userGuid: string): Promise<any> {
    return this.instance.delete(`/admin/deleteuser/${userGuid}`);
  }

  public deleteUserRole(userRoleGuid: string): Promise<any> {
    return this.instance.delete(`/admin/deleteuserrole/${userRoleGuid}`);
  }

  public patchUserRole(userRole: UserRole): Promise<any> {
    return this.instance.patch('/admin/patchuserrole/', userRole);
  }

  public async getFeatureFields(payload: string, token: string): Promise<AxiosResponse<any>> {
    return axios.get(`${payload}?f=json&token=${token}`);
  }

  public async getFeatureData(payload: string, primaryId: string,
    token: string): Promise<AxiosResponse<any>> {
    return axios.get(`${payload}/query?where=${primaryId}>0&outFields=*&f=pjson&token=${token}`);
  }

  postFeatureData(
    projectGuid: string,
    mappingGuid: string,
    payload: unknown,
  ): Promise<AxiosResponse<any>> {
    return this.instance.post(`/utility/ImportAgol/${projectGuid}/${mappingGuid}`, payload);
  }

  postAgolCreds(payload: any): Promise<AxiosResponse<any>> {
    return axios.post(`${payload.url}?client_id=${payload.client_id}`
    + '&grant_type=authorization_code'
    + `&code=${payload.code}`
    + `&code_verifier=${payload.code_verifier}`
    + `&redirect_uri=${payload.redirect_uri}`);
  }

  public getDetailedProjectMetrics(projectGuid: string): Promise<any> {
    return this.instance.get<CollectionMetrics[]>(`/projects/collection/${projectGuid}`);
  }

  public getDashboardProjectMetrics(projectGuid: string): Promise<any> {
    return this.instance.get<ProjectListing>(`/projects/metics/${projectGuid}`);
  }

  public deleteProject(projectGuid: string): Promise<any> {
    return this.instance.delete(`/projects/${projectGuid}`);
  }

  public getCrossSectionsString(inspectionGuid: string): Promise<any> {
    return this.instance.get<string>(`/inspections/crossSections/${inspectionGuid}`, {
      transformResponse: (x) => x,
    });
  }

  public getCrossSectionsList(inspectionGuid: string): Promise<any> {
    return this.instance.get<CrossSectionData[]>(`/inspections/crossSections/${inspectionGuid}`);
  }

  public getDefectCodingList(dataStandardGuid: string): Promise<any> {
    return this.instance.get<DefectCodingModel[]>(`/ConditionCodesController/byDataStandard/${dataStandardGuid}`);
  }

  public postDefectCodingData(inspectionGuid: string, defect: DefectCodingPostModel)
    : Promise<AxiosResponse<any>> {
    return this.instance.post(`/ConditionCodesController/${inspectionGuid}`, defect);
  }

  public deleteDefectData(inspectionGuid: string, defectGuids: string[])
  : Promise<AxiosResponse<any>> {
    const payloadString = this.createQueryStringFromArray(defectGuids, 'defectGuids');
    return this.instance.delete(`/ConditionCodesController/${inspectionGuid}?${payloadString}`);
  }

  public patchDefectData(inspectionGuid: string, defectGuid: string,
    defect: DefectCodingPostModel): Promise<AxiosResponse<any>> {
    return this.instance.patch(`/ConditionCodesController/${inspectionGuid}/${defectGuid}`, defect);
  }

  public validateAndScore(inspectionGuid: string): Promise<AxiosResponse<any>> {
    return this.instance.post(`/inspections/ValidateAndPostInspectionScoring/${inspectionGuid}`);
  }

  public getMSIData(inspectionGuid: string): Promise<any> {
    return this.instance.get<MSIInspectionData>(`/inspections/msidefects/${inspectionGuid}`);
  }

  public getPVDDetails(inspectionGuid: string): Promise<any> {
    return this.instance.get<PVDData>(`/inspections/pvdDetails/${inspectionGuid}`);
  }

  public getFlatGraphImage(assetGuid: string): Promise<any> {
    return this.instance.get<CrossSectionData[]>(`/inspections/flatGraph/${assetGuid}`);
  }

  public getLineGraphData(inspectionGuid: string): Promise<any> {
    return this.instance.get<LineGraphData>(`/inspections/lineGraphData/${inspectionGuid}`);
  }

  public getGasTempData(inspectionGuid: string): Promise<any> {
    return this.instance.get<GasTempData>(`/inspections/gasTemp/${inspectionGuid}`);
  }

  public getInspectionDetail(assetGuid: string): Promise<any> {
    return this.instance.get<InspectionResponse>(`/inspections/inspectionDetail/${assetGuid}`);
  }

  public patchInspectionDetail(inspectionGuid: string, payload: any): Promise<any> {
    return this.instance.patch<string>(`/inspections/inspectionDetail/${inspectionGuid}`, payload);
  }

  public deleteInspectionDetail(subInspectionGuid: string): Promise<any> {
    return this.instance.delete(`/inspections/deleteSubInspection/${subInspectionGuid}`);
  }

  public getConditionDetails(inspectionGuid: string): Promise<any> {
    return this.instance.get<Condition[]>(`/inspections/defects/${inspectionGuid}`);
  }

  public getCannedPDF(inspectionGuid: string): Promise<any> {
    return this.instance.get<string>(`/inspections/cannedPDF/${inspectionGuid}`);
  }

  public getWallLossData(inspectionGuid: string): Promise<any> {
    return this.instance.get<string>(`/inspections/wallLoss/${inspectionGuid}`);
  }

  public postAssignments(assignments: AssignmentPost[]): Promise<AxiosResponse<any>> {
    return this.instance.post('/assets/assignments', assignments).catch((error) => {
      throw error.response;
    });
  }

  public postRouteAssignments(assignments: AssignmentPost[]): Promise<AxiosResponse<any>> {
    return this.instance.post('/assets/routes', assignments).catch((error) => {
      throw error.response;
    });
  }

  public postFollowUpWorkOrder(
    parentWorkOrderGuid: string, taskTypeGuid: string,
  ): Promise<AxiosResponse<any>> {
    return this.instance.post(`/planning/workOrder/followUp/${parentWorkOrderGuid}/${taskTypeGuid}`);
  }

  public getReadyForCodingAssignments(): Promise<any> {
    return this.instance.get('/assets/readyForCoding');
  }

  public getAICodingWorkOrderByProject(projectGuid: string): Promise<any> {
    return this.instance.get(`/Ai/aiworkorders/${projectGuid}`);
  }

  public patchAICodingWorkOrderByProject(payload: {
    projectGuid: string, payload: AICodingWorkOrder[]
  }): Promise<any> {
    return this.instance.patch(`/Ai/aiworkorders/${payload.projectGuid}`, payload.payload);
  }

  public getWorkOrderMetrics(projectGuid: string): Promise<any> {
    return this.instance.get(`metrics/workOrder/statuses/${projectGuid}/`);
  }

  public getOMDefectMetrics(projectGuid: string): Promise<any> {
    return this.instance.get(`metrics/project/assetScores/OmGrade/${projectGuid}/`);
  }

  public getStructGradeMetrics(projectGuid: string): Promise<any> {
    return this.instance.get(`metrics/project/assetScores/StructGrade/${projectGuid}`);
  }

  public getAssignmentMetrics(projectGuid: string): Promise<any> {
    return this.instance.get(`/metrics/assignment/statuses/${projectGuid}`);
  }

  public getProjectOverviewMetrics(projectGuid: string): Promise<any> {
    return this.instance.get(`/metrics/overview/${projectGuid}`);
  }

  public getOverviewTotalcounts(projectGuid: string): Promise<any> {
    return this.instance.get(`/metrics/counttotals/${projectGuid}`);
  }

  public getDataDeliveryProjectMetrics(projectGuid: string): Promise<any> {
    return this.instance.get(`/metrics/dataDelivery/${projectGuid}`);
  }

  public getProjectTotals(projectGuid: string): Promise<any> {
    return this.instance.get(`/metrics/project/totals/${projectGuid}`);
  }

  public getDefectCounts(projectGuid: string, assetsFiltered: string[]): Promise<any> {
    return this.instance.get(`/metrics/project/defects/${projectGuid}`, {
      params: { assetsFiltered },
    });
  }

  public getCrewCounts(payload: FetchCrewCountsPayload): Promise<any> {
    return this.instance.post(`/metrics/project/crewcounts/${payload.projectGuid}`, {
      startDate: payload.startDate,
      endDate: payload.endDate,
    });
  }

  public getCrewCountByAverage(payload: FetchCrewCountsPayload): Promise<any> {
    return this.instance.post(`/metrics/project/crewcountsbyaverage/${payload.projectGuid}`, {
      platformName: payload.platformName,
      startDate: payload.startDate,
      endDate: payload.endDate,
    });
  }

  public getDashboardDefects(payload: {
    projectGuid: string,
    scores: number[],
  }): Promise<any> {
    return this.instance.post(`/metrics/project/defectsbyarray/${payload.projectGuid}`, payload.scores);
  }

  public getPlatformCrewOverview(projectGuid: string): Promise<any> {
    return this.instance.get(`/metrics/project/platformcrewmetrics/${projectGuid}`);
  }

  public getUpdateCrewCount(payload: FetchCrewCountsPayload): Promise<any> {
    return this.instance.post(`/metrics/project/singlecrewcount/${payload.projectGuid}`, {
      platformName: payload.platformName,
      startDate: payload.startDate,
      endDate: payload.endDate,
    });
  }

  public getUpdateCrewCountAverages(payload: FetchCrewCountsPayload): Promise<any> {
    return this.instance.post(`/metrics/project/singlecrewcountbyaverage/${payload.projectGuid}`, {
      platformName: payload.platformName,
      crewName: payload.crewName,
      startDate: payload.startDate,
      endDate: payload.endDate,
    });
  }

  public getNasscoInspectionScores(projectGuid: string, assetsFiltered: string[]): Promise<any> {
    return this.instance.get(`/metrics/inspectionscores/${projectGuid}`, {
      params: { assetsFiltered },
    });
  }

  public getCrewCountsByDay(payload: string): Promise<any> {
    return this.instance.get(`/metrics/project/crewcountsbyday/${payload}`);
  }

  public getCrewDataDeliveryProjectMetrics(payload: {
    projectGuid: string,
    platformName: string,
  }): Promise<any> {
    return this.instance.get(`/metrics/projectmetricsbycrew/${payload.projectGuid}/${payload.platformName}`);
  }

  public getCustomerDashboardWorkOrders(payload: {
    projectGuid: string,
    startScheduledForString: string | undefined,
    endScheduledForString: string | undefined,
    startCompletedByString: string | undefined,
    endCompletedByString: string | undefined,
    }): Promise<any> {
    const scheduledForString = !payload.startScheduledForString ? 'null' : `startScheduledForString=${payload.startScheduledForString}`;
    const completedByString = !payload.endScheduledForString ? 'null' : `endScheduledForString=${payload.endScheduledForString}`;
    const startCompletedByString = !payload.startCompletedByString ? 'null' : `startCompletedByString=${payload.startCompletedByString}`;
    const endCompletedByString = !payload.endCompletedByString ? 'null' : `endCompletedByString=${payload.endCompletedByString}`;
    return this.instance.get(`/metrics/customerDashboard/workorders/${payload.projectGuid}?${scheduledForString}&${completedByString}&${startCompletedByString}&${endCompletedByString}`);
  }

  public getWorkOrderProjectMetrics(projectGuid: string): Promise<any> {
    return this.instance.get(`/metrics/workorder/${projectGuid}`);
  }

  public getDeploymentData(projectGuids: string[]): Promise<any> {
    const idStrings = this.createQueryStringFromArray(projectGuids, 'projectGuids');
    return this.instance.get(`/deployments/?${idStrings}`);
  }

  public getDeploymentCounts(projectGuids: string[]): Promise<any> {
    const idStrings = this.createQueryStringFromArray(projectGuids, 'projectGuids');
    return this.instance.get(`/deployments/counts/?${idStrings}`);
  }

  public exportDeploymentCsv(exportObject: any): Promise<any> {
    return this.instance.post('/deployments/csv', exportObject);
  }

  public getAssetPopout(assetGuid: string): Promise<any> {
    return this.instance.get(`/projects/popout/${assetGuid}`);
  }

  public postGeoJson(payload: GeoJsonJobObject): Promise<void> {
    return this.instance.post('/utility/GeoJsonConverter', payload);
  }

  public getAGOLMapping(payload: string): Promise<any> {
    return this.instance.get(`/projects/mapping/agol/${payload}`);
  }

  public postAGOLMapping(payload: {
    projectGuid: string, payload: ImportMappingContainer
  }): Promise<any> {
    return this.instance.post(`/projects/mapping/agol/${payload.projectGuid}`, payload.payload);
  }

  public patchAGOLMapping(payload: {
    mappingGuid: string, payload: ImportMappingContainer
  }): Promise<any> {
    return this.instance.patch(`/projects/mapping/agol/${payload.mappingGuid}`, payload.payload);
  }

  public deleteAGOLMapping(payload: string): Promise<any> {
    return this.instance.delete(`/projects/mapping/agol/${payload}`);
  }

  public turnOffAgolMapping(payload: string): Promise<any> {
    return this.instance.patch(`/projects/mapping/agol/turnOff/${payload}`);
  }

  public postRoute(exportObject: string): Promise<AxiosResponse> {
    return this.instance.post('/routing', exportObject);
  }

  public patchRoute(exportObject: string): Promise<AxiosResponse> {
    return this.instance.patch('/routing', exportObject);
  }

  public fetchRoutingData(guid: string): Promise<any> {
    return this.instance.get(`/routing/project/${guid}`);
  }

  public deleteRoutingData(guids: DeleteRoutingDataDTO): Promise<any> {
    return this.instance.patch('/routing/multidelete', guids);
  }

  public fetchSimpleRoutingData(guid: string): Promise<any> {
    return this.instance.get(`/routing/project/${guid}/simple`);
  }

  public fetchRouteAssets(guid: string): Promise<any> {
    return this.instance.get(`/routing/${guid}/assets`);
  }

  public postRouteAssetDuplicates(guids: string[]): Promise<any> {
    return this.instance.post('/routing/checkAssetDuplicates', guids);
  }

  public getAssetCountsMetrics(guid: string): Promise<any> {
    return this.instance.get(`/projects/assetcountsmetrics/${guid}`);
  }

  public getClientActionItems(projectGuid: string): Promise<any> {
    return this.instance.get(`/metrics/project/clientactionitems/${projectGuid}`);
  }

  public fetchLineSegmentAccessPoints(payload: any): Promise<any> {
    return this.instance.post('/assets/lineSegmentAccessPoints', payload);
  }

  public fetchAvailableManholes(payload: any): Promise<any> {
    return this.instance.get(`/assets/availableManholes/${payload}`);
  }

  public postCreateNewLineSegmentAccessPoint(payload: any): Promise<any> {
    return this.instance.post('/assets/lineSegmentAccessPoints/new', payload);
  }

  public postDeployment(payload: any): Promise<any> {
    return this.instance.post('/deployments', payload).catch((error) => {
      throw error.response;
    });
  }

  public patchDeploymentFormWorkOrders(payload: DeploymentFormDoNotInspectDTO[]): Promise<any> {
    return this.instance.patch('/planning/patchworkorderfromdeploymentform', payload);
  }

  public postReleaseInspection(payload: any): Promise<AxiosResponse<any>> {
    return this.instance.post('/releases', payload);
  }

  public patchReleaseInspection(payload: any): Promise<AxiosResponse<any>> {
    return this.instance.patch('/releases', payload);
  }

  public postSendExportEmail(releaseGuid: string, userEmail: string): Promise<AxiosResponse<any>> {
    return this.instance.post(`/releases/exportEmail/${releaseGuid}/${userEmail}`);
  }

  public postSendNotificationEmail(releaseGuid: string, notificationEmails: string[])
  : Promise<AxiosResponse<any>> {
    return this.instance.post(`/releases/notificationEmail/${releaseGuid}`, notificationEmails);
  }

  public fetchReleasesOverviewData(projectGuid: string): Promise<any> {
    return this.instance.get(`/releases/${projectGuid}`);
  }

  public fetchReleaseTableData(projectGuid: string): Promise<any> {
    return this.instance.get(`/releases/getProjectReleaseTableData/${projectGuid}`);
  }

  public getInspectionReleases(projectGuid: string): Promise<any> {
    return this.instance.get(`/releases/inspectionreleases/${projectGuid}`);
  }

  public postSendCsvEmail(
    projectGuid: string,
    releaseGuid: string,
    notificationEmails: string[],
  ): Promise<AxiosResponse<any>> {
    return this.instance.post(`/releases/exportCsv/${projectGuid}/${releaseGuid}`, notificationEmails);
  }

  public deleteRelease(releaseGuid: string): Promise<any> {
    return this.instance.delete(`/releases/${releaseGuid}`);
  }

  public deleteReleaseInspection(releaseGuid: string, assetGuid: string): Promise<any> {
    return this.instance.delete(`/releases/inspection/${releaseGuid}/${assetGuid}`);
  }
}
