











































































































































































































































































































/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-param-reassign */
import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import Ajv2019 from 'ajv/dist/2019';
import addFormats from 'ajv-formats';
import { namespace } from 'vuex-class';
import { AssetData, InspectionData } from '@/store/asset/types';
import { InspectionActions } from '@/store/inspection/actions';
import VueHtml2pdf from 'vue-html2pdf';
import { AssetActions } from '@/store/asset/actions';
import { uuid } from 'vue-uuid';
import { InspectionMutations } from '@/store/inspection/mutations';
import {
  CodingFormData,
  ConditionalRequirement,
  ConditionalType,
  FormPageData,
  InspectionResponse,
  // RequiredFieldArray,
  // RequiredValueType,
  SubInspectionData,
} from './types';
import FormPage from './FormPage/FormPage.vue';
import Observation from '../Report/ObservationFullReport/ObservationFullReport.vue';
import CleaningWorkOrderReport from '../Report/Cleaning/CleaningReport.vue';
import PipeInspectionReport from '../Report/Pipe Inspection/PipeInspectionReport.vue';
import ServiceCallReport from '../Report/ServiceCall/ServiceCallReport.vue';
import StructureInspectionReport from '../Report/Structure Inspection/StructureInspectionReport.vue';
import SSOReportReport from '../Report/SSO/SSOReportReport.vue';
import GeneralMaintenanceReport from '../Report/General Maintenance/GeneralMaintenanceReport.vue';
import FOGInspectionReport from '../Report/FOG Inspection/FOGInspectionReport.vue';
import RepairReport from '../Report/Repair/RepairReport.vue';
import MACP2 from '../Report/MACP2/MACP2.vue';
import utils from '../../views/Asset/utils';
import GenericWorkReport from '../Report/GenericWork/GenericWorkReport.vue';
import SmokeTestReport from '../Report/Smoke Test/SmokeTestReport.vue';

const inspectionModule = namespace('inspection');
const assetModule = namespace('asset');
const planningModule = namespace('planning');

@Component({
  components: {
    FormPage,
    CleaningWorkOrderReport,
    PipeInspectionReport,
    ServiceCallReport,
    StructureInspectionReport,
    SSOReportReport,
    GeneralMaintenanceReport,
    GenericWorkReport,
    FOGInspectionReport,
    RepairReport,
    MACP2,
    Observation,
    SmokeTestReport,
    VueHtml2pdf,
  },
})
export default class CodingForm extends Vue {
  @Prop({ default: null }) assetId: string;

  @Prop() inspectionId: string;

  @Prop() assetType: string;

  @Prop() popOutOpen: boolean;

  @Prop() isPopout: boolean;

  @Prop({ default: false }) isWorkOrderForm: boolean;

  @Prop({ default: '' }) workOrderReport: string;

  @Prop({ default: null }) item: any;

  @Prop({ default: false }) hasModel: boolean;

  @Prop({ default: null }) maxDepth: number;

  @Prop({ default: null }) codingDetail: InspectionResponse;

  @Prop() inspection!: InspectionData;

  @Prop({ default: true }) enableReportExport: boolean;

  @Prop({ default: false }) readonly: boolean;

  @Prop() canEdit: boolean;

  @Prop() canExport: boolean;

  @assetModule.State('asset') assetData: AssetData | undefined = undefined;

  @assetModule.Action(AssetActions.FETCH_ASSET_DATA) fetchAssetData;

  @inspectionModule.State('codingError') codingError: string | undefined;

  @inspectionModule.State('codingState') codingState: boolean;

  @inspectionModule.State('fullCodingForm') fullCodingForm:
    | CodingFormData[]
    | undefined;

  @inspectionModule.State('subFullCodingForm') subFullCodingForm:
    | any[]
    | undefined;

  @inspectionModule.Action(InspectionActions.FETCH_CODING_DETAIL) fetchCodingDetail;

  @inspectionModule.Mutation(InspectionMutations.SET_CODING_DETAIL) setCodingDetail;

  @inspectionModule.Action(InspectionActions.SET_CODING_FORM) setCodingForm;

  @inspectionModule.Action(InspectionActions.SET_SUB_CODING_FORM) setSubCodingForm;

  @inspectionModule.Action(InspectionActions.PATCH_CODING_DETAIL) patchCodingDetail;

  @inspectionModule.Action(InspectionActions.DELETE_SUB_INSPECTION) deleteSubInspectionAction;

  @planningModule.State('patchWorkOrderLoading') patchWorkOrderLoading: boolean;

  ALWAYSREQUIRED = 'alwaysRequired';

  pages = [];

  subPages = [];

  connectionsCount = 0;

  dataStandardJson = null;

  dataStandardFormat;

  dataStandardSchema = {};

  subDataStandardFormat;

  subDataStandardSchema;

  subDataFormFlag = false;

  subCodeIndex = -1;

  errors = [];

  subErrors = [];

  selectedType = '';

  selectedTypeOld = '';

  selectedPipeConnection = 0;

  currentFormPageData = [];

  oldFormData = {};

  oldSubFormData = [];

  checkBoxHeaderNumbers = [];

  subCheckBoxHeaderNumbers = [];

  ajv = new Ajv2019({
    strict: false,
    strictSchema: false,
    strictTypes: false,
    strictRequired: false,
    strictNumbers: false,
    validateFormats: false,
    useDefaults: 'empty',
    allErrors: true,
    multipleOfPrecision: 6,
    unicodeRegExp: false,
  });

  validate = null;

  subValidate = null;

  showValidationResults = true;

  isPatched = false;

  requiredFieldObjects: ConditionalRequirement;

  hasDownloaded = true;

  reportRefreshKey = 0;

  processingForm = false;

  assetAttributes = null;

  // Keeping track of GUIDs created for each pipe connection/sub coding forms
  codingDetailInspections = [];

  // Field names for required fields
  requiredFields = new Map<string, boolean>();

  errorExists = false;

  error = '';

  subSchemaNumberHeaders = [
    'PipeNumber',
    'AssetNumber',
  ]

  get inspectionIndex(): number {
    let retVal = this.codingDetail.inspections.findIndex(
      (insp) => insp.inspectionGuid === this.inspectionId,
    );
    if (retVal === -1) retVal = 0;
    return retVal;
  }

  get codingFormValidBool(): boolean {
    return (
      this.errors !== undefined
      && (this.errors.length > 0 || this.subErrors.length > 0 || this.codingState)
    );
  }

  async mounted(): Promise<void> {
    if (!this.isPopout && this.codingDetail === null) return;
    await this.populateForm();
  }

  @Watch('inspectionId')
  async onInspectionChange(): Promise<void> {
    await this.reset();
    await this.populateForm();
  }

  @Watch('selectedType')
  selectedTypeChange(): void {
    // TODO: split into functions
    // sub ds
    if (this.selectedType === undefined) {
      this.selectedType = this.selectedTypeOld;
      return;
    }

    this.selectedTypeOld = this.selectedType;

    if (
      this.subPages.includes(this.selectedType)
      || this.selectedType === 'Pipe Connection'
    ) {
      this.subDataFormFlag = true;
      const formPage = this.subDataStandardFormat.formpage.find(
        (fp) => fp.pagename === this.selectedType,
      );

      if (formPage != null) {
        this.currentFormPageData = formPage.formpagedata as FormPageData[];
      }

      this.currentFormPageData.forEach((form: FormPageData) => {
        // look for prefilled in subform
        this.oldSubFormData.forEach((oldData) => {
          form.prefilled = false;
          if (
            oldData[form.headername] != null
            && oldData[form.headername].toString().trim() !== ''
          ) {
            form.prefilled = true;
          }
        });

        // look for enums
        // TODO: De-jankify
        const properties = Object.entries(this.subDataStandardSchema).find(
          (e) => e[0] === 'properties',
        )[1];

        if (properties != null) {
          const currProp = Object.entries(properties).find(
            (p) => p[0] === form.headername,
          )[1];
          form.enum = currProp.enum;
        }
      });

      this.validateForm();
      return;
    }

    // ds
    this.subDataFormFlag = false;
    const isSubDataForm = this.subPages.includes(this.selectedType);
    this.subDataFormFlag = isSubDataForm;
    const currentDataStandardFormat = isSubDataForm
      ? this.subDataStandardFormat
      : this.dataStandardFormat;
    const formPage = currentDataStandardFormat.formpage.find(
      (fp) => fp.pagename === this.selectedType,
    );

    if (formPage && formPage.formpagedata) {
      this.currentFormPageData = formPage.formpagedata as FormPageData[];
    }

    this.currentFormPageData.forEach((form: FormPageData) => {
      // look for prefilled in main form
      const oldFormData = this.oldFormData[form.headername];
      form.prefilled = false;

      if (oldFormData != null && oldFormData.toString().trim() !== '') {
        form.prefilled = true;
      }

      form.required = this.requiredFields.has(form.headername)
        && this.requiredFields.get(form.headername);

      // look for hints
      if (
        this.dataStandardJson?.Requirements
        && Object.keys(this.dataStandardJson.Requirements.HeaderValues).includes(
          form.headername,
        )
      ) {
        form.hint = this.dataStandardJson.Requirements.HeaderValues[form.headername];
      }

      // look for enums
      const currentSchema = isSubDataForm
        ? this.subDataStandardSchema
        : this.dataStandardSchema;
      const properties = Object.entries(currentSchema).find(
        (e) => e[0] === 'properties',
      )[1];

      if (properties === null) {
        return;
      }

      const currPropArr = Object.entries(properties).find(
        (p) => p[0] === form.headername,
      );

      if (
        currPropArr === undefined
        || currPropArr[1] === undefined
        || currPropArr[1].enum === undefined
      ) {
        return;
      }

      const currProp = currPropArr[1];
      form.enum = currProp.enum;
    });
    this.validateForm();
  }

  @Watch('codingDetail')
  async oncodingDetailUpdate(): Promise<void> {
    await this.reset();

    if (this.codingDetail === undefined) return;

    const currentInspDetails = this.codingDetail?.inspections[this.inspectionIndex];

    if (!currentInspDetails) {
      return;
    }

    this.dataStandardFormat = JSON.parse(
      currentInspDetails.dataStandard_format,
    );
    this.dataStandardSchema = JSON.parse(
      currentInspDetails.dataStandard_Schema,
    );
    this.subDataStandardFormat = JSON.parse(
      currentInspDetails.subDataStandard_format,
    );
    this.subDataStandardSchema = JSON.parse(
      currentInspDetails.subDataStandard_Schema,
    );
    this.dataStandardJson = currentInspDetails.dataStandard_Json;

    if (!this.dataStandardFormat) {
      this.error = 'Error: Invalid Data Standard Format';
      this.errorExists = true;
      return;
    }
    if (!this.dataStandardSchema) {
      this.error = 'Error: Invalid Data Standard Schema';
      this.errorExists = true;
      return;
    }

    currentInspDetails.subInspectionJsonData.forEach((element) => {
      const elementExists = this.codingDetailInspections.find(
        (insp) => insp.subInspectionGuid === element.subInspectionGuid,
      );

      if (!elementExists) {
        this.codingDetailInspections.push(element);

        if (element.jsonData !== null) {
          this.oldSubFormData.push(JSON.parse(element.jsonData));
        } else if (element !== null) {
          this.oldSubFormData.push(JSON.parse(element as string));
        }
      }
    });

    if (this.codingDetail.inspections[0].jsonData) {
      this.oldFormData = JSON.parse(this.codingDetail.inspections[0].jsonData);
    }

    const reqs: string[] = [];
    this.pages = this.dataStandardFormat.formpage.map((m) => m.pagename);

    if (this.subDataStandardFormat != null) {
      this.subPages = this.subDataStandardFormat.formpage.map(
        (m) => m.pagename,
      );
    }

    this.pages.forEach((page) => {
      const uniqueNums = [];

      const formPage = this.dataStandardFormat.formpage.find(
        (fp) => fp.pagename === page,
      );
      if (formPage !== undefined) {
        formPage.formpagedata.forEach((pageData) => {
          const oldVal = this.oldFormData[pageData.headername];

          // check if header has already been added, if it was, skip
          if (
            !this.isPopout
            && this.fullCodingForm.findIndex(
              (fc) => fc.header === pageData.headername,
            ) === -1
          ) {
            this.fullCodingForm.push({
              header: pageData.headername,
              // instead of null we should check data type and fill as appropriate
              // strings = '' ints/nums = 0
              value: this.getCodingFormDataValue(oldVal, pageData),
              isChanged: !!(
                oldVal
                || (reqs && reqs.includes(pageData.headername))
              ),
              headerNumber: pageData.headernumber,
            });
          }

          // look for checkbox values
          const currNum = pageData.headernumber;
          if (pageData.type !== 'array') {
            if (!uniqueNums.includes(currNum) && pageData.type !== 'boolean') {
              uniqueNums.push(currNum);
            } else if (
              !uniqueNums.includes(currNum)
              && pageData.type === 'boolean'
            ) {
              uniqueNums.push(currNum);
              this.checkBoxHeaderNumbers.push(currNum);
            } else {
              this.checkBoxHeaderNumbers.push(currNum);
            }
          }
        });
      }
    });

    // check for macp/pacp values to prefill
    if (this.assetType === 'Manhole') {
      // macp
      const mhNumber = this.fullCodingForm.find(
        (fc) => fc.header === 'ManholeNumber',
      );
      if (mhNumber && mhNumber.value === '') {
        mhNumber.value = this.codingDetail.name;
      }
    } else {
      // pacp
      // 30. Pipe Segment
      const lsSegment = this.fullCodingForm.find(
        (fc) => fc.header === 'PipeSegment',
      );
      if (lsSegment && lsSegment.value === '') {
        lsSegment.value = this.codingDetail.name;
      }
      // 43. Map Length
      const mapLength = this.fullCodingForm.find(
        (fc) => fc.header === 'TotalLength',
      );
      if (mapLength && mapLength.value === null) {
        mapLength.value = parseFloat(
          (this.assetData?.attributes as any)?.Length,
        ).toFixed(2);
      }
      // 44. Length Surveyed
      const maxDepth = this.fullCodingForm.find(
        (fc) => fc.header === 'LengthSurveyed',
      );
      if (maxDepth && maxDepth.value === null) {
        maxDepth.value = this.maxDepth;
      }
      const maxDepthReached = Math.round(utils.getMaxDepthReached(this.inspection) * 10) / 10;
      maxDepth.value = Math.max(maxDepth.value as number, maxDepthReached);
    }

    if (this.subDataStandardFormat != null) {
      if (this.oldSubFormData.length > this.subFullCodingForm.length) {
        this.oldSubFormData.forEach((oldData) => {
          const uniqueNums = [];
          const tempData = [];
          this.subPages.forEach((page) => {
            this.subDataStandardFormat.formpage
              .find((fp) => fp.pagename === page)
              .formpagedata.forEach((pageData) => {
                const oldVal = oldData[pageData.headername];
                tempData.push({
                  header: pageData.headername,
                  value: this.getCodingFormDataValue(oldVal, pageData),
                  // eslint-disable-next-line no-unneeded-ternary
                  isChanged: !!oldVal,
                  headerNumber: pageData.headernumber,
                });
                // look for checkbox values
                const currNum = pageData.headernumber;
                if (!uniqueNums.includes(currNum)) {
                  uniqueNums.push(currNum);
                } else {
                  this.subCheckBoxHeaderNumbers.push(currNum);
                }
              });
          });
          this.subFullCodingForm.push(tempData);
        });

        this.sortSubFullCoding();
      }
      this.connectionsCount = this.subFullCodingForm.length;
      this.subCodeIndex = this.connectionsCount > 0 ? 0 : -1;
    }

    this.checkCheckBoxValues();

    this.connectionsCount = this.subFullCodingForm.map((cf) => cf.find((it) => it.header === 'Structure')).length;
    // eslint-disable-next-line prefer-destructuring
    this.selectedType = this.pages[0];

    this.requiredFieldObjects = this.findRequiredFields(
      this.dataStandardSchema,
    );

    // Ajv throws an error if we try to add formats if they already exist
    if (Object.keys(this.ajv.formats).length === 0) {
      addFormats(this.ajv);
    }
    this.validate = this.ajv.compile(this.dataStandardSchema);
    if (this.subDataStandardSchema) {
      this.subValidate = this.ajv.compile(this.subDataStandardSchema);
    }
    this.validateForm();
  }

  @Watch('selectedPipeConnection')
  onSelectedPipeConnectionChange(): void {
    if (this.selectedPipeConnection === undefined) {
      this.selectedPipeConnection = 1;
      return;
    }

    if (this.connectionsCount === 0) {
      this.subCodeIndex = -1;
      return;
    }

    this.subCodeIndex = this.selectedPipeConnection - 1;

    this.currentFormPageData.forEach((form: FormPageData) => {
      if (this.subCodeIndex === 0) {
        this.oldSubFormData.forEach((oldData) => {
          form.prefilled = false;
          if (
            oldData[form.headername] != null
            && oldData[form.headername].toString().trim() !== ''
          ) {
            form.prefilled = true;
          }
        });
      } else {
        this.oldSubFormData.forEach(() => {
          form.prefilled = false;
          if (this.subSchemaNumberHeaders.indexOf(form.headername) !== -1) {
            form.prefilled = true;
          }
        });
      }
    });
    this.validateForm();
  }

  async populateForm(): Promise<void> {
    if (this.isPopout && this.assetId === null) {
      window.opener.postMessage('Form Popout Loaded', window.location.origin);
      window.addEventListener(
        'message',
        async (event) => {
          if (event.data.assetId) this.assetId = event.data.assetId;
          if (event.data.inspectionId) {
            this.inspectionId = event.data.inspectionId;
          }
          if (event.data.codingDetail) {
            this.codingDetail = event.data.codingDetail;
          }
          if (event.data.inspection) this.inspection = event.data.inspection;
          if (event.data.assetType) this.assetType = event.data.assetType;
          if (event.data.fullCodingForm) {
            await this.setCodingForm(event.data.fullCodingForm);
          }
          if (event.data.subFullCodingForm) {
            await this.setSubCodingForm(event.data.subFullCodingForm);
          }

          if (this.assetId && this.inspectionId) {
            await this.fetchAssetData({
              assetId: this.assetId,
              inspectionId: this.inspectionId,
            });
          }
        },
        { once: true },
      );

      const container = document.getElementsByClassName(
        'view-container',
      )[0] as HTMLElement;
      const header = document.getElementsByClassName(
        'navbarCustom',
      )[0] as HTMLElement;
      const footer = document.getElementsByClassName(
        'footer',
      )[0] as HTMLElement;
      const nav = document.getElementsByClassName(
        'v-navigation-drawer',
      )[0] as HTMLElement;
      const main = document.getElementsByClassName('v-main')[0] as HTMLElement;
      if (header) header.remove();
      if (footer) footer.remove();
      if (nav) nav.remove();
      main.style.padding = '0px';
      container.style.height = '900px';
      window.addEventListener('resize', () => {
        window.resizeTo(1200, 1000);
      });

      window.addEventListener('beforeunload', this.beforeUnloadPop, {
        capture: true,
      });
    }

    if (this.codingDetail !== undefined) {
      this.oncodingDetailUpdate();
    }

    this.sortSubFullCoding();

    if (this.item) {
      await this.fetchAssetData({
        assetId: this.item.nodes.guid,
        inspectionId: this.item.guid,
      });
    }
  }

  /**
 * @description Used to get the Button text to add an object to the subschema
 * @return String to render in the button
 */
  getSubSchemaButtonText() {
    const pageHeader = this.getPageHeader();
    switch (pageHeader) {
      case 'AssetNumber':
        return 'Add Asset';
      case 'PipeNumber':
        return 'Add Pipe Connection';
      default:
        return 'Error - No Number Field';
    }
  }

  /**
 * @description Used to get the function to call on submit
 * @return the function to call
 */
  getSubSchemaButtonSubmitFunction() {
    const pageHeader = this.getPageHeader();
    switch (pageHeader) {
      case 'AssetNumber':
        return this.addAssetConnection;
      case 'PipeNumber':
        return this.addPipeConnection;
      default:
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        return () => {};
    }
  }

  /**
 * @description Used to get the label for the object type in the subschema
 * @return the label for the object type
 */
  getSubSchemaLabelText() {
    const pageHeader = this.getPageHeader();
    switch (pageHeader) {
      case 'AssetNumber':
        return 'Asset';
      case 'PipeNumber':
        return 'Pipe Connection';
      default:
        return 'Object';
    }
  }

  /**
 * @description Get the header of the current page
 * @returns the header name or null if it cannot be found
 */
  getPageHeader(): string | null {
    const pageSubDataStandardFormat = this.subDataStandardFormat.formpage
      .find((fp) => fp.pagename === this.selectedType);

    let pageHeader = null;
    if (pageSubDataStandardFormat?.formpagedata != null
    && pageSubDataStandardFormat.formpagedata.length > 0) {
      pageHeader = pageSubDataStandardFormat.formpagedata[0].headername;
    }
    return pageHeader;
  }

  formDataArrayToObject(codingForm: any, mainInspection: boolean): any {
    if (!codingForm) {
      return false;
    }
    const retVal = codingForm.reduce(
      (a, v) => ({ ...a, [v.header]: v.value }),
      {},
    );
    if (mainInspection) {
      // remove unchanged values
      const delHeaders = this.fullCodingForm
        .filter((fc) => fc.isChanged === false)
        .map((fc) => fc.header);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      delHeaders.forEach((header) => {
        // delete retVal[header];
      });
    }
    return retVal;
  }

  validateForm(): void {
    // TODO: eliminate side effects
    // update pipeConnections if we're on pipe connections
    // validate sub inspections if on pipe coinnections
    if (this.subDataFormFlag && this.subFullCodingForm.length > 0) {
      this.connectionsCount = this.subFullCodingForm.length;
      // validate sub form
      const valid = this.subValidate(
        this.formDataArrayToObject(
          this.subFullCodingForm.find(
            (cf) => cf.find((it) => this.subSchemaNumberHeaders.indexOf(it.header) !== -1).value
              === this.subCodeIndex,
          ) !== undefined
            ? this.subFullCodingForm.find(
              (cf) => cf.find((it) => this.subSchemaNumberHeaders.indexOf(it.header) !== -1).value
                  === this.subCodeIndex,
            )
            : this.subFullCodingForm[this.subCodeIndex],
          false,
        ),
      );
      if (!valid && this.subValidate.errors != null) {
        this.subErrors = this.subValidate.errors;
      } else {
        this.subErrors = [];
      }
      return;
    }
    // validateForm
    this.applyRequiredFields(this.requiredFieldObjects);
    this.applyHintFields();
    this.autoFillAutoProcessedFields();
    const valid = this.validate(
      this.formDataArrayToObject(this.fullCodingForm, true),
    );
    if (!valid && this.validate.errors != null) {
      this.errors = this.validate.errors;
    } else {
      this.errors = [];
    }
  }

  async submitForm(): Promise<void> {
    // update original json objects with updated data
    this.fullCodingForm.forEach((cf: CodingFormData) => {
      const newKey = cf.header;
      const newVal = cf.value;
      const newObj = { [newKey]: newVal };
      Object.assign(this.oldFormData, newObj);
      if (cf.isChanged === false) {
        // delete this.oldFormData[cf.header];
      }
    });

    if (this.isWorkOrderForm) {
      this.$emit('patchWorkOrder', {
        inspectionJsonData: JSON.stringify(this.oldFormData),
        subInspectionJsonData: await this.formatSubInspections(),
      });
      return;
    }

    this.processingForm = true;
    const result = await this.patchCodingDetail({
      inspectionGuid: this.inspectionId,
      payload: {
        inspectionJsonData: JSON.stringify(this.oldFormData),
        subInspectionJsonData: await this.formatSubInspections(),
      },
    });

    if (result === true) {
      this.isPatched = true;
    }

    this.sortSubFullCoding();
    this.processingForm = false;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  getCodingFormDataValue(
    oldVal: any,
    pageData: any,
  ): string | number | boolean | any[] {
    if (oldVal) {
      if (pageData.type === 'array') {
        return Array.isArray(oldVal) ? oldVal : [];
      }
      if ((oldVal as string).length === 0) {
        // eslint-disable-next-line no-param-reassign
        oldVal = null;
      } else if (pageData.type === 'integer') {
        // eslint-disable-next-line no-param-reassign
        oldVal = parseInt(oldVal as string, 10);
      } else if (pageData.type === 'number') {
        // eslint-disable-next-line no-param-reassign
        oldVal = parseFloat(oldVal as string);
      }
      return oldVal;
    }

    if (oldVal === 0) {
      if (pageData.type === 'integer' || pageData.type === 'number') {
        return 0;
      }
    }
    if (pageData.type === 'string') return '';
    if (pageData.type === 'integer' || pageData.type === 'number') return null;
    if (pageData.type === 'boolean') return false;
    if (pageData.type === 'array') return [];
    return null;
  }

  /**
 * @description Used to add a pipe connection to the subschema
 */
  addPipeConnection(): void {
    const tempData = [];
    this.subPages.forEach((page) => {
      this.subDataStandardFormat.formpage
        .find((fp) => fp.pagename === page)
        .formpagedata.forEach((pageData) => {
          tempData.push({
            header: pageData.headername,
            // instead of null we should check data type and fill as appropriate
            // strings = '' ints/nums = 0
            value: this.getCodingFormDataValue(null, pageData),
          });
        });
    });
    let newTempDataSubFullCodingFormValue = 1;
    if (this.connectionsCount > 0
      && this.subFullCodingForm.length >= this.connectionsCount - 1
      && this.subFullCodingForm[this.connectionsCount - 1].length > 0
      && this.subFullCodingForm[this.connectionsCount - 1][0].value != null) {
      newTempDataSubFullCodingFormValue = this.subFullCodingForm[
        this.connectionsCount - 1][0].value + 1;
    }
    tempData.find((cd) => cd.header === 'PipeNumber').value = newTempDataSubFullCodingFormValue;
    this.subFullCodingForm.push(tempData);
    this.codingDetailInspections.push({
      subInspectionGuid: uuid.v4(),
      jsonData: tempData,
    });
    this.connectionsCount = this.subFullCodingForm.map((cf) => cf.find((it) => it.header === 'Structure')).length;
    this.sortSubFullCoding();
  }

  /**
 * @description Used to add an asset to the subschema
 */
  addAssetConnection(): void {
    const tempData = [];
    this.subPages.forEach((page) => {
      this.subDataStandardFormat.formpage
        .find((fp) => fp.pagename === page)
        .formpagedata.forEach((pageData) => {
          tempData.push({
            header: pageData.headername,
            // instead of null we should check data type and fill as appropriate
            // strings = '' ints/nums = 0
            value: this.getCodingFormDataValue(null, pageData),
          });
        });
    });
    let newTempDataSubFullCodingFormValue = 1;
    if (this.connectionsCount > 0
      && this.subFullCodingForm.length >= this.connectionsCount - 1
      && this.subFullCodingForm[this.connectionsCount - 1].length > 0
      && this.subFullCodingForm[this.connectionsCount - 1][0].value != null) {
      newTempDataSubFullCodingFormValue = this.subFullCodingForm[
        this.connectionsCount - 1][0].value + 1;
    }
    tempData.find((cd) => cd.header === 'AssetNumber').value = newTempDataSubFullCodingFormValue;
    this.subFullCodingForm.push(tempData);
    this.codingDetailInspections.push({
      subInspectionGuid: uuid.v4(),
      jsonData: tempData,
    });
    this.connectionsCount = this.subFullCodingForm.map((cf) => cf.find((it) => it.header === 'AssetNumber')).length;
    this.sortSubFullCoding();
  }

  async formatSubInspections(): Promise<SubInspectionData[]> {
    const retVal = [] as SubInspectionData[];
    this.sortSubFullCoding();
    // update old data with new data
    this.oldSubFormData.forEach((sfd, i) => {
      this.subFullCodingForm[i].forEach((cf: CodingFormData) => {
        // eslint-disable-next-line no-param-reassign
        sfd[cf.header] = cf.value;
      });
    });

    // check if new sub insps
    if (this.oldSubFormData.length < this.subFullCodingForm.length) {
      const newSubInspections = this.subFullCodingForm.splice(
        this.oldSubFormData.length,
      );
      // format new sub inspections
      newSubInspections.forEach((nsi, i) => {
        newSubInspections[i] = nsi.reduce(
          (a, v) => ({ ...a, [v.header]: v.value }),
          {},
        );
      });
      // add to old sub form data
      this.oldSubFormData.push(...newSubInspections);
    }

    // fill object
    this.oldSubFormData.forEach((element, i) => {
      const currCodeDetail = this.codingDetail.inspections[this.inspectionIndex]
        .subInspectionJsonData[i] as SubInspectionData;
      const subInspectionGuid = currCodeDetail && currCodeDetail.subInspectionGuid != null
        ? currCodeDetail.subInspectionGuid
        : this.codingDetailInspections[i].subInspectionGuid;

      retVal.push({
        subInspectionGuid,
        jsonData: JSON.stringify(element),
      });
    });

    // re-setup subFullCodingForm
    await this.setSubCodingForm([]);
    if (this.subDataStandardFormat != null) {
      this.oldSubFormData.forEach((oldData) => {
        const tempData = [];
        this.subPages.forEach((page) => {
          this.subDataStandardFormat.formpage
            .find((fp) => fp.pagename === page)
            .formpagedata.forEach((pageData) => {
              const oldVal = oldData[pageData.headername];
              tempData.push({
                header: pageData.headername,
                value: this.getCodingFormDataValue(oldVal, pageData),
              });
            });
        });
        this.subFullCodingForm.push(tempData);
      });
    }
    return retVal;
  }

  async deleteSubInspection(subInspIndex: number): Promise<void> {
    let deleteGuid = '';

    // check if this sub was remote
    if (subInspIndex <= this.oldSubFormData.length) {
      const subInspOrig = this.codingDetailInspections[
        subInspIndex
      ] as SubInspectionData;
      const inpectionExists = subInspOrig != null && subInspOrig.jsonData != null;

      if (inpectionExists) {
        // Delete remote
        deleteGuid = subInspOrig.subInspectionGuid;
        await this.deleteSubInspectionAction(deleteGuid);
      }
    }

    // Delete locally
    this.subFullCodingForm.splice(subInspIndex, 1);
    this.codingDetailInspections.splice(subInspIndex, 1);
    if (this.oldSubFormData.length >= subInspIndex) {
      this.oldSubFormData.splice(subInspIndex, 1);
    }

    this.connectionsCount = this.subFullCodingForm.length;
    this.selectedPipeConnection -= 1;
    this.sortSubFullCoding();
  }

  sortSubFullCoding(): any[] {
    if (this.subFullCodingForm.length === 0) {
      return [];
    }

    const subSchemaNumberHeader = this.getPageHeader();

    // Remove duplicates and sort into order
    this.oldSubFormData = [
      ...new Map(
        this.oldSubFormData.map((item) => [item[subSchemaNumberHeader], item]),
      ).values(),
    ].sort((a, b) => a[subSchemaNumberHeader] - b[subSchemaNumberHeader]);
    const subFullCodingForm = [
      ...new Map(
        this.subFullCodingForm.map((item) => [item[0].value, item]),
      ).values(),
    ].sort((a, b) => a[0].value - b[0].value);

    // Enfore numbering
    subFullCodingForm.forEach((subForm, index) => {
      // eslint-disable-next-line no-param-reassign
      subForm[0].value = index + 1;
    });

    if (subSchemaNumberHeader === 'PipeNumber') {
      subFullCodingForm[0].find(
        (header) => header.header === 'ClockPositions',
      ).value = 6;
    }

    this.setSubCodingForm(subFullCodingForm);
    return subFullCodingForm;
  }

  // false means not empty
  isEmptyValue(value: string | number | boolean | []): boolean {
    if (typeof value === 'string') return (value as string).length === 0;
    if (Array.isArray(value)) return value.length === 0;
    return false;
  }

  checkCheckBoxValues(): void {
    // check full coding form
    const fcCheckBoxes = this.fullCodingForm.filter(
      (fc: CodingFormData) => this.checkBoxHeaderNumbers.includes(fc.headerNumber),
    );
    const checkedBoxes = fcCheckBoxes.find(
      (fc: CodingFormData) => fc.value === true,
    );
    if (checkedBoxes !== undefined) {
      for (let i = 0; i < fcCheckBoxes.length; i += 1) {
        fcCheckBoxes[i].isChanged = true;
      }
    }
    // check sub  coding form
    const scCheckBoxes = this.subFullCodingForm.filter(
      (fc: CodingFormData) => this.checkBoxHeaderNumbers.includes(fc.headerNumber),
    );
    const scCheckedBoxes = scCheckBoxes.find(
      (fc: CodingFormData) => fc.value === true,
    );
    if (scCheckedBoxes !== undefined) {
      for (let i = 0; i < scCheckBoxes.length; i += 1) {
        scCheckBoxes[i].isChanged = true;
      }
    }
  }

  popOutForm(): void {
    this.$emit('togglePopout');
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  beforeUnloadPop(_event: any): void {
    window.opener.postMessage(
      {
        fullCodingForm: this.fullCodingForm,
        subFullCodingForm: this.subFullCodingForm,
      },
      window.location.origin,
    );
  }

  getErrorCount(formPageName: string): number {
    let retVal = 0;
    const formPage = this.dataStandardFormat.formpage.find(
      (fp) => fp.pagename === formPageName,
    );
    if (formPage !== null && formPage !== undefined) {
      formPage.formpagedata.forEach((d) => {
        if (
          this.errors.find((e) => e.instancePath.split('/')[1] === d.headername)
        ) {
          retVal += 1;
        }
      });
    }
    return retVal;
  }

  findRequiredFields(schema: any): ConditionalRequirement {
    if (schema?.required) {
      return {
        conditionalField: this.ALWAYSREQUIRED,
        comparerValue: true,
        requiredOnThen: schema.required as string[],
        // everything else can be empty
        requiredOnElse: [],
        conditionalsOnElse: [],
        conditionalsOnThen: [],
        optionalOnElse: [],
        optionalOnThen: [],
      };
    }
    return this.findRequiredFieldsRecursive(schema);
  }

  findRequiredFieldsRecursive(schema: any): ConditionalRequirement {
    if (schema == null) return null;
    const isOf = this.checkForOf(schema);
    if (isOf) {
      return {
        conditionalField: this.ALWAYSREQUIRED,
        comparerValue: true,
        conditionalsOnThen: (() => {
          const retVal: ConditionalRequirement[] = [];
          schema[isOf].forEach((element) => {
            const newRequirement = this.findRequiredFieldsRecursive(element);
            if (newRequirement != null) retVal.push(newRequirement);
          });
          return retVal;
        })(),
        // everything else can be empty
        requiredOnElse: [],
        requiredOnThen: [],
        conditionalsOnElse: [],
        optionalOnElse: [],
        optionalOnThen: [],
      };
    }
    const conditionalRequirement: ConditionalRequirement = {
      conditionalField: '',
      comparerValue: '',
      requiredOnElse: [],
      conditionalsOnElse: [],
      requiredOnThen: [],
      conditionalsOnThen: [],
      optionalOnElse: [],
      optionalOnThen: [],
    };
    // check for property and const or enum
    if (schema.if) {
      // eslint-disable-next-line prefer-destructuring
      const propName = Object.entries(schema.if.properties)[0][0];
      const path = schema.if.properties[propName];
      conditionalRequirement.conditionalField = propName;
      // eslint-disable-next-line prefer-destructuring
      conditionalRequirement.comparerValue = Object.entries(path)[0][1] as any;
    }
    if (schema.else) {
      if (schema.else.required) {
        conditionalRequirement.requiredOnElse = schema.else.required;
      }
      const keys = Object.keys(schema.else);
      keys.forEach((key) => {
        if (ConditionalType.includes(key)) {
          conditionalRequirement.conditionalsOnElse.push(
            this.findRequiredFieldsRecursive(schema.else),
          );
        }
        if (key === 'properties') {
          Object.entries(schema.else.properties).forEach((entry) => {
            const property = this.checkProperties(entry);
            if (property) {
              conditionalRequirement.optionalOnElse.push(property);
            }
          });
        }
      });
    }
    if (schema.then) {
      if (schema.then.required) {
        conditionalRequirement.requiredOnThen = schema.then.required;
      }
      const keys = Object.keys(schema.then);
      keys.forEach((key) => {
        if (ConditionalType.includes(key)) {
          conditionalRequirement.conditionalsOnThen.push(
            this.findRequiredFieldsRecursive(schema.then),
          );
        }
        if (key === 'properties') {
          Object.entries(schema.then.properties).forEach((entry) => {
            const property = this.checkProperties(entry);
            if (property) {
              conditionalRequirement.optionalOnThen.push(property);
            }
          });
        }
      });
    }
    return conditionalRequirement;
  }

  checkForOf(schema: any): string | null {
    if (schema.allOf) {
      return 'allOf';
    }
    if (schema.anyOf) {
      return 'anyOf';
    }
    return null;
  }

  // return property name when minlength is 0 or type includes null
  checkProperties(entry: any): string | undefined {
    if (entry[1].minLength !== undefined || entry[1].type !== undefined) {
      if (entry[1].minLength === 0) {
        return entry[0];
      }
      if (Array.isArray(entry[1].type) && entry[1].type.includes('null')) {
        return entry[0];
      }
    }
    return undefined;
  }

  applyHintFields(): void {
    this.currentFormPageData.forEach((form: FormPageData) => {
      // look for hints
      if (
        this.dataStandardJson?.Requirements
        && Object.keys(this.dataStandardJson.Requirements.HeaderValues).includes(
          form.headername,
        )
      ) {
        form.hint = this.dataStandardJson.Requirements.HeaderValues[form.headername];
      }
    });
  }

  applyRequiredFields(conditionalRequirement: ConditionalRequirement): void {
    // clear required fields
    this.requiredFields.clear();
    this.currentFormPageData.forEach((fpd) => {
      fpd.required = false;
    });
    this.applyRequiredFieldsRecursive(conditionalRequirement);
    // apply required to current form page
    this.requiredFields.forEach((value, requiredField) => {
      const field = this.currentFormPageData.find(
        (fpd) => fpd.headername === requiredField,
      );
      if (field !== undefined) {
        field.required = value;
      }
    });
  }

  applyRequiredFieldsRecursive(
    conditionalRequirement: ConditionalRequirement,
  ): void {
    let conditionalMet = false;
    const { conditionalField, comparerValue } = conditionalRequirement;
    // find field value
    const fieldValue = conditionalField === this.ALWAYSREQUIRED
      ? true
      : this.fullCodingForm.find((cf) => cf.header === conditionalField)
        ?.value;

    // compare to comparer values
    // skip if field is undefined
    if (fieldValue !== undefined) {
      // see if comparer value is valid depending on the conditional type
      // have to check it differently if it's an array
      if (Array.isArray(comparerValue)) {
        if (comparerValue.some((cv) => cv === (fieldValue as string))) {
          conditionalMet = true;
        }
      } else if (comparerValue === fieldValue) {
        conditionalMet = true;
      }
      // true, process on thens
      if (conditionalMet) {
        const { requiredOnThen, conditionalsOnThen, optionalOnThen } = conditionalRequirement;
        requiredOnThen.forEach((field) => {
          this.requiredFields.set(field, true);
        });
        optionalOnThen.forEach((field) => {
          this.requiredFields.set(field, false);
        });
        conditionalsOnThen.forEach((conditional) => {
          this.applyRequiredFieldsRecursive(conditional);
        });
      }
      // false, process on elses
      if (!conditionalMet) {
        const { requiredOnElse, conditionalsOnElse, optionalOnElse } = conditionalRequirement;
        requiredOnElse.forEach((field) => {
          this.requiredFields.set(field, true);
        });
        optionalOnElse.forEach((field) => {
          this.requiredFields.set(field, false);
        });
        conditionalsOnElse.forEach((conditional) => {
          this.applyRequiredFieldsRecursive(conditional);
        });
      }
    }
  }

  async reset(): Promise<void> {
    if (this.isPopout) return;
    this.pages = [];
    this.subPages = [];
    this.connectionsCount = 0;
    this.subDataFormFlag = false;
    this.subCodeIndex = -1;
    this.errors = [];
    this.subErrors = [];
    this.errorExists = false;
    this.error = '';
    this.selectedType = '';
    this.selectedPipeConnection = 0;
    this.currentFormPageData = [];
    this.oldSubFormData = [];
    this.checkBoxHeaderNumbers = [];
    this.oldFormData = {};
    this.codingDetailInspections = [];

    await this.setCodingForm([]);
    await this.setSubCodingForm([]);
  }

  getBackgroundColor(): any {
    const retVal = this.selectedType === 'Report'
      ? { background: '#CCCCCC' }
      : { background: 'white' };
    return retVal;
  }

  generateReport(): void {
    this.hasDownloaded = false;
    // eslint-disable-next-line dot-notation
    this.$refs.html2Pdf['generatePdf']();
  }

  autoFillAutoProcessedFields(): void {
    this.currentFormPageData.forEach((form: FormPageData) => {
      // look for hints
      if (form.hint != null && form.hint !== '') {
        const splitHint = form.hint.split(' ');
        const item = this.fullCodingForm.find(
          (f) => f.header === form.headername,
        );
        const val1 = this.fullCodingForm.find(
          (f) => f.header === splitHint[0],
        ).value;
        const val2 = this.fullCodingForm.find(
          (f) => f.header === splitHint[2],
        ).value;
        const operator = splitHint[1];
        switch (operator) {
          case '+':
            item.value = (val1 as number) + (val2 as number);
            break;
          case '-':
            item.value = (val1 as number) - (val2 as number);
            break;
          default:
            throw new Error('Invalid operator');
        }
      }
    });
  }
}
