













































import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import IntegrityTable from '@/components/IntegrityTable/IntegrityTable.vue';
import { namespace } from 'vuex-class';
import { ScopeHistoryMutations } from '@/store/scopeHistory/mutations';
import { ScopeHistoryActions } from '@/store/scopeHistory/actions';

const scopeHistoryModule = namespace('scopeHistory');

@Component({
  components: {
    IntegrityTable,
  },
})
export default class HistoryDropDown extends Vue {
  @Prop({ default: [] }) readonly data: any[];

  @Prop({ default: false }) readonly isEditMode: boolean

  @Prop({ default: false }) readonly loading: boolean;

  @Prop({ default: ['d1edc644-292c-4fcb-b333-73474f95467a'] }) readonly projectGuids: string[];

  @scopeHistoryModule.Mutation(ScopeHistoryMutations.SET_ASSET_WORKORDER_HISTORY_DATA)
    setAssetWorkOrderHistoryData;

  @scopeHistoryModule.Action(
    ScopeHistoryActions.PATCH_ASSET_WORKORDER_HISTORY_DATA,
  ) patchAssetWorkOrderHistoryData;

  @scopeHistoryModule.State('assetPatchWorkOrderLoading') assetPatchWorkOrderLoading: boolean;

  @scopeHistoryModule.State('assetPatchWorkOrderError') assetPatchWorkOrderError: string | undefined;

  tableData: any[] = [];

  tableHeaders = [
    {
      text: 'Deployment', value: 'deployment', sortable: true, filterable: false,
    },
    {
      text: '', value: 'hasCustomerDeliverables', sortable: false, filterable: false,
    },
    {
      text: 'Direction', value: 'direction', sortable: true, filterable: true,
    },
    {
      text: 'WO Type', value: 'woType', sortable: true, filterable: true,
    },
    {
      text: 'WO Status', value: 'woStatus', sortable: true, filterable: true,
    },
    {
      text: 'Collection Results', value: 'collectionResults', sortable: true, filterable: true,
    },
    {
      text: 'Notes', value: 'notes', sortable: true, filterable: true,
    },
    {
      text: 'Length Surveyed', value: 'length', sortable: true, filterable: true,
    },
    {
      text: 'Media?', value: 'media', sortable: true, filterable: true,
    },
    {
      text: 'Date Performed', value: 'date', sortable: true, filterable: true,
    },
    {
      text: 'Surveyor', value: 'surveyor', sortable: true, filterable: true,
    },
    {
      text: 'Active',
      value: 'active',
      editable: true,
      options: [
        { name: 'Yes', value: true },
        { name: 'No', value: false },
      ],
      sortable: true,
      filterable: true,
    },
  ];

  matchFilters = [
    {
      header: 'deployment', type: 'string', value: '', method: '', options: [], tempValue: '', open: false,
    },
    {
      header: 'hasCustomerDeliverables', type: 'string', value: '', method: '', options: [], tempValue: '', open: false,
    },
    {
      header: 'direction', type: 'string', value: '', method: '', options: [], tempValue: '', open: false,
    },
    {
      header: 'woType', type: 'string', value: '', method: '', options: [], tempValue: '', open: false,
    },
    {
      header: 'woStatus', type: 'string', value: '', method: '', options: [], tempValue: '', open: false,
    },
    {
      header: 'collectionResults', type: 'string', value: '', method: '', options: [], tempValue: '', open: false,
    },
    {
      header: 'notes', type: 'string', value: '', method: '', options: [], tempValue: '', open: false,
    },
    {
      header: 'length', type: 'number', value: '', method: '', options: [], tempValue: '', open: false,
    },
    {
      header: 'media', type: 'string', value: '', method: '', options: [], tempValue: '', open: false,
    },
    {
      header: 'date', type: 'string', value: '', method: '', options: [], tempValue: '', open: false,
    },
    {
      header: 'surveyor', type: 'string', value: '', method: '', options: [], tempValue: '', open: false,
    },
    {
      header: 'active', type: 'string', value: '', method: '', options: [], tempValue: '', open: false,
    },
  ]

  sortBy = 'date';

  filterValues = {};

  blankMatchFilters = {}

  showSnackbar = false as boolean;

  snackbarColor = '' as string;

  snackbarMessage = '' as string;

  mounted(): void {
    this.updateFilterValues();
  }

  @Watch('data', { deep: true })
  onDataChange(): void {
    const tempObject = this.data != null ? this.data : [];
    const returnObject = [];
    // I am changing the text of the booleans to be Yes/No instead of true/false
    tempObject.forEach((dep) => {
      returnObject.push({
        ...dep,
        ...{
          media: dep.media ? 'Yes' : 'No',
          active: dep.active ? 'Yes' : 'No',
        },
      });
    });
    this.tableData = returnObject;
    this.updateFilterValues();
  }

  updateFilterValues(): void {
    this.filterValues = {};

    function compareNumbers(a, b) {
      return a - b;
    }
    this.tableData.forEach((entry: any) => {
      // Populate filterValues with unique values for each column
      this.tableHeaders.forEach((header) => {
        if (header.value === 'hasCustomerDeliverables') return;

        let checkVal: string;
        if (this.matchFilters.find((value) => value.header === header.value)) {
          if (!this.filterValues[header.value]) {
            this.filterValues[header.value] = [];
          }

          try {
            checkVal = entry[header.value];
          } catch {
            checkVal = '';
          }

          if (
            checkVal != null
              && checkVal !== ''
              && Array.isArray(this.filterValues[header.value])
              && !this.filterValues[header.value].includes(checkVal)
          ) {
            this.filterValues[header.value].push(checkVal);
          }

          this.filterValues[header.value].sort();
          this.filterValues[header.value].sort(compareNumbers);
        }
      });
    });
  }

  clearAllFilters(): void {
    (this.$refs.collectionSubTable as IntegrityTable).clearAllMatchFilters();
  }

  /**
     * @description Gets the params for the deployment page
    */
  getDeploymentPageParams(): any {
    return { ids: JSON.stringify(this.projectGuids) };
  }

  /**
     * Gets the deployment guid query filter from the requested deployment/workorder item
     */
  getDeploymentsPageQuery(item: any): any {
    const returnObject = {
      deploymentNumber: [],
    };
    if (item?.deploymentGuid != null) {
      returnObject.deploymentNumber.push(item.deploymentGuid);
    }
    return returnObject;
  }

  /**
     * @description Called when the deployment is edited
     */
  async deploymentEdited(deployment: any): Promise<void> {
    if (deployment?.deploymentGuid == null) {
      // Reset the data since we don't have a guid we're trying to edit
      this.onDataChange();
    } else {
      let active = false;
      if (deployment?.active != null && deployment.active.toLowerCase() === 'yes') {
        active = true;
      }
      await this.patchAssetWorkOrderHistoryData({
        guid: deployment.deploymentGuid,
        dto: {
          active,
        },
      });
      if (this.assetPatchWorkOrderError == null) {
        const newData = [...this.data];
        const editedData = newData.find(
          (dep) => dep.deploymentGuid === deployment.deploymentGuid,
        );
        if (editedData != null) {
          editedData.active = active;
          this.setAssetWorkOrderHistoryData(newData);
          this.snackbarColor = 'green';
          this.snackbarMessage = 'Deployment updated successfully';
          this.showSnackbar = true;
        } else {
          this.snackbarColor = 'red';
          this.snackbarMessage = 'An error occured while updating the deployment';
          this.showSnackbar = true;
        }
      } else {
        this.snackbarColor = 'red';
        this.snackbarMessage = this.assetPatchWorkOrderError;
        this.showSnackbar = true;
      }
    }
  }

  handleInfo(): void {
    console.error('NOT IMPLEMENTED');
  }
}
