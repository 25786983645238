

















































































































































































































































































































































































































































































































































































































































































/* eslint-disable @typescript-eslint/no-explicit-any */
import { InspectionData, Condition, AssetData } from '@/store/asset/types';
import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import { InspectionResponse } from '@/components/CodingForm/types';
import ReportObservation from '../ReportObservation.vue';
import PipeConditionReport from '../../PipeConditionReport/PipeConditionReport.vue';
import ReportFooter from '../ReportFooter.vue';
import util from '../util';
import { macpReportFields } from './types';

@Component({
  components: {
    ReportFooter,
    ReportObservation,
    PipeConditionReport,
  },
})
export default class MACP2 extends Vue {
  @Prop() readonly assetName!: string;

  @Prop() readonly inspection!: InspectionData;

  @Prop() readonly codingDetail: InspectionResponse;

  @Prop() readonly asset!: AssetData;

  @Prop({ default: true }) readonly displayImperial!: boolean;

  @Prop({ default: -1 }) readonly fullVideoTime: number;

  workOrderJson: any;

  howManyIterations = 0;

  inspectionsGrouped: Array<InspectionData> = [];

  get report(): Record<string, unknown> {
    return this.inspection ? this.inspection.report : {};
  }

  get codingDetailReport(): Record<string, unknown> {
    return this.codingDetail ? JSON.parse(this.codingDetail.inspections[0].jsonData) : {};
  }

  get pageCountSkip(): number {
    const isPipeConnectionPage = (this.reportPipeConnections.length > 0) ? 1 : 0;
    return 3 + isPipeConnectionPage;
  }

  get conditionReportPages(): Array<Condition[]> {
    let index = 1;
    let pageCount = 0;
    let page = [];
    const pages = [[]];

    if (this.inspection.conditionReport.length === 0) {
      return [];
    }

    if (this.inspection && this.inspection.conditionReport) {
      const sortedArr = [...this.inspection.conditionReport]
        .sort((a, b) => a.distance - b.distance);
      sortedArr.forEach((condRep) => {
        pages[pageCount].push(condRep);

        if (index % 3 === 0 && index !== this.inspection.conditionReport.length) {
          page = [];
          pages.push(page);
          pageCount += 1;
        }

        index += 1;
      });
    }
    return pages;
  }

  reportFields: macpReportFields | any = {};

  assetStreet = this.report !== undefined && this.report.location !== undefined ? (this.report as any).location['street_L1_2'] : '';

  assetCity = this.report !== undefined && this.report.location !== undefined ? (this.report as any).location['city_L1_2'] : '';

  @Watch('inspection')
  onInspectionChange(): void {
    this.inspectionsGrouped = [];
    this.howManyIterations = 0;
    if (
      this.inspection != null
      && this.inspection.conditionReport != null
      && this.inspection.conditionReport.length > 0
    ) {
      this.howManyIterations = Math.ceil(
        this.inspection.conditionReport.length / 30,
      );

      let tempList = [];
      this.inspection.conditionReport.forEach((e, i) => {
        if (i % 30 === 0 && i !== 0) {
          const newInspection: InspectionData = {
            name: this.inspection.name,
            guid: this.inspection.guid,
            inspectionDate:
              this.inspection.inspectionDate != null
                ? this.inspection.inspectionDate
                : null,
            macpLevel: 'Level 1',
            maxPayout: this.inspection.maxPayout,
            measurementUnits: this.inspection.measurementUnits,
            conditionReport: this.inspection.conditionReport,
            flatImage: this.inspection.flatImage,
            video: this.inspection.video,
            wmVideo: this.inspection.wmVideo,
            msiExist: this.inspection.msiExist,
            model: this.inspection.model,
            deliverablesJson: this.inspection.deliverablesJson,
            report: this.inspection.report,
            payoutIPD:
              this.inspection.payoutIPD != null
                ? this.inspection.payoutIPD
                : null,
            version: this.inspection.version,
          };
          newInspection.conditionReport = tempList;
          this.inspectionsGrouped.push(newInspection);
          tempList = [];
        }
        tempList.push(e);
      });
      if (tempList.length > 0) {
        const newInspection: InspectionData = {
          name: this.inspection.name,
          guid: this.inspection.guid,
          inspectionDate:
            this.inspection.inspectionDate != null
              ? this.inspection.inspectionDate
              : null,
          macpLevel: 'Level 1',
          maxPayout: this.inspection.maxPayout,
          measurementUnits: this.inspection.measurementUnits,
          conditionReport: this.inspection.conditionReport,
          flatImage: this.inspection.flatImage,
          video: this.inspection.video,
          wmVideo: this.inspection.wmVideo,
          msiExist: this.inspection.msiExist,
          model: this.inspection.model,
          deliverablesJson: this.inspection.deliverablesJson,
          report: this.inspection.report,
          payoutIPD:
            this.inspection.payoutIPD != null
              ? this.inspection.payoutIPD
              : null,
          version: this.inspection.version,
        };
        newInspection.conditionReport = tempList;
        this.inspectionsGrouped.push(newInspection);
      }
    }
  }

  mounted(): void {
    this.onInspectionChange();
    if (this.codingDetail !== undefined) {
      this.workOrderJson = JSON.parse(this.codingDetail.inspections[0].jsonData);
    }
    if (this.report !== undefined) {
      this.fillReportFields();
    }
  }

  // eslint-disable-next-line class-methods-use-this
  formatText(text: string): string {
    if (!text) return '';
    let newText = text;

    newText = newText.replace(/&gt;/g, '>');
    newText = newText.replace(/&lt;/g, '<');
    newText = newText.replace(/&quot;/g, '"');

    return newText;
  }

  getDisplayDistanceInMM(value: number): string {
    try {
    // eslint-disable-next-line eqeqeq
      return value && value != 0
        ? util.getDisplayDistanceInMM(this.displayImperial, value)
          + util.getDistanceUnitsInMM(this.displayImperial)
        : '';
    } catch {
      return '';
    }
  }

  getDisplayDistanceFtM(value: number): string {
    try {
    // eslint-disable-next-line eqeqeq
      return value && value != 0
        ? util.getDisplayDistanceFtM(this.displayImperial, value)
          + util.getDistanceUnitsFtM(this.displayImperial)
        : '';
    } catch {
      return '';
    }
  }

  fillReportFields(): void {
    if (Object.keys(this.codingDetailReport).length > 0) {
      this.reportFields = {
        street_L1_2: this.codingDetailReport.Address ? this.codingDetailReport.Address : '',
        city_L1_2: this.codingDetailReport.City ? this.codingDetailReport.City : '',
        inspectionStatus_L1_2: this.codingDetailReport.MHInspectionStatus ? this.codingDetailReport.MHInspectionStatus : '',
        inspectionLevel_L1_2: this.codingDetailReport.MHInspectionLevel ? this.codingDetailReport.MHInspectionLevel : '',
        overallQuick: '',
        overallIndex: '',
        overallRating: '',
        structuralQuick: '',
        structuralIndex: '',
        structuralRating: '',
        omQuick: '',
        omIndex: '',
        omRating: '',
        owner: this.codingDetailReport.Owner ? this.codingDetailReport.Owner : '',
        customer: this.codingDetailReport.Customer ? this.codingDetailReport.Customer : '',
        mhUse_L2: this.codingDetailReport.SewerUse ? this.codingDetailReport.SewerUse : '',
        accessType_L1_2: this.codingDetailReport.MHAccessType ? this.codingDetailReport.MHAccessType : '',
        yearConstructed: this.codingDetailReport.YearBuilt ? this.codingDetailReport.YearBuilt : '',
        yearRenewed: this.codingDetailReport.YearRenewed ? this.codingDetailReport.YearRenewed : '',
        evidenceofSurcharge_L2: this.codingDetailReport.MHEvidenceSurcharge ? this.codingDetailReport.MHEvidenceSurcharge : '',
        northing: this.codingDetailReport.Northing ? this.codingDetailReport.Northing : '',
        easting: this.codingDetailReport.Easting ? this.codingDetailReport.Easting : '',
        elevation: this.codingDetailReport.Elevation ? this.codingDetailReport.Elevation : '',
        verticalDatum: this.codingDetailReport.VerticalDatum ? this.codingDetailReport.VerticalDatum : '',
        gpsAccuracy: this.codingDetailReport.GPSAccuracy ? this.codingDetailReport.GPSAccuracy : '',
        additionalInformation: this.codingDetailReport.AdditionalInfo ? this.codingDetailReport.AdditionalInfo : '',
        coverType: '',
        shape_L2: this.codingDetailReport.MHCoverShape ? this.codingDetailReport.MHCoverShape : '',
        coverMaterial_L2: this.codingDetailReport.MHCoverMaterial ? this.codingDetailReport.MHCoverMaterial : '',
        holeNumber_L2: this.codingDetailReport.MHCoverMaterial ? this.codingDetailReport.MHCoverMaterial : '',
        cover_FrameFit_L2: this.codingDetailReport.MHCoverFrameFit ? this.codingDetailReport.MHCoverFrameFit : '',
        insertType_L2: '',
        coverInsertCondition: '',
        ringType_L1_2: this.codingDetailReport.MHAdjustmentRingType ? this.codingDetailReport.MHAdjustmentRingType : '',
        ringMaterial_L2: '',
        ringCondition_L1_2: [],
        frameMaterial_L2: '',
        sealCondition_L2: '',
        sealInflow_L2: this.codingDetailReport.MHInfiltrationInflow_FrameSeal ? this.codingDetailReport.MHInfiltrationInflow_FrameSeal : '',
        frameCondition_L1_2: '',
        chimneyPresent_L2: this.codingDetailReport.MHChimneyPresent ? this.codingDetailReport.MHChimneyPresent : '',
        chimneyI_I: '',
        chimneyfirstMaterial_L2: '',
        secondMaterial: '',
        liningInterior: '',
        liningExterior: '',
        chimneyCondition_L1: this.codingDetailReport.MHCondition_Chimney ? this.codingDetailReport.MHCondition_Chimney : '',
        conetype_L2: this.codingDetailReport.MHConeType ? this.codingDetailReport.MHConeType : '',
        first_Material_L2: '',
        second_Material_L2: '',
        coneCondition_L1: this.codingDetailReport.MHCondition_Cone ? this.codingDetailReport.MHCondition_Cone : '',
        conefirstMaterial_L2: '',
        wallfirstMaterial_L2: '',
        secondMaterial_L2: '',
        wallLiningInterior: this.codingDetailReport.MHLiningMaterial_Wall_Interior ? this.codingDetailReport.MHLiningMaterial_Wall_Interior : '',
        wallLiningExterior: this.codingDetailReport.MHLiningMaterial_Wall_Exterior ? this.codingDetailReport.MHLiningMaterial_Wall_Exterior : '',
        wallCondition_L1: this.codingDetailReport.MHCondition_Wall ? this.codingDetailReport.MHCondition_Wall : '',
        benchPresent_L2: this.codingDetailReport.MHBenchPresent ? this.codingDetailReport.MHBenchPresent : '',
        benchMaterial_L2: this.codingDetailReport.MHMaterial_Bench ? this.codingDetailReport.MHMaterial_Bench : '',
        benchLining: this.codingDetailReport.MHLiningMaterial_Bench ? this.codingDetailReport.MHLiningMaterial_Bench : '',
        benchCondition_L1: this.codingDetailReport.MHCondition_Bench ? this.codingDetailReport.MHCondition_Bench : '',
        channelInstalled_L2: this.codingDetailReport.MHChannelInstalled ? this.codingDetailReport.MHChannelInstalled : '',
        channelMaterial_L2: this.codingDetailReport.MHMaterial_Channel ? this.codingDetailReport.MHMaterial_Channel : '',
        exposure_L2: this.codingDetailReport.MHChannelExposure ? this.codingDetailReport.MHChannelExposure : '',
        channelCondition_L1: this.codingDetailReport.MHCondition_Channel ? this.codingDetailReport.MHCondition_Channel : '',
        channeltype_L2: this.codingDetailReport.MHChannelType ? this.codingDetailReport.MHChannelType : '',
        number_L2: '',
        material_L2: '',
        surveyedBy_L1_2: this.codingDetailReport.SurveyedBy ? this.codingDetailReport.SurveyedBy : '',
        reviewedBy: this.codingDetailReport.ReviewedBy ? this.codingDetailReport.ReviewedBy : '',
        weather: this.codingDetailReport.WeatherType ? this.codingDetailReport.WeatherType : '',
        purposeofSurvey_L1_2: this.codingDetailReport.MHPurpose ? this.codingDetailReport.MHPurpose : '',
        certificateNumber_L1_2: this.codingDetailReport.CertificateNumber ? this.codingDetailReport.CertificateNumber : '',
        reviewerCertificateNo: this.codingDetailReport.ReviewerCertificateNumber ? this.codingDetailReport.ReviewerCertificateNumber : '',
        pre_Cleaning_L2: this.codingDetailReport.PreCleaning ? this.codingDetailReport.PreCleaning : '',
        p_ONumber: this.codingDetailReport.PONumber ? this.codingDetailReport.PONumber : '',
        mediaLabel: this.codingDetailReport.MediaLabel ? this.codingDetailReport.MediaLabel : '',
        date_L1_2: this.codingDetailReport.InspDate ? this.codingDetailReport.InspDate : '',
        sheetNumber_L1_2: this.codingDetailReport.SheetNumber ? this.codingDetailReport.SheetNumber : '',
        dateCleaned: this.codingDetailReport.DateCleaned ? this.codingDetailReport.DateCleaned : '',
        workOrder: this.codingDetailReport.WorkOrderNum ? this.codingDetailReport.WorkOrderNum : '',
        consequenceofFailure: this.codingDetailReport.ConsequenceOfFailure ? this.codingDetailReport.ConsequenceOfFailure : '',
        rimtoInvert_L2: this.codingDetailReport.RimtoInvert
          ? this.codingDetailReport.RimtoInvert : 0,
        rimtoGrade_L2: this.codingDetailReport.RimtoGrade ? this.codingDetailReport.RimtoGrade : 0,
        gradetoInvert_L2: this.codingDetailReport.GradetoInvert
          ? this.codingDetailReport.GradetoInvert : 0,
        rimtoGradeExposed: this.codingDetailReport.RimToGradeExposed
          ? this.codingDetailReport.RimToGradeExposed : 0,
        size_L2: this.codingDetailReport.CoverSize ? this.codingDetailReport.CoverSize : 0,
        sizeWidth_L2: this.codingDetailReport.CoverSizeWidth
          ? this.codingDetailReport.CoverSizeWidth : 0,
        centerCoverSize: this.codingDetailReport.CenterCoverSize
          ? this.codingDetailReport.CenterCoverSize : 0,
        bearingSurfaceDiameter_L2: this.codingDetailReport.CoverBearingSurfaceDiameter
          ? this.codingDetailReport.CoverBearingSurfaceDiameter : 0,
        coverbearingSurfaceWidth_L2: this.codingDetailReport.CoverBearingSurfaceDiaWidth
          ? this.codingDetailReport.CoverBearingSurfaceDiaWidth : 0,
        ringHeight: this.codingDetailReport.AdjustmentRingHeight
          ? this.codingDetailReport.AdjustmentRingHeight : 0,
        framebearingSurfaceWidth_L2: this.codingDetailReport.FrameBearingSurfaceWidth
          ? this.codingDetailReport.FrameBearingSurfaceWidth : 0,
        coverbearingSurfaceDepth_L2: 0,
        framebearingSurfaceDepth_L2: this.codingDetailReport.FrameBearingSurfaceDepth
          ? this.codingDetailReport.FrameBearingSurfaceDepth : 0,
        clearOpeningDiam_L2: this.codingDetailReport.FrameClearOpeningDia
          ? this.codingDetailReport.FrameClearOpeningDia : 0,
        clearOpeningWidth_L2: 0,
        frameDepth: this.codingDetailReport.FrameDepth ? this.codingDetailReport.FrameDepth : 0,
        offsetDistance_L2: this.codingDetailReport.FrameOffsetDistance
          ? this.codingDetailReport.FrameOffsetDistance : 0,
        chimneyDepth_L2: this.codingDetailReport.ChimneyDepth
          ? this.codingDetailReport.ChimneyDepth : 0,
        chimneyclearOpening: this.codingDetailReport.ChimneyClearOpening
          ? this.codingDetailReport.ChimneyClearOpening : 0,
        coneclearOpening: 0,
        conedepth_L2: this.codingDetailReport.ConeDepth ? this.codingDetailReport.ConeDepth : 0,
        walldepth_L2: this.codingDetailReport.WallDepth ? this.codingDetailReport.WallDepth : 0,
        wallDiameter_Length: this.codingDetailReport.WallDiameter_Length
          ? this.codingDetailReport.WallDiameter_Length : 0,
        wallbySize_Width: this.codingDetailReport.WallDiameter_Width
          ? this.codingDetailReport.WallDiameter_Width : 0,
        holeDiameter_Vent_L2: this.codingDetailReport.MHHoleDiameter
          ? this.codingDetailReport.MHHoleDiameter : '',
        pipeConnections: [],
      };
      return;
    }
    if (Object.keys(this.report).length > 0) {
      this.reportFields = {
        street_L1_2: this.report['location'] ? this.report['location']['street_L1_2'] : '',
        city_L1_2: this.report['location'] ? this.report['location']['city_L1_2'] : '',
        inspectionStatus_L1_2: this.report['generalInformation'] ? this.report['generalInformation']['inspectionStatus_L1_2'] : '',
        inspectionLevel_L1_2: this.report['generalInformation'] ? this.report['generalInformation']['inspectionLevel_L1_2'] : '',
        overallQuick: this.report['overallScoring'] ? this.report['overallScoring']['overallQuick'] : '',
        overallIndex: this.report['overallScoring'] ? this.report['overallScoring']['overallIndex'] : '',
        overallRating: this.report['overallScoring'] ? this.report['overallScoring']['overallRating'] : '',
        structuralQuick: this.report['overallScoring'] ? this.report['overallScoring']['structuralQuick'] : '',
        structuralIndex: this.report['overallScoring'] ? this.report['overallScoring']['structuralIndex'] : '',
        structuralRating: this.report['overallScoring'] ? this.report['overallScoring']['structuralRating'] : '',
        omQuick: this.report['overallScoring'] ? this.report['overallScoring']['omQuick'] : '',
        omIndex: this.report['overallScoring'] ? this.report['overallScoring']['omIndex'] : '',
        omRating: this.report['overallScoring'] ? this.report['overallScoring']['omRating'] : '',
        owner: this.report['generalInformation'] ? this.report['generalInformation']['owner'] : '',
        customer: this.report['generalInformation'] ? this.report['generalInformation']['customer'] : '',
        mhUse_L2: this.report['manhole'] ? this.report['manhole']['mhUse_L2'] : '',
        accessType_L1_2: this.report['manhole'] ? this.report['manhole']['accessType_L1_2'] : '',
        yearConstructed: this.report['manhole'] ? this.report['manhole']['yearConstructed'] : '',
        yearRenewed: this.report['manhole'] ? this.report['manhole']['yearRenewed'] : '',
        evidenceofSurcharge_L2: this.report['manhole'] ? this.report['manhole']['evidenceofSurcharge_L2'] : '',
        northing: this.report['measurements'] ? this.report['measurements']['northing'] : '',
        easting: this.report['measurements'] ? this.report['measurements']['easting'] : '',
        elevation: this.report['measurements'] ? this.report['measurements']['elevation'] : '',
        verticalDatum: this.report['measurements'] ? this.report['measurements']['verticalDatum'] : '',
        gpsAccuracy: this.report['measurements'] ? this.report['measurements']['gpsAccuracy'] : '',
        additionalInformation: this.report['measurements'] ? this.report['measurements']['additionalInformation'] : '',
        coverType: this.report['cover'] ? this.report['cover']['type'] : '',
        shape_L2: this.report['cover'] ? this.report['cover']['shape_L2'] : '',
        coverMaterial_L2: this.report['cover'] ? this.report['cover']['coverMaterial_L2'] : '',
        holeNumber_L2: this.report['cover'] ? this.report['cover']['holeNumber_L2'] : '',
        cover_FrameFit_L2: this.report['cover'] ? this.report['cover']['cover_FrameFit_L2'] : '',
        insertType_L2: this.report['coverInsert'] ? this.report['coverInsert']['insertType_L2'] : '',
        coverInsertCondition: this.report['coverInsert'] ? this.report['coverInsert']['coverInsertCondition'] : '',
        ringType_L1_2: this.report['coverAdjustmentRing'] ? this.report['coverAdjustmentRing']['ringType_L1_2'] : '',
        ringMaterial_L2: this.report['coverAdjustmentRing'] ? this.report['coverAdjustmentRing']['ringMaterial_L2'] : '',
        ringCondition_L1_2: this.report['coverAdjustmentRing'] ? this.report['coverAdjustmentRing']['ringCondition_L1_2'] : [],
        frameMaterial_L2: this.report['frame'] ? this.report['frame']['frameMaterial_L2'] : '',
        sealCondition_L2: this.report['frame'] ? this.report['frame']['sealCondition_L2'] : '',
        sealInflow_L2: this.report['frame'] ? this.report['frame']['sealInflow_L2'] : '',
        frameCondition_L1_2: this.report['frame'] ? this.report['frame']['frameCondition_L1_2'] : '',
        chimneyPresent_L2: this.report['chimney'] ? this.report['chimney']['chimneyPresent_L2'] : '',
        chimneyI_I: this.report['chimney'] ? this.report['chimney']['chimneyI_I'] : '',
        chimneyfirstMaterial_L2: this.report['chimney'] ? this.report['chimney']['firstMaterial_L2'] : '',
        secondMaterial: this.report['chimney'] ? this.report['chimney']['secondMaterial'] : '',
        liningInterior: this.report['chimney'] ? this.report['chimney']['liningInterior'] : '',
        liningExterior: this.report['chimney'] ? this.report['chimney']['liningExterior'] : '',
        chimneyCondition_L1: this.report['chimney'] ? this.report['chimney']['chimneyCondition_L1'] : '',
        conetype_L2: this.report['cone'] ? this.report['cone']['type_L2'] : '',
        first_Material_L2: this.report['wall'] ? this.report['wall']['first_Material_L2'] : '',
        second_Material_L2: this.report['cone'] ? this.report['cone']['second_Material_L2'] : '',
        coneCondition_L1: this.report['cone'] ? this.report['cone']['coneCondition_L1'] : '',
        conefirstMaterial_L2: this.report['cone'] ? this.report['cone']['first_Material_L2'] : '',
        wallfirstMaterial_L2: this.report['wall'] ? this.report['wall']['firstMaterial_L2'] : '',
        secondMaterial_L2: this.report['wall'] ? this.report['wall']['secondMaterial_L2'] : '',
        wallLiningInterior: this.report['wall'] ? this.report['wall']['wallLiningInterior'] : '',
        wallLiningExterior: this.report['wall'] ? this.report['wall']['wallLiningExterior'] : '',
        wallCondition_L1: this.report['wall'] ? this.report['wall']['wallCondition_L1'] : '',
        benchPresent_L2: this.report['bench'] ? this.report['bench']['benchPresent_L2'] : '',
        benchMaterial_L2: this.report['bench'] ? this.report['bench']['benchMaterial_L2'] : '',
        benchLining: this.report['bench'] ? this.report['bench']['benchLining'] : '',
        benchCondition_L1: this.report['bench'] ? this.report['bench']['benchCondition_L1'] : '',
        channelInstalled_L2: this.report['channel'] ? this.report['channel']['channelInstalled_L2'] : '',
        channelMaterial_L2: this.report['channel'] ? this.report['channel']['channelMaterial_L2'] : '',
        exposure_L2: this.report['channel'] ? this.report['channel']['exposure_L2'] : '',
        channelCondition_L1: this.report['channel'] ? this.report['channel']['channelCondition_L1'] : '',
        channeltype_L2: this.report['channel'] ? this.report['channel']['type_L2'] : '',
        number_L2: this.report['steps'] ? this.report['steps']['number_L2'] : '',
        material_L2: this.report['steps'] ? this.report['steps']['material_L2'] : '',
        surveyedBy_L1_2: this.report['generalInformation'] ? this.report['generalInformation']['surveyedBy_L1_2'] : '',
        reviewedBy: this.report['generalInformation'] ? this.report['generalInformation']['reviewedBy'] : '',
        weather: this.report['generalInformation'] ? this.report['generalInformation']['weather'] : '',
        purposeofSurvey_L1_2: this.report['generalInformation'] ? this.report['generalInformation']['purposeofSurvey_L1_2'] : '',
        certificateNumber_L1_2: this.report['generalInformation'] ? this.report['generalInformation']['certificateNumber_L1_2'] : '',
        reviewerCertificateNo: this.report['generalInformation'] ? this.report['generalInformation']['reviewerCertificateNo'] : '',
        pre_Cleaning_L2: this.report['generalInformation'] ? this.report['generalInformation']['pre_Cleaning_L2'] : '',
        p_ONumber: this.report['generalInformation'] ? this.report['generalInformation']['p_ONumber'] : '',
        mediaLabel: this.report['generalInformation'] ? this.report['generalInformation']['mediaLabel'] : '',
        date_L1_2: this.report['generalInformation'] ? this.report['generalInformation']['date_L1_2'] : '',
        sheetNumber_L1_2: this.report['generalInformation'] ? this.report['generalInformation']['sheetNumber_L1_2'] : '',
        dateCleaned: this.report['generalInformation'] ? this.report['generalInformation']['dateCleaned'] : '',
        workOrder: this.report['generalInformation'] ? this.report['generalInformation']['workOrder'] : '',
        consequenceofFailure: this.report['generalInformation'] ? this.report['generalInformation']['consequenceofFailure'] : '',
        rimtoInvert_L2: this.report['measurements'] ? this.report['measurements']['rimtoInvert_L2'] : 0,
        rimtoGrade_L2: this.report['measurements'] ? this.report['measurements']['rimtoGrade_L2'] : 0,
        gradetoInvert_L2: this.report['measurements'] ? this.report['measurements']['gradetoInvert_L2'] : 0,
        rimtoGradeExposed: this.report['measurements'] ? this.report['measurements']['rimtoGradeExposed'] : 0,
        size_L2: this.report['cover'] ? this.report['cover']['size_L2'] : 0,
        sizeWidth_L2: this.report['cover'] ? this.report['cover']['sizeWidth_L2'] : 0,
        centerCoverSize: this.report['cover'] ? this.report['cover']['centerCoverSize'] : 0,
        bearingSurfaceDiameter_L2: this.report['cover'] ? this.report['cover']['bearingSurfaceDiameter_L2'] : 0,
        coverbearingSurfaceWidth_L2: this.report['cover'] ? this.report['cover']['bearingSurfaceWidth_L2'] : 0,
        ringHeight: this.report['coverAdjustmentRing'] ? this.report['coverAdjustmentRing']['ringHeight'] : 0,
        framebearingSurfaceWidth_L2: this.report['frame'] ? this.report['frame']['bearingSurfaceWidth_L2'] : 0,
        coverbearingSurfaceDepth_L2: this.report['cover'] ? this.report['cover']['bearingSurfaceDepth_L2'] : 0,
        framebearingSurfaceDepth_L2: this.report['frame'] ? this.report['frame']['bearingSurfaceDepth_L2'] : 0,
        clearOpeningDiam_L2: this.report['frame'] ? this.report['frame']['clearOpeningDiam_L2'] : 0,
        clearOpeningWidth_L2: this.report['frame'] ? this.report['frame']['clearOpeningWidth_L2'] : 0,
        frameDepth: this.report['frame'] ? this.report['frame']['frameDepth'] : 0,
        offsetDistance_L2: this.report['frame'] ? this.report['frame']['offsetDistance_L2'] : 0,
        chimneyDepth_L2: this.report['chimney'] ? this.report['chimney']['chimneyDepth_L2'] : 0,
        chimneyclearOpening: this.report['chimney'] ? this.report['chimney']['clearOpening'] : 0,
        coneclearOpening: this.report['cone'] ? this.report['cone']['clearOpening'] : 0,
        conedepth_L2: this.report['cone'] ? this.report['cone']['depth_L2'] : 0,
        walldepth_L2: this.report['wall'] ? this.report['wall']['depth_L2'] : 0,
        wallDiameter_Length: this.report['wall'] ? this.report['wall']['wallDiameter_Length'] : 0,
        wallbySize_Width: this.report['wall'] ? this.report['wall']['wallbySize_Width'] : 0,
        holeDiameter_Vent_L2: this.report['cover'] ? this.report['cover']['holeDiameter_Vent_L2'] : '',
        pipeConnections: this.report['pipeConnections'] ? this.report['pipeConnections'] : [],
        shape: this.report['generalInformation'] ? this.report['generalInformation']['pipeShape'] : '',
        lining: this.report['generalInformation'] ? this.report['generalInformation']['liningMethod'] : '',
        location: this.report['generalInformation'] ? this.report['generalInformation']['location'] : '',
        locationDetails: this.report['generalInformation'] ? this.report['generalInformation']['locationDetails'] : '',
        surveyor: this.report['generalInformation'] ? this.report['generalInformation']['surveyor'] : '',
        direction: this.report['generalInformation'] ? this.report['generalInformation']['direction'] : '',
        purpose: this.report['generalInformation'] ? this.report['generalInformation']['purpose'] : '',
        category: this.report['generalInformation'] ? this.report['generalInformation']['sewerCategory'] : '',
        flowControl: this.report['generalInformation'] ? this.report['generalInformation']['flowControl'] : '',
        additionalInfo: this.report['generalInformation'] ? this.report['generalInformation']['additionalInfo'] : '',
        po: this.report['generalInformation'] ? this.report['generalInformation']['po'] : '',
        CoverCondition_L1_2: this.report['cover'] ? this.report['cover']['coverCondition_L1_2'] : '',
      };
    }
  }

  get reportPipeConnections(): any {
    return (this.report['pipeConnections'] as any[]).sort((a, b) => a['pipeNo_L2'].localeCompare(b['pipeNo_L2']));
  }

  getQuickStyle(score: string): any {
    const quickScore = score?.length > 0 ? parseInt(score.charAt(0), 10) : 0;
    return {
      color: quickScore === 3 ? 'black' : 'white',
      backgroundColor: util.getScoreColor(quickScore),
    };
  }
}
