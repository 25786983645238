


























































































































































































/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable object-curly-newline */
/* eslint-disable @typescript-eslint/no-explicit-any */
import PlanningTable from '@/components/ProjectManager/PlanningTable.vue';
import NewAssignmentDialog from '@/components/ScopeNavigation/NewAssignmentDialog/NewAssignmentDialog.vue';
import NewAssignmentTable from '@/components/ScopeNavigation/NewAssignmentTable/NewAssignmentTable.vue';
import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import { AssignmentShort } from '@/store/planning/types';
import { DataTableHeader } from '@/components/AssetTable/types';
import { AssetData } from '@/store/asset/types';
import { namespace } from 'vuex-class';
import { RoutingActions } from '@/store/routing/actions';
import { RoutingData } from '@/store/routing/types';
import { TASK_TYPE_STRING, NODEITEM_MANHOLE_GUID, NODEITEM_LINESEGMENT_GUID, MACP_LEVEL_1_COLLECTION_TASK, MACP_LEVEL_2_COLLECTION_TASK, ALLOWED_DEPLOYMENT_FORM_TASK_TYPES } from '@/common/Constants';
import { CREW_LEAD_AUTH0, PROJECT_MANAGER, SUPER_USER } from '@/auth/roles';
import { MPSActions } from '@/store/mps/actions';
import { ProjectHeaders } from '@/store/project/types';
import { AssetActions } from '@/store/asset/actions';
import { UserPermission } from '@/store/userpermissions/types';
import NewRoutingAssignmentTable from './NewAssignmentTable/NewRoutingAssignmentTable.vue';
import WorkOrderCounts from './WorkOrderCounts/WorkOrderCounts.vue';
import { WorkOrderListPatchDTO } from '../ProjectManager/types';
import DeploymentForm from '../ProjectManager/DeploymentForm.vue';
import UserPermissionsMixin from '../UserPermissions/UserPermissionsMixin.vue';

const routingModule = namespace('routing');
const mpsModule = namespace('mps');
const assetModule = namespace('asset');

@Component({
  components: {
    PlanningTable,
    NewAssignmentDialog,
    NewAssignmentTable,
    NewRoutingAssignmentTable,
    WorkOrderCounts,
    DeploymentForm,
  },
})
export default class PlanningBase extends UserPermissionsMixin {
  categories = [
    {
      name: 'unassigned',
      label: 'Unassigned',
      filterFunction: (x): boolean => x.scheduledEndTime == null && x.scheduledStartTime == null && x.status !== 'Complete' && x.status !== 'Completed' && !(x.status as string).toLowerCase().includes('incomplete') && x.status !== 'Follow-Up Required',
    },
    {
      name: 'Pending',
      label: 'Pending',
      filterFunction: (x): boolean => x.scheduledEndTime != null && x.scheduledStartTime != null && x.status !== 'Complete' && x.status !== 'Completed' && !(x.status as string).toLowerCase().includes('incomplete') && x.status !== 'Follow-Up Required',
    },
    {
      name: 'completed',
      label: 'Completed',
      filterFunction: (x): boolean => (x.status === 'Complete' || x.status === 'Completed') && x.status !== 'Follow-Up Required',
    },
    {
      name: 'incomplete',
      label: 'Incomplete',
      filterFunction: (x): boolean => (x.status as string).toLowerCase().includes('incomplete') && x.status !== 'Follow-Up Required',
    },
    {
      name: 'followUpRequired',
      label: 'Follow-Up Required',
      filterFunction: (x): boolean => x.status === 'Follow-Up Required',
    },
    {
      name: 'all',
      label: 'All',
      filterFunction: (x): boolean => x,
    },
  ];

  filteredData = [];

  selectedCategoryIndex = 0;

  snackbarMessage = '';

  snackbarColor = '';

  snackbarVisible = false;

  polling = 0;

  taskTypeGuids = TASK_TYPE_STRING.map((tts) => tts.guid)

  createNewAssign = false;

  showNewAssignDialog = false;

  tabOptions = ['Manhole', 'Line Segment', 'Manhole Routes', 'Line Segment Routes']

  newWorkOrderHeaders: DataTableHeader[] = [
    { text: 'Name', value: 'name', sortable: false, class: 'headcol', cellClass: 'headcol', editable: true },
    { text: 'Score', value: 'score', sortable: false },
    { text: 'Street', value: 'street', sortable: false, editable: true },
    { text: 'City', value: 'city', sortable: false, editable: true },
    { text: 'Drainage Area / Basin', value: 'basin', sortable: false, editable: true },
    { text: 'Owner', value: 'owner', sortable: false, editable: true },
  ]

  activeTab = 0;

  selectedAssets = [];

  selectedAssetType = 0;

  @Prop() project: ProjectHeaders;

  @Prop() analyticsData: AssignmentShort[] | undefined;

  @Prop() loadingAnalyticsData;

  @Prop() fetchAssignmentsData;

  @Prop() deleteAssignment;

  @Prop() editAssignment;

  @Prop() patchData: string | undefined;

  @Prop() deleteLoading: boolean;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Prop() patchAssignmentList: any;

  @Prop() readonly assetData: AssetData[];

  @routingModule.Action(RoutingActions.FETCH_ROUTING_DATA) fetchRoutingData;

  @routingModule.State('routingData') routingData: RoutingData[] | undefined;

  @mpsModule.Action(MPSActions.FETCH_MPS_DATA) fetchMpsData;

  @assetModule.Action(AssetActions.FETCH_AVAILABLE_MANHOLES) fetchAvailableManholes;

  openDialog = false;

  deploymentRolesMet = false;

  selectedItems = [];

  newDeploymentDialog = false;

  defaultDeploymentFormButtonTooltipText = 'Create deployment for the selected work orders.'

  deploymentFormButtonTooltip = '';

  @Watch('patchData')
  onPatchDataChange(): void {
    let url = null;
    try {
      url = new URL(this.patchData);
    } catch (ex) {
      const silence = ex;
    }
    if (url != null) {
      this.openDialog = true;
    }
    this.$forceUpdate();
  }

  @Watch('analyticsData')
  onAnalyticsDataChange(newAnalyticsData: AssignmentShort | undefined): void {
    if (!newAnalyticsData) return;

    const hasRedzoneWO = this.analyticsData.some((ad) => ad.isRedzone === true);
    const hasNonRedzoneWO = this.analyticsData.some((ad) => ad.isRedzone === false);

    if (!hasRedzoneWO) {
      const incomplete = this.categories.find((cat) => cat.name === 'incomplete');
      const incompleteIndex = this.categories.indexOf(incomplete);
      if (incompleteIndex > -1) this.categories.splice(incompleteIndex, 1);
    }

    if (!hasNonRedzoneWO) {
      const followUp = this.categories.find((cat) => cat.name === 'followUpRequired');
      const followUpIndex = this.categories.indexOf(followUp);
      if (followUpIndex > -1) this.categories.splice(followUpIndex, 1);
    }

    this.filteredData = [];
    this.categories.forEach((_category, i) => {
      this.filteredData.push(this.getFilteredData(i));
    });
  }

  @Watch('selectedAssetType')
  onActiveTabChange(): void {
    this.selectedItems = [];
  }

  async mounted(): Promise<void> {
    await this.getRouteParams();
    this.fetchAvailableManholes(this.project.guids[0]);
    this.fetchMpsData(this.project.guids[0]);
    if (this.$auth.user.id !== null) {
      const roles = await this.getRoles();
      const deploymentRoles = [CREW_LEAD_AUTH0, PROJECT_MANAGER, SUPER_USER];
      this.deploymentRolesMet = deploymentRoles.some((role) => roles.includes(role));
    }
  }

  async getRoles(): Promise<string[]> {
    return this.$auth.getRoles(`auth0|${this.$auth.user.id}`);
  }

  created(): void {
    this.pollData();
  }

  pollData(): void {
    if (!this.project || !this.project.guids) {
      return;
    }
    if (this.fetchAssignmentsData) {
      this.refresh();
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get filtered(): any[] {
    if (!this.analyticsData) return [];

    const typeGuids = [NODEITEM_MANHOLE_GUID, NODEITEM_LINESEGMENT_GUID];
    const filteredDataCategory = this.filteredData[this.selectedCategoryIndex];

    return !filteredDataCategory
      ? []
      : filteredDataCategory.filter(
        (data) => data.nodes.typeGuid === typeGuids[this.selectedAssetType],
      );
  }

  get filteredTableData(): AssetData[] | RoutingData[] {
    if (this.isRoutingTab) {
      return [];
    }
    return this.assetData.filter((asset) => asset.type === this.tabOptions[this.activeTab]);
  }

  get isPlanningTableLineSegmentTab(): boolean {
    return this.selectedAssetType === 1;
  }

  get isRoutingTab(): boolean {
    return this.activeTab >= 2;
  }

  get activeTabString(): string {
    return this.tabOptions[this.activeTab];
  }

  get validResourceGroups(): any {
    if (!this.analyticsData) {
      return [];
    }

    return this.analyticsData.map(
      (data) => (
        {
          dataGuid: data['guid'],
          name: data['resourceGroupName'],
          guid: data['resourceGroupGuid'],
        }
      ),
    ).filter(
      (value, index, self) => index === self.findIndex((t) => (
        t.guid === value.guid && value.guid && !value.name.startsWith('Assignment')
      )),
    );
  }

  get isDeploymentFormButtonVisible(): boolean {
    return !this.createNewAssign && this.deploymentRolesMet && this.isPlanningTableLineSegmentTab;
  }

  get isDeploymentFormButtonDisabled(): boolean {
    const taskTypesSelected = new Set(this.selectedItems.map((value) => value.taskTypeGuid));
    if (taskTypesSelected.size === 0) {
      this.deploymentFormButtonTooltip = 'Select a CCTV, Profiler, or Responder work order.';
      return true;
    }
    if (taskTypesSelected.size !== 1) {
      this.deploymentFormButtonTooltip = 'Select only work orders with the same task type.';
      return true;
    }
    if (!ALLOWED_DEPLOYMENT_FORM_TASK_TYPES.includes(taskTypesSelected
      .values().next().value)
    ) {
      this.deploymentFormButtonTooltip = 'You can only create a deployment on CCTV, Profiler, and Responder work orders.';
      return true;
    }
    this.deploymentFormButtonTooltip = this.defaultDeploymentFormButtonTooltipText;

    return false;
  }

  getFilteredData(category: number): AssignmentShort[] {
    let filtered = [];
    const selectedCategory = this.categories[category];
    if (this.analyticsData) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      filtered = (this.analyticsData as any).filter(selectedCategory.filterFunction);
      filtered = filtered.filter((x) => this.taskTypeGuids.includes(x.taskTypeGuid));
      filtered = filtered.filter((x) => x.nodes);
    }

    return filtered;
  }

  isMACPTaskType(taskTypeGuid: string): boolean {
    return taskTypeGuid && (taskTypeGuid === MACP_LEVEL_1_COLLECTION_TASK
    || taskTypeGuid === MACP_LEVEL_2_COLLECTION_TASK);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async onEditAssignmentItem(editAssignment: any, shouldEditMACPLevels: boolean): Promise<void> {
    try {
      const editAssignmentList = [editAssignment];
      if (shouldEditMACPLevels) {
        this.analyticsData.forEach(
          (data) => {
            if (this.isMACPTaskType(data['taskTypeGuid']) && data['guid'] !== editAssignment.guid) {
              editAssignmentList.push({
                guid: data['guid'],
                startDate: data['scheduledStartTime'],
                endDate: data['scheduledEndTime'],
                priorityItemGuid: data['priorityItemGuid'],
                taskTypeGuid: editAssignment.taskTypeGuid,
                resourceGroupGuid: data['resourceGroupGuid'],
              });
            }
          },
        );
      }

      await this.editAssignment(editAssignmentList);

      await this.refresh();
      this.showSnackBarSuccess('Edit Successful');
    } catch {
      this.showSnackBarFailue('Edit Error');
    }
  }

  async onRemoveAssignment(workOrderGuids: string[]): Promise<void> {
    try {
      workOrderGuids.forEach((workOrderGuid) => {
        const itemIndex = this.analyticsData
          .findIndex((dataItem: AssignmentShort) => dataItem.guid
           === workOrderGuid);
        this.analyticsData.splice(itemIndex, 1);
      });
      this.$forceUpdate();
      this.showSnackBarSuccess('Delete Successful');
    } catch {
      this.showSnackBarFailue('Delete Error');
    }
  }

  async submit(data: WorkOrderListPatchDTO): Promise<void> {
    try {
      await this.patchAssignmentList(data);
      // check success
      if (!this.patchData.includes('error')) {
        this.snackbarMessage = data.createPCM
          ? 'Work Order Assigned. Generated PCMs will be sent to attached addresses'
          : 'Work Order Updated';
        this.snackbarColor = 'green';
        this.snackbarVisible = true;

        await this.refresh();

        this.resetEdit();

        // this.fetchPlanningAnalytics(this.project.guids);
        setTimeout(() => {
          this.snackbarMessage = '';
          this.snackbarColor = '';
          this.snackbarVisible = false;
        }, 3000);
      } else { console.log('failure occcured'); this.fail(); }
    } catch {
      // api error
      this.fail();
    }
  }

  async reset(data: unknown): Promise<void> {
    try {
      await this.patchAssignmentList(data);
      await this.refresh();
      this.resetEdit();
    } catch {
      this.fail();
    }
  }

  resetEdit(): void{
    const dataTable = this.$refs.dataTable as any;
    if (dataTable.resetEdit) { dataTable.resetEdit(); }
  }

  // eslint-disable-next-line class-methods-use-this
  fail(): void {
    this.showSnackBarFailue('Save Failed');
  }

  cancel(): void {
    this.resetEdit();
  }

  refreshFollowUpWorkOrder(
    followUpMsg: {
      followUpWorkOrder: string,
      isSuccessful: boolean,
    },
  ): void {
    if (followUpMsg.isSuccessful) {
      this.refresh();
      this.showSnackBarSuccess(`Created Follow Up Work Order ${followUpMsg.followUpWorkOrder}`);
    } else {
      this.showSnackBarFailue('Unable To Create Follow Up Work Order');
    }
  }

  async refresh(): Promise<void> {
    // Get the earlies possible date since we
    // Don't want a limit
    const date = new Date(0).toISOString();
    await this.fetchAssignmentsData({
      dateCutoff: date,
      taskTypeGuids: this.taskTypeGuids,
      projectGuids: this.project.guids,
    });
    this.$forceUpdate();
  }

  closeDialog(): void {
    this.showNewAssignDialog = false;
  }

  updateSelectedAssets(newAssetSelection: AssetData[]): void {
    this.selectedAssets = newAssetSelection;
  }

  editWorkOrderPass(): void {
    this.showSnackBarSuccess('Work Order Saved');
  }

  setCreateNewAssign(val: boolean): void {
    this.createNewAssign = val;
  }

  setSelectedCategoryIndex(val: number): void {
    this.selectedCategoryIndex = val;
  }

  getRouteParams(): void {
    const route = this.$route;
    if (Object.keys(route.query).length > 0) {
      const queries = Object.entries(route.query);
      queries.forEach((pair) => {
        const [key, value] = pair;
        if (key === 'category') {
          this.selectedCategoryIndex = parseInt(value as string, 10);
        }
      });
    }
  }

  showSnackBarSuccess(msg: string): void {
    this.showSnackbarMessage(msg, true);
  }

  showSnackBarFailue(msg: string): void {
    this.showSnackbarMessage(msg, false);
  }

  showSnackbarMessage(msg: string, isSuccessful: boolean): void {
    this.snackbarMessage = msg;
    this.snackbarColor = isSuccessful ? 'green' : '#e61e25';
    this.snackbarVisible = true;
    setTimeout(() => {
      this.snackbarMessage = '';
      this.snackbarColor = '';
      this.snackbarVisible = false;
    }, 3000);
  }

  async deploymentSaveResults(results: string): Promise<void> {
    if (results === 'OK') {
      this.showSnackbarMessage('Success adding deployment!', true);
    } else {
      this.showSnackbarMessage(results, false);
    }
    await this.refresh();

    this.resetEdit();

    this.newDeploymentDialog = false;
  }

  /**
   * @returns true if the user has the permission WORK_ORDER_CREATE_WORK_ORDER
   */
  get hasPermissionWorkOrderCreateWorkOrder(): boolean {
    return this.hasPermission(UserPermission.WORK_ORDER_CREATE_WORK_ORDER);
  }
}
