
























import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class WorkOrderCounts extends Vue {
  @Prop() filteredData;

  @Prop() categories;

  @Prop() analyticsData;

  selectedCategoryIndex = 0;

  mounted(): void {
    this.getRouteParams();
  }

  getCategoryCount(categoryID: number): string {
    const category = this.filteredData[categoryID];
    if (!this.analyticsData || !category) {
      return '-';
    }
    return category.length;
  }

  onCategoryChange(val: boolean, index: number): void {
    this.$emit('setCreateNewAssign', val);
    this.selectedCategoryIndex = index;
    this.$emit('setSelectedCategoryIndex', index);
  }

  getRouteParams(): void {
    const route = this.$route;
    if (Object.keys(route.query).length > 0) {
      const queries = Object.entries(route.query);
      queries.forEach((pair) => {
        const [key, value] = pair;
        if (key === 'category') {
          this.selectedCategoryIndex = parseInt(value as string, 10);
        } else if (key === 'workOrderStatus') {
          const task = this.categories.findIndex((item) => item.label === value);
          this.onCategoryChange(false, task);
        }
      });
    }
  }
}
