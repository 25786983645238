import Vue from 'vue';
import VueRouter, { Route, RouteConfig } from 'vue-router';
import User from '@/auth/User';
import { authGuard } from '@/auth/authGuard';
import { VueAuth } from '@/auth/VueAuth';
import {
  PROJECT_MANAGER, CUSTOMER, SUPER_USER, SYS_ADMIN, SYS_ADMIN_AUTH0,
  REPORTING_TECH_AUTH0, CREW_LEAD_AUTH0,
} from '@/auth/roles';
import { getInstance } from '../auth';
import Project from '../views/Project/Project.vue';
import Asset from '../views/Asset/Asset.vue';
import Profile from '../views/Profile/Profile.vue';
import ProjectSelection from '../views/Profile/ProjectSelection.vue';
import EmbeddedViewer from '../components/EmbeddedViewer/EmbeddedViewer.vue';
import Error from '../views/Error/Error.vue';
import Forbidden from '../views/Error/Forbidden.vue';
import AdminUser from '../views/Admin/AdminUser.vue';
import UserEdit from '../views/Admin/EditUser.vue';
import ScopeHistory from '../components/ScopeHistory/ScopeHistory.vue';
import storeUtility from '../store/utils';
import CodingForm from '../components/CodingForm/CodingForm.vue';
import WorkOrder from '../components/WorkOrder/WorkOrder.vue';
import Routing from '../components/Routing/Routing.vue';
import ProjectDashboard from '../views/Dashboards/ProjectDashboard/ProjectDashboard.vue';
import CrewDashboard from '../views/Dashboards/CrewDashboard/CrewDashboard.vue';
import ImportBase from '../views/ImportBase/ImportBase.vue';
import MPSBase from '../views/MasterProjectSummary/MPSBase.vue';
import Reporting from '../views/Reporting/Reporting.vue';
import Deployments from '../views/Deployments/Deployments.vue';
import Inventory from '../views/Inventory/Inventory.vue';
import WinCanWorkOrder from '../views/WinCanWorkOrder/WinCanWorkOrder.vue';

Vue.use(VueRouter);

let auth: VueAuth;
let roles: string[] = [];

class AuthRoute {
  auth: boolean;

  path: string;

  constructor(authBool: boolean, pathString: string) {
    this.auth = authBool;
    this.path = pathString;
  }
}

async function checkAuth(user: User,
  desiredRoutePath: string,
  authRole: string,
  redirectPath: string): Promise<AuthRoute> {
  // Get Permissions
  // jsonJWT = auth.getTokenSilently({}).then((jwt) => auth.parseJwt(jwt));

  // Get roles
  if (roles.length === 0) {
    roles = await auth.getRoles(`auth0|${user.id}`);
  }
  if (roles.indexOf(authRole) !== -1) {
    return new AuthRoute(true, desiredRoutePath);
  }
  return new AuthRoute(false, redirectPath);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
async function checkAuthMulti(this: any,
  user: User,
  desiredRoutePath: string,
  authRole: Array<string>,
  redirectPath: string): Promise<AuthRoute> {
  let returnValue: AuthRoute = new AuthRoute(false, redirectPath);
  for (let i = 0; i < authRole.length; i += 1) {
    // eslint-disable-next-line no-await-in-loop
    const route = await checkAuth(user, desiredRoutePath, authRole[i], redirectPath);
    if (route.auth) {
      returnValue = route;
      break;
    }
  }
  return returnValue;
}

const routes: Array<RouteConfig> = [
  {
    // Then for the time being we will be routed to the first project.
    path: '/',
    beforeEnter: async (to, from, next) => {
      try {
        const api = await storeUtility.useIntegrityAPI();
        const { data } = await api.getProjectsSimple();
        await auth.getUser();
        const desiredPath = '/projectSelection';
        let redirectPath = '/error';
        let route: AuthRoute;

        // commenting this out till we want to have reporting tech routed directly to reporting page
        // const userRoles = await auth.getRoles(`auth0|${auth.user.id}`);
        // if (userRoles.length === 1 && userRoles[0] === REPORTING_TECH_AUTH0) {
        //   desiredPath = '/reporting';
        //   route = await checkAuthMulti(auth.user as User, desiredPath,
        //     [REPORTING_TECH_AUTH0], '/error');
        // } else
        if (data.length === 1) {
          redirectPath = `/projects/["${data[0]}"]`;
          route = await checkAuthMulti(auth.user as User, redirectPath,
            [CUSTOMER, PROJECT_MANAGER, SUPER_USER, SYS_ADMIN_AUTH0,
              REPORTING_TECH_AUTH0, CREW_LEAD_AUTH0], '/error');
        } else {
          route = await checkAuthMulti(auth.user as User, desiredPath,
            [CUSTOMER, PROJECT_MANAGER, SUPER_USER, SYS_ADMIN_AUTH0,
              REPORTING_TECH_AUTH0, CREW_LEAD_AUTH0], '/error');
        }
        next(route.path);
      } catch (e) {
        next('/error');
      }
    },
  },
  {
    path: '/projects/:ids',
    name: 'projects',
    props: true,
    component: Project,
    meta: {
      breadCrumb() {
        return [
          {
            text: 'Project Selection',
            to: { name: 'projectSelection' },
          },
          {
            text: 'Project',
          },
        ];
      },
    },
    beforeEnter: async (to, from, next) => {
      await auth.getUser();
      const route = await checkAuthMulti(auth.user as User, to.path,
        [PROJECT_MANAGER, CUSTOMER, SUPER_USER, SYS_ADMIN_AUTH0, REPORTING_TECH_AUTH0, CREW_LEAD_AUTH0], '/error');
      if (!route.auth) {
        next(route.path);
      } else {
        next();
      }
    },
  },
  {
    path: '/projects/:ids/dashboard',
    name: 'dashboard',
    props: true,
    component: ProjectDashboard,
    meta: {
      breadCrumb(route: Route) {
        const paramToProject = route.params.ids;
        return [
          {
            text: 'Project Selection',
            to: { name: 'projectSelection' },
          },
          {
            text: 'Project',
            exact: true,
            to: {
              name: 'projects',
              params: { ids: paramToProject },
            },
          },
          {
            text: 'Dashboard',
          },
        ];
      },
    },
    beforeEnter: async (to, from, next) => {
      await auth.getUser();
      const route = await checkAuthMulti(auth.user as User, to.path,
        [PROJECT_MANAGER, CUSTOMER, SUPER_USER, SYS_ADMIN_AUTH0, REPORTING_TECH_AUTH0], '/error');
      if (!route.auth) {
        next(route.path);
      } else {
        next();
      }
    },
  },
  {
    path: '/projects/:ids/dashboard/crew',
    name: 'crewDashboard',
    props: true,
    component: CrewDashboard,
    meta: {
      breadCrumb(route: Route) {
        const paramToProject = route.params.ids;
        return [
          {
            text: 'Project Selection',
            to: { name: 'projectSelection' },
          },
          {
            text: 'Project',
            exact: true,
            to: {
              name: 'projects',
              params: { ids: paramToProject },
            },
          },
          {
            text: 'Dashboard',
          },
        ];
      },
    },
    beforeEnter: async (to, from, next) => {
      await auth.getUser();
      const route = await checkAuthMulti(auth.user as User, to.path,
        [PROJECT_MANAGER, CUSTOMER, SUPER_USER, SYS_ADMIN_AUTH0, REPORTING_TECH_AUTH0, CREW_LEAD_AUTH0], '/error');
      if (!route.auth) {
        next(route.path);
      } else {
        next();
      }
    },
  },
  {
    path: '/assets/:idList/:id',
    name: 'assetsList',
    props: true,
    component: Asset,
    meta: {
      breadCrumb(route: Route) {
        const paramToProject = route.params.idList;
        return [
          {
            text: 'Project Selection',
            to: { name: 'projectSelection' },
          },
          {
            text: 'Project',
            to: {
              name: 'projects',
              params: { ids: paramToProject },
            },
          },
          {
            text: 'Asset',
          },
        ];
      },
    },
    beforeEnter: async (to, from, next) => {
      await auth.getUser();
      const route = await checkAuthMulti(auth.user as User, to.path,
        [PROJECT_MANAGER, CUSTOMER, SUPER_USER, SYS_ADMIN_AUTH0, REPORTING_TECH_AUTH0, CREW_LEAD_AUTH0], '/error');
      if (!route.auth) {
        next(route.path);
      } else {
        next();
      }
    },
  },
  {
    path: '/assets/:id',
    name: 'assets',
    props: true,
    component: Asset,
    beforeEnter: async (to, from, next) => {
      await auth.getUser();
      const route = await checkAuthMulti(auth.user as User, to.path,
        [PROJECT_MANAGER, CUSTOMER, SUPER_USER, SYS_ADMIN_AUTH0, REPORTING_TECH_AUTH0, CREW_LEAD_AUTH0], '/error');
      if (!route.auth) {
        next(route.path);
      } else {
        next();
      }
    },
  },
  {
    path: '/profile',
    name: 'profile',
    props: true,
    component: Profile,
    beforeEnter: async (to, from, next) => {
      await auth.getUser();
      const route = await checkAuthMulti(auth.user as User, to.path,
        [PROJECT_MANAGER, CUSTOMER, SUPER_USER, SYS_ADMIN_AUTH0, REPORTING_TECH_AUTH0, CREW_LEAD_AUTH0], '/error');
      if (!route.auth) {
        next(route.path);
      } else {
        next();
      }
    },
  },
  {
    path: '/editUser/id=:id!auth0Id=:auth0Id',
    name: 'editUser',
    props: true,
    component: UserEdit,
    beforeEnter: async (to, from, next) => {
      await auth.getUser();
      const route = await checkAuthMulti(auth.user as User, to.path,
        [PROJECT_MANAGER, CUSTOMER, SUPER_USER, SYS_ADMIN_AUTH0, CREW_LEAD_AUTH0], '/error');
      if (!route.auth) {
        next(route.path);
      } else {
        next();
      }
    },
  },
  {
    path: '/projectSelection',
    name: 'projectSelection',
    component: ProjectSelection,
    meta: {
      breadCrumb: [
        { text: 'Project Selection' },
      ],
    },
    beforeEnter: async (to, from, next) => {
      await auth.getUser();
      const route = await checkAuthMulti(auth.user as User, to.path,
        [PROJECT_MANAGER, CUSTOMER, SUPER_USER, SYS_ADMIN_AUTH0, REPORTING_TECH_AUTH0, CREW_LEAD_AUTH0], '/error');
      if (!route.auth) {
        next(route.path);
      } else {
        next();
      }
    },
  },
  {
    path: '/admin',
    name: 'admin',
    component: AdminUser,
    beforeEnter: async (to, from, next) => {
      await auth.getUser();
      const route = await checkAuthMulti(auth.user as User, to.path, [SUPER_USER, SYS_ADMIN, SYS_ADMIN_AUTH0, CREW_LEAD_AUTH0], '/error');
      if (!route.auth) {
        next(route.path);
      } else {
        next();
      }
    },
  },
  {
    path: '/embed',
    component: EmbeddedViewer,
    props: {
      embedLink: 'https://redzone.com/',
    },
    beforeEnter: async (to, from, next) => {
      await auth.getUser();
      const route = await checkAuthMulti(auth.user as User, to.path,
        [PROJECT_MANAGER, CUSTOMER, SUPER_USER, SYS_ADMIN_AUTH0, CREW_LEAD_AUTH0], '/error');
      if (!route.auth) {
        next(route.path);
      } else {
        next();
      }
    },
  },
  {
    path: '/codingForm',
    component: CodingForm,
    props: {
      isPopout: true,
    },
    beforeEnter: async (to, from, next) => {
      await auth.getUser();
      const route = await checkAuthMulti(auth.user as User, to.path,
        [PROJECT_MANAGER, CUSTOMER, SUPER_USER, SYS_ADMIN_AUTH0, REPORTING_TECH_AUTH0, CREW_LEAD_AUTH0], '/error');
      if (!route.auth) {
        next(route.path);
      } else {
        next();
      }
    },
  },
  {
    path: '/workOrders/:id',
    name: 'workOrders',
    props: true,
    component: WorkOrder,
    meta: {
      breadCrumb(route: Route) {
        const paramToProject = route.params.id;
        return [
          {
            text: 'Project Selection',
            to: { name: 'projectSelection' },
          },
          {
            text: 'Project',
            to: {
              name: 'projects',
              params: { ids: `["${paramToProject}"]` },
            },
          },
          {
            text: 'Work Orders',
          },
        ];
      },
    },
    beforeEnter: async (to, from, next) => {
      await auth.getUser();
      const route = await checkAuthMulti(auth.user as User, to.path,
        [PROJECT_MANAGER, CUSTOMER, SUPER_USER, SYS_ADMIN_AUTH0, CREW_LEAD_AUTH0], '/error');
      if (!route.auth) {
        next(route.path);
      } else {
        next();
      }
    },
  },
  {
    path: '/routing/:id',
    name: 'routing',
    props: true,
    component: Routing,
    meta: {
      breadCrumb(route: Route) {
        const paramToProject = route.params.id;
        return [
          {
            text: 'Project Selection',
            to: { name: 'projectSelection' },
          },
          {
            text: 'Project',
            to: {
              name: 'projects',
              params: { ids: `["${paramToProject}"]` },
            },
          },
          {
            text: 'Routing',
          },
        ];
      },
    },
    beforeEnter: async (to, from, next) => {
      await auth.getUser();
      const route = await checkAuthMulti(auth.user as User, to.path,
        [PROJECT_MANAGER, CUSTOMER, SUPER_USER, SYS_ADMIN_AUTH0, CREW_LEAD_AUTH0], '/error');
      if (!route.auth) {
        next(route.path);
      } else {
        next();
      }
    },
  },
  {
    path: '/aicoding/:id',
    name: 'aicoding',
    props: true,
    component: WinCanWorkOrder,
    meta: {
      breadCrumb(route: Route) {
        const paramToProject = route.params.id;
        return [
          {
            text: 'Project Selection',
            to: { name: 'projectSelection' },
          },
          {
            text: 'Project',
            to: {
              name: 'projects',
              params: { ids: `["${paramToProject}"]` },
            },
          },
          {
            text: 'AI Coding',
          },
        ];
      },
    },
    beforeEnter: async (to, from, next) => {
      await auth.getUser();
      const route = await checkAuthMulti(auth.user as User, to.path,
        [SUPER_USER, REPORTING_TECH_AUTH0], '/error');
      if (!route.auth) {
        next(route.path);
      } else {
        next();
      }
    },
  },
  {
    path: '/projects/:projectGuid/import',
    name: 'import',
    props: true,
    component: ImportBase,
    meta: {
      breadCrumb(route: Route) {
        const paramToProject = route.params.projectGuid;
        return [
          {
            text: 'Project Selection',
            to: { name: 'projectSelection' },
          },
          {
            text: 'Project',
            exact: true,
            to: {
              name: 'projects',
              params: { ids: `["${paramToProject}"]` },
            },
          },
          {
            text: 'Import',
          },
        ];
      },
    },
    beforeEnter: async (to, from, next) => {
      await auth.getUser();
      const route = await checkAuthMulti(auth.user as User, to.path,
        [PROJECT_MANAGER, CUSTOMER, SUPER_USER, SYS_ADMIN_AUTH0, REPORTING_TECH_AUTH0, CREW_LEAD_AUTH0], '/error');
      if (!route.auth) {
        next(route.path);
      } else {
        next();
      }
    },
  },
  {
    path: '/projects/:projectGuids/assetHistory',
    name: 'assetManagement',
    props: true,
    component: ScopeHistory,
    meta: {
      breadCrumb(route: Route) {
        const paramToProject = route.params.projectGuids;
        return [
          {
            text: 'Project Selection',
            to: { name: 'projectSelection' },
          },
          {
            text: 'Project',
            exact: true,
            to: {
              name: 'projects',
              params: { ids: paramToProject },
            },
          },
          {
            text: 'Asset History',
          },
        ];
      },
    },
    beforeEnter: async (to, from, next) => {
      await auth.getUser();
      const route = await checkAuthMulti(auth.user as User, to.path,
        [PROJECT_MANAGER, CUSTOMER, SUPER_USER, SYS_ADMIN_AUTH0, REPORTING_TECH_AUTH0], '/error');
      if (!route.auth) {
        next(route.path);
      } else {
        next();
      }
    },
  },
  {
    path: '/projectSelection/mps/:guid?',
    name: 'MPS',
    props: true,
    component: MPSBase,
    meta: {
      breadCrumb(route: Route) {
        const paramToProject = route.params.guid;
        return [
          {
            text: 'Project Selection',
            exact: true,
            to: { name: 'projectSelection' },
          },
          {
            text: 'Project',
            to: {
              name: 'projects',
              params: { ids: `["${paramToProject}"]` },
            },
          },
          {
            text: 'MPS',
          },
        ];
      },
    },
    beforeEnter: async (to, from, next) => {
      await auth.getUser();
      const route = await checkAuthMulti(auth.user as User, to.path,
        [PROJECT_MANAGER, CUSTOMER, SUPER_USER, SYS_ADMIN_AUTH0, REPORTING_TECH_AUTH0], '/error');
      if (!route.auth) {
        next(route.path);
      } else {
        next();
      }
    },
  },
  {
    path: '/reporting',
    name: 'reporting',
    component: Reporting,
    meta: {
      breadCrumb: [
        { text: 'Reporting' },
      ],
    },
    beforeEnter: async (to, from, next) => {
      await auth.getUser();
      const route = await checkAuthMulti(auth.user as User, to.path,
        [PROJECT_MANAGER, SUPER_USER, REPORTING_TECH_AUTH0], '/error');
      if (!route.auth) {
        next(route.path);
      } else {
        next();
      }
    },
  },
  {
    path: '/projects/:ids/inventory',
    name: 'inventory',
    component: Inventory,
    meta: {
      breadCrumb(route: Route) {
        const paramToProject = route.params.ids;
        return [
          {
            text: 'Project Selection',
            to: { name: 'projectSelection' },
          },
          {
            text: 'Project',
            exact: true,
            to: {
              name: 'projects',
              params: { ids: paramToProject },
            },
          },
          {
            text: 'Asset Inventory',
          },
        ];
      },
    },
    beforeEnter: async (to, from, next) => {
      await auth.getUser();
      const route = await checkAuthMulti(auth.user as User, to.path,
        [PROJECT_MANAGER, SUPER_USER, REPORTING_TECH_AUTH0, CUSTOMER, SYS_ADMIN], '/error');
      if (!route.auth) {
        next(route.path);
      } else {
        next();
      }
    },
  },
  {
    path: '/projects/:ids/deployments',
    name: 'deployments',
    props: true,
    component: Deployments,
    meta: {
      breadCrumb(route: Route) {
        const paramToProject = route.params.ids;
        return [
          {
            text: 'Project Selection',
            to: { name: 'projectSelection' },
          },
          {
            text: 'Project',
            exact: true,
            to: {
              name: 'projects',
              params: { ids: paramToProject },
            },
          },
          {
            text: 'Deployments',
          },
        ];
      },
    },
    beforeEnter: async (to, from, next) => {
      await auth.getUser();
      const route = await checkAuthMulti(auth.user as User, to.path,
        [PROJECT_MANAGER, SUPER_USER, CREW_LEAD_AUTH0], '/error');
      if (!route.auth) {
        next(route.path);
      } else {
        next();
      }
    },
  },
  {
    path: '/Error',
    name: 'Error',
    props: true,
    component: Error,
  },
  {
    path: '/forbidden',
    name: 'Forbidden',
    component: Forbidden,
    props: (route) => ({
      ...route.params,
    }),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/:catchAll(.*)',
    name: 'Catchall',
    component: Error,
    props: (route) => ({
      ...route.params,
    }),
    meta: {
      requiresAuth: false,
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  authGuard(to, from, next);
  auth = getInstance();
});

export default router;
