import { GetterTree } from 'vuex';
import { RootState } from '../types';
import { AssignmentShort, PlanningState } from './types';

const getters: GetterTree<PlanningState, RootState> = {
  getAssignmentData(state): AssignmentShort {
    return state.data;
  },
};

export default getters;
